import { env } from 'config';
import { PayoutOrderAutomationRequest, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/payout-order-automation-requests`;

export const payoutOrderAutomationRequestsApi = {
  getForOrder:
    (orderId: string) =>
    async (
      params?: Partial<QueryParams>,
    ): Promise<PayoutOrderAutomationRequest[]> =>
      (await apiUtils.getWithQueryParams(`${url}/order/${orderId}`, params))
        .data,

  getForAutomation:
    (automationId: string) =>
    async (
      params?: Partial<QueryParams>,
    ): Promise<PayoutOrderAutomationRequest[]> =>
      (
        await apiUtils.getWithQueryParams(
          `${url}/automation/${automationId}`,
          params,
        )
      ).data,
};
