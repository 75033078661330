import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { Orders } from 'features/payout-orders';

export const TraderActiveOrders: React.FC = () => (
  <Orders
    tab={OrdersTab.Active}
    additionalColumns={[
      AdditionalOrderColumn.StatusActive,
      AdditionalOrderColumn.CustomerRequisites,
      AdditionalOrderColumn.AutomationStatus,
    ]}
  />
);
