import axios from 'axios';

import { env } from 'config';
import { InternalTransferTab, UserRole } from 'enums';
import { InternalTransfer, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/internal-transfer`;

const getAllPaginatedByRole =
  (role: UserRole, tab: InternalTransferTab) =>
  async (params: QueryParams): Promise<PaginatedData<InternalTransfer>> =>
    (await apiUtils.getWithQueryParams(`${url}/${role}/${tab}`, params)).data;

const create = async (data: {
  senderAssetId: string;
  amount: number;
}): Promise<InternalTransfer> => (await axios.post(url, data)).data;

const createAdmin = apiUtils.withAxiosInstance<
  {
    senderUserId: string;
    senderAssetId: string;
    amount: number;
    recipientUserId: string;
    recipientAssetId: string;
    code?: string;
    password?: string;
  },
  InternalTransfer
>(
  async (axiosInstance, data) =>
    (await axiosInstance.post(`${url}/admin/create`, data)).data,
);
const findOne = async (id: string): Promise<InternalTransfer> =>
  (await axios.get(`${url}/${id}`)).data;

const complete = async (data: {
  recipientAssetId?: string;
  id: string;
}): Promise<InternalTransfer> =>
  (await axios.post(`${url}/complete`, data)).data;

const cancel = async (data: { id: string }): Promise<InternalTransfer> =>
  (await axios.post(`${url}/cancel`, data)).data;

const cancelAdmin = async (data: { id: string }): Promise<InternalTransfer> =>
  (await axios.post(`${url}/admin/cancel`, data)).data;

export const internalTransferApi = {
  getAllPaginatedByRole,
  create,
  createAdmin,
  findOne,
  complete,
  cancel,
  cancelAdmin,
};
