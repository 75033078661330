import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ChipLinkList } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  payinTo: string;
  payoutTo: string;
  commonTo?: string;
  payinBadge?: number;
  payoutBadge?: number;
};

export const PayOutlet: React.FC<Props> = ({
  payinTo,
  payoutTo,
  commonTo,
  payinBadge,
  payoutBadge,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'common',
  });

  return (
    <Outlet
      context={{
        headerSubcontent: (
          <ChipLinkList
            links={[
              {
                label: t('payin'),
                to: payinTo,
                badge: payinBadge,
              },
              {
                label: t('payout'),
                to: payoutTo,
                badge: payoutBadge,
              },
              ...(commonTo
                ? [
                    {
                      label: t('common'),
                      to: commonTo,
                    },
                  ]
                : []),
            ]}
          />
        ),
      }}
    />
  );
};
