import i18next from 'i18next';

import { PayoutRequisitesAutomationType } from 'enums';

const getTypeLabel = (type: PayoutRequisitesAutomationType) =>
  i18next.t(`payout_requisites_automation_type.${type}`, {
    defaultValue: type,
  });

export const payoutRequisitesAutomationUtils = {
  getTypeLabel,
};
