import { FormikProvider, useFormik } from 'formik';
import { isEmpty, map, noop, values } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { payoutOrdersApi, tradersApi } from 'api';
import {
  CloseDialogResult,
  Dialog,
  DialogProps,
  FormikRadioGroup,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';

import { ConfirmPaymentAutomation } from './ConfirmPaymentAutomation';
import { ManageReceipts } from '../ManageReceipts';

type Props = {
  order: PayoutOrder | null;
} & DialogProps;

enum ConfirmType {
  Receipts = 'receipts',
  Automation = 'automation',
}

type Values = {
  confirmType: ConfirmType;
  automationId: string;
};

export const ConfirmPaymentDialog: React.FC<Props> = ({
  open,
  onClose,
  order,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_payment_dialog',
  });
  const queryClient = useQueryClient();
  const { role, isTrader } = useUser();

  const tradeInfoQueryResult = useQuery(QueryKey.MyInfo, tradersApi.getMyInfo, {
    enabled: isTrader,
  });

  const canUseAutomation = useMemo(
    () => tradeInfoQueryResult.data?.payoutRequisitesAutomationEnabled,
    [tradeInfoQueryResult],
  );

  const confirmReceiptsMutation = useMutation(payoutOrdersApi.confirmAsTrader);
  const confirmAutomationMutation = useMutation(
    payoutOrdersApi.confirmAutomationAsTrader,
  );

  const initialValues: Values = useMemo(
    () => ({
      confirmType: ConfirmType.Receipts,
      automationId: '',
    }),
    [],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: noop,
  });

  const { isConfirmReceipts, isConfirmAutomation } = useMemo(
    () => ({
      isConfirmReceipts: formik.values.confirmType === ConfirmType.Receipts,
      isConfirmAutomation: formik.values.confirmType === ConfirmType.Automation,
    }),
    [formik],
  );

  const mutation = useMemo(() => {
    if (isConfirmAutomation) {
      return confirmAutomationMutation;
    }
    return confirmReceiptsMutation;
  }, [confirmAutomationMutation, confirmReceiptsMutation, isConfirmAutomation]);

  const queryKey = useMemo(
    () => [QueryKey.PayoutOrderReceipts, order?.id],
    [order],
  );
  const queryResultReceipts = useQuery(
    queryKey,
    () => payoutOrdersApi.getOrderReceiptsAsRole(role)(order?.id || ''),
    { enabled: open && !!order?.id && isConfirmReceipts },
  );

  const handleClose = useCallback(
    (result: CloseDialogResult<Values>) => {
      queryResultReceipts.remove();
      if (result.ok && order) {
        const options = {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.Notifications);
            queryClient.invalidateQueries(QueryKey.ActivePayoutOrders);
            formik.resetForm();
            onClose(result);
          },
        };
        if (isConfirmReceipts) {
          confirmReceiptsMutation.mutate(order, options);
          return;
        } else if (isConfirmAutomation) {
          confirmAutomationMutation.mutate(
            { orderId: order.id, automationId: result.data?.automationId! },
            options,
          );
          return;
        }
      }
      formik.resetForm();
      onClose(result);
    },
    [
      order,
      formik,
      queryClient,
      queryResultReceipts,
      isConfirmReceipts,
      isConfirmAutomation,
      onClose,
      confirmReceiptsMutation,
      confirmAutomationMutation,
    ],
  );

  const confirmTypeOptions = useMemo(
    () =>
      map(values(ConfirmType), (type) => ({
        value: type,
        label: t(`confirm_types.${type}`, { defaultValue: type }),
      })),
    [t],
  );

  const okDisabled = useMemo(() => {
    if (isConfirmReceipts) {
      return isEmpty(queryResultReceipts.data);
    } else if (isConfirmAutomation) {
      return !formik.values.automationId;
    }
  }, [formik, queryResultReceipts, isConfirmReceipts, isConfirmAutomation]);

  return (
    <Dialog
      open={open}
      title={t('title')}
      okLabel={tCommon('buttons.confirm')}
      okDisabled={okDisabled}
      mutation={mutation}
      data={formik.values}
      onClose={handleClose}
    >
      {canUseAutomation && (
        <FormikProvider value={formik}>
          <FormikRadioGroup
            name="confirmType"
            options={confirmTypeOptions}
            label={t('confirm_type')}
            sx={{ mb: 4 }}
          />
          {order && isConfirmAutomation && (
            <ConfirmPaymentAutomation order={order} />
          )}
        </FormikProvider>
      )}
      {order && isConfirmReceipts && (
        <ManageReceipts
          queryResultReceipts={queryResultReceipts}
          order={order}
        />
      )}
    </Dialog>
  );
};
