import { useContext } from 'react';

import { SecureConfirmContext } from 'context';

export const useSecureConfirmContext = (optional?: boolean) => {
  const context = useContext(SecureConfirmContext);
  if (!context && !optional) {
    throw new Error(
      'useSecureConfirmContext must be used within a SecureConfirmProvider',
    );
  }
  return context;
};
