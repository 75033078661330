import { HttpOutlined as HttpIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { payoutOrderAutomationRequestsApi } from 'api';
import { QueryKey } from 'enums';
import { AutomationRequestsDialog } from 'features/payout-orders';

type Props = {
  automationId: string;
};

export const AutomationRequestsButton: React.FC<Props> = ({ automationId }) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);

  const queryResult = useQuery(
    QueryKey.PayoutOrderAutomationRequests,
    payoutOrderAutomationRequestsApi.getForAutomation(automationId),
    {
      enabled: dialogOpen,
    },
  );

  return (
    <Fragment>
      <Tooltip
        title={t(
          'features.payout_order_automation.table.columns.automation_requests',
        )}
      >
        <HttpIcon
          className="tw-cursor-pointer"
          color="secondary"
          fontSize="large"
          onClick={() => setDialogOpen(true)}
        />
      </Tooltip>
      <AutomationRequestsDialog
        queryResult={queryResult}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Fragment>
  );
};
