import i18next from 'i18next';

import { OrderAutomationStatus, OrderAutomationStatusDetails } from 'enums';

const getStatusLabel = (status: OrderAutomationStatus) => {
  const { t } = i18next;
  const key = `order_automation_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusDetailsLabel = (statusDetails: OrderAutomationStatusDetails) => {
  const { t } = i18next;
  const key = `order_automation_status_details.${statusDetails}`;
  return t(key as any, { defaultValue: statusDetails });
};

export const orderAutomationUtils = {
  getStatusLabel,
  getStatusDetailsLabel,
};
