import { Typography } from '@mui/material';
import { map } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { paymentLimitsApi } from 'api';
import {
  CloseDialogHandler,
  DataWrapper,
  Dialog,
  StatisticalInfo,
} from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import {
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationLimits,
} from 'types';
import { formatUtils } from 'utils';

type Props = {
  open: boolean;
  requisitesAutomation?: PayoutRequisitesAutomation;
  onClose: CloseDialogHandler;
};

const BankLimitsInfo = ({
  limits,
}: {
  limits: PayoutRequisitesAutomationLimits;
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.requisite_limits.show_limits.dialog',
  });
  return (
    <div className="tw-mb-4">
      <Typography variant="h6" sx={{ mb: 2 }}>
        {limits.customerBank?.name}
      </Typography>
      <div className="tw-grid tw-gap-1">
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payoutLimitCountPerDay!}
          used={limits?.limitCountPerDay}
          label={t('limits_count_per_day')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payoutLimitCountPerMonth!}
          used={limits?.limitCountPerMonth}
          label={t('limits_count_per_month')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payoutLimitSumPerDay!}
          used={limits?.limitSumPerDay}
          label={t('limits_sum_per_day')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payoutLimitSumPerMonth!}
          used={limits?.limitSumPerMonth}
          label={t('limits_sum_per_month')}
        />
        {!!limits?.throttleUntilAt && (
          <div className="tw-text-xs">
            {t('throttle_until_at')}
            {': '}
            {formatUtils.formatDate(limits?.throttleUntilAt)}
          </div>
        )}
      </div>
    </div>
  );
};

export const PayoutLimitsInfoDialog: React.FC<Props> = ({
  open,
  requisitesAutomation,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.requisite_limits.show_limits.dialog',
  });

  const queryKey = useMemo(
    () => [QueryKey.PayoutRequisitesAutomationLimits, requisitesAutomation?.id],
    [requisitesAutomation],
  );

  const queryResult = useQuery(
    queryKey,
    paymentLimitsApi.getPayoutRequisitesAutomationLimits(
      requisitesAutomation?.id,
    ),
    {
      enabled: !!requisitesAutomation,
    },
  );

  return (
    <Dialog open={open} title={t('title')} onClose={onClose} modal>
      <DataWrapper queryResult={queryResult}>
        <Fragment>
          {map(queryResult?.data, (limits) => (
            <BankLimitsInfo key={limits.id} limits={limits} />
          ))}
        </Fragment>
      </DataWrapper>
    </Dialog>
  );
};
