import { Fragment } from 'react';

import { StatisticalInfo } from 'components';
import { OrderType } from 'enums';
import { PlatformPaymentLimits } from 'types';

type Props = {
  limits: PlatformPaymentLimits;
  orderType: OrderType;
};

export const LimitsInfo: React.FC<Props> = ({ limits, orderType }) => (
  <div>
    {orderType === OrderType.Payin ? (
      <Fragment>
        <StatisticalInfo
          total={limits?.payinLimitSumPerDay}
          used={limits?.usage?.payinLimitSumPerDay}
        />
        <StatisticalInfo
          total={limits?.payinLimitSumPerMonth}
          used={limits?.usage?.payinLimitSumPerMonth}
        />
      </Fragment>
    ) : (
      <Fragment>
        <StatisticalInfo
          total={limits?.payoutLimitSumPerDay}
          used={limits?.usage?.payoutLimitSumPerDay}
        />
        <StatisticalInfo
          total={limits?.payoutLimitSumPerMonth}
          used={limits?.usage?.payoutLimitSumPerMonth}
        />
      </Fragment>
    )}
  </div>
);
