import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionRow } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  credentials?: Record<string, any>;
};

export const P2PProviderCredentialsDetails: React.FC<Props> = ({
  credentials,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  return (
    <Fragment>
      {credentials?.apiKey && (
        <DescriptionRow
          title={t('details.api_key')}
          value={credentials.apiKey}
          canCopy
        />
      )}
      {credentials?.signatureKey && (
        <DescriptionRow
          title={t('details.signature_key')}
          value={credentials.signatureKey}
          canCopy
        />
      )}
      {credentials?.apiToken && (
        <DescriptionRow
          title={t('details.api_token')}
          value={credentials.apiToken}
          canCopy
        />
      )}
      {credentials?.host2host && (
        <DescriptionRow
          title={t('details.host_2_host')}
          value={credentials.host2host}
          canCopy
        />
      )}
      {credentials?.apiPublic && (
        <DescriptionRow
          title={t('details.api_public')}
          value={credentials.apiPublic}
          canCopy
        />
      )}
      {credentials?.apiPrivate && (
        <DescriptionRow
          title={t('details.api_private')}
          value={credentials.apiPrivate}
          canCopy
        />
      )}
      {credentials?.apiSecret && (
        <DescriptionRow
          title={t('details.api_secret')}
          value={credentials.apiSecret}
          canCopy
        />
      )}
      {credentials?.privateKey && (
        <DescriptionRow
          title={t('details.private_key')}
          value={credentials.privateKey}
          canCopy
        />
      )}
      {credentials?.password && (
        <DescriptionRow
          title={t('details.password')}
          value={credentials.password}
          canCopy
        />
      )}
    </Fragment>
  );
};
