import { Stack, Typography } from '@mui/material';
import { isEmpty, isNumber } from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyText, DescriptionRow, StylizedNumber } from 'components';
import { useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProviderPayinConfig } from 'types';
import { formatUtils } from 'utils';

import { P2PProviderCredentialsDetails } from '../P2PProviderCredentialsDetails';

type Props = {
  config: P2PProviderPayinConfig;
};

export const P2PProviderPayinConfigDetails: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const { getTradeMethodLabel } = useUserContextUtils();

  return (
    <Fragment>
      <Typography variant="h6">{t('details.payin_details')}</Typography>
      <DescriptionRow
        title={t('details.status.title')}
        value={
          config?.enabled
            ? t('details.status.enabled')
            : t('details.status.disabled')
        }
      />
      {config?.url && (
        <DescriptionRow title={t('details.url')} value={config.url} canCopy />
      )}

      <P2PProviderCredentialsDetails credentials={config?.credentials} />

      {config?.responseTimeout && (
        <DescriptionRow
          title={t('details.response_timeout')}
          description={t('details.response_timeout_description')}
          value={`${formatUtils.formatNumber(config.responseTimeout)}${tCommon(
            'suffixes.ms',
          )}`}
        />
      )}

      {isNumber(config?.expectedFee) && (
        <DescriptionRow
          title={t('details.expected_fee')}
          value={<StylizedNumber value={config.expectedFee} unit="%" />}
        />
      )}

      {config?.lastTakenOrderAt && (
        <DescriptionRow
          title={t('details.last_taken_order_at')}
          value={formatUtils.formatDate(config.lastTakenOrderAt)}
        />
      )}

      {!isEmpty(config?.tradeMethods) && (
        <DescriptionRow
          title={t('details.trade_methods')}
          value={
            <Stack direction="column" spacing={2}>
              {config?.tradeMethods?.map(({ id }) => (
                <Stack key={id} direction="column" spacing={0}>
                  <CopyText text={id} />
                  <div>{getTradeMethodLabel(id)}</div>
                </Stack>
              ))}
            </Stack>
          }
        />
      )}
    </Fragment>
  );
};
