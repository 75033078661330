import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { tradeMethodsApi } from 'api';
import { PageHeader, Dialog, DataWrapper } from 'components';
import { QueryKey } from 'enums';
import {
  AutomationSourcesList,
  AutomationSourcesDetailsForm,
  AutomationSourcesProvider,
} from 'features/automation-sources';
import { useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AutomationSource } from 'types';

export const TradeMethodAutomationSourcesPage = () => {
  const { id: tradeMethodId = '' } = useParams<{ id: string }>();

  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.automation_sources',
  });
  const { getTradeMethodLabel } = useUserContextUtils();

  const [isDetailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedAutomationSource, setSelectedAutomationSource] =
    React.useState<AutomationSource | null>(null);

  const tradeMethodQuery = useQuery(
    [QueryKey.TradeMethods, tradeMethodId],
    () => tradeMethodsApi.getOne(tradeMethodId!),
  );

  const subtitle = useMemo(
    () => getTradeMethodLabel(tradeMethodId),
    [getTradeMethodLabel, tradeMethodId],
  );

  const onEdit = useCallback((automationSource: AutomationSource) => {
    setDetailsDialogOpen(true);
    setSelectedAutomationSource(automationSource);
  }, []);

  const onCreate = useCallback(() => {
    setDetailsDialogOpen(true);
    setSelectedAutomationSource(null);
  }, []);

  const onClose = useCallback(() => {
    setDetailsDialogOpen(false);
    setSelectedAutomationSource(null);
  }, []);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        subtitle={subtitle}
        divider={false}
        rightContentButton={{
          onClick: onCreate,
        }}
      />
      <DataWrapper queryResult={tradeMethodQuery}>
        <AutomationSourcesProvider tradeMethod={tradeMethodQuery.data}>
          <AutomationSourcesList onEdit={onEdit} />
          <Dialog
            title={
              selectedAutomationSource
                ? t('details_form.title.edit')
                : t('details_form.title.create')
            }
            open={isDetailsDialogOpen}
            onClose={onClose}
            maxWidth="sm"
            modal
          >
            <AutomationSourcesDetailsForm
              onSubmit={onClose}
              selectedAutomationSource={selectedAutomationSource}
            />
          </Dialog>
        </AutomationSourcesProvider>
      </DataWrapper>
    </Fragment>
  );
};
