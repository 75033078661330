import { Typography } from '@mui/material';
import { FieldArray } from 'formik';
import { filter } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormikCheckbox,
  FormikMultiSelect,
  FormikNumericField,
  FormikSelect,
  FormikTextField,
} from 'components';
import { P2PProviderType } from 'enums';
import { useCurrencies, useUserContext, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethod } from 'types';

import { P2PProviderCredentials } from '../P2PProviderCredentials';

type Props = {
  providerType?: P2PProviderType;
  fiatCurrencyId?: string;
  assetCurrencyId?: string;
  tradeMethods?: { id: string }[];
  onTradeMethodsChange: (event: any) => void;
};

export const P2PProviderPayoutConfig: React.FC<Props> = ({
  providerType,
  fiatCurrencyId,
  assetCurrencyId,
  tradeMethods,
  onTradeMethodsChange,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const { defaultAssetCurrency, getAssetCurrencyExchangeOptions } =
    useCurrencies();
  const { getTradeMethodsOptions } = useUserContextUtils();

  const { tradeMethods: allTradeMethods } = useUserContext();

  const filteredTradeMethods: TradeMethod[] = useMemo(
    () =>
      filter(allTradeMethods, {
        fiatCurrencyId,
      }),
    [fiatCurrencyId, allTradeMethods],
  );

  const tradeMethodsOptions = useMemo(
    () => getTradeMethodsOptions(filteredTradeMethods),
    [filteredTradeMethods, getTradeMethodsOptions],
  );

  const isTradeMethodsSelectDisabled = useMemo(
    () => !fiatCurrencyId || !tradeMethodsOptions.length,
    [fiatCurrencyId, tradeMethodsOptions],
  );

  return (
    <Fragment>
      <Typography variant="h5">{tCommon('common.payout')}</Typography>
      <FormikCheckbox label={t('fields.enabled')} name="payoutConfig.enabled" />
      <FormikCheckbox
        label={t('fields.polling_enabled')}
        name="payoutConfig.pollingEnabled"
      />
      {assetCurrencyId && assetCurrencyId !== defaultAssetCurrency?.id && (
        <FormikSelect
          label={t('fields.withdrawal_currency_exchange')}
          name="payoutConfig.assetCurrencyExchangeId"
          required
          helperText={t('fields.withdrawal_currency_exchange_description')}
          options={getAssetCurrencyExchangeOptions(assetCurrencyId)}
          noneOption
        />
      )}
      <FormikTextField
        label={t('fields.url')}
        name="payoutConfig.url"
        required
      />

      <P2PProviderCredentials
        path="payoutConfig.credentials"
        providerType={providerType}
      />

      <FormikNumericField
        label={t('fields.response_timeout')}
        name="payoutConfig.responseTimeout"
        suffix={tCommon('suffixes.ms')}
        allowNegative={false}
        defaultValue={0}
        fullWidth
        helperText={t('fields.response_timeout_description')}
      />

      <FormikNumericField
        label={t('fields.order_timeout')}
        name="payoutConfig.orderTimeout"
        suffix={tCommon('suffixes.minutes')}
        allowNegative={false}
        required
        defaultValue={0}
        fullWidth
        helperText={t('fields.order_timeout_description')}
      />

      <FormikNumericField
        label={t('fields.expected_fee')}
        name="payoutConfig.expectedFee"
        allowNegative={false}
        percentageSuffix
        fullWidth
        required
      />

      <FieldArray
        name="payoutConfig.tradeMethods"
        render={() => {
          const multiSelectValue = tradeMethods?.map(({ id }) => id) || [];

          return (
            <FormikMultiSelect
              label={t('fields.trade_methods')}
              name="payoutConfig.tradeMethods"
              options={tradeMethodsOptions}
              value={multiSelectValue}
              onChange={onTradeMethodsChange}
              disabled={isTradeMethodsSelectDisabled}
            />
          );
        }}
      />
    </Fragment>
  );
};
