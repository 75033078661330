export enum FilterDefinitionType {
  Date = 'date',
  DateTime = 'date_time',
  Text = 'text',
  Enum = 'enum',
  User = 'user',
  Shop = 'shop',
  OrderStatus = 'order_status',
  Trader = 'trader',
  Select = 'select',
  MultiSelect = 'multi_select',
  Operator = 'operator',
  Numeric = 'numeric',
  Range = 'range',
}
