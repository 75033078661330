import { map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogProps,
  FormControls,
  FormikDialog,
  FormikSelect,
} from 'components';
import { OrderInternalStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayinOrder;
} & DialogProps;

export const InternalStatusDetailsDialog: React.FC<Props> = ({
  open,
  order,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.details.internal_status_details_dialog',
  });

  const internalStatusDetailsOptions = useMemo(
    () =>
      map(OrderInternalStatusDetails, (status) => ({
        value: status,
        label: orderUtils.getInternalStatusDetailsLabel(status),
      })),
    [],
  );

  return (
    <FormikDialog
      dialogProps={{ open, title: t('title'), onClose }}
      formikProps={{
        initialValues: {
          internalStatusDetails: order.internalStatusDetails || '',
        },
      }}
    >
      {() => (
        <FormControls>
          <FormikSelect
            label={t('fields.internal_status_details')}
            name="internalStatusDetails"
            noneOption
            options={internalStatusDetailsOptions}
          />
        </FormControls>
      )}
    </FormikDialog>
  );
};
