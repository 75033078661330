import axios from 'axios';

import { env } from 'config';
import { UserStatus } from 'enums';
import { Referral, User, UserProfile, UserAgentInfo, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/users`;

const getProfile = async (): Promise<User> =>
  (await axios.get(`${url}/me`)).data;

const updateProfile = async (user: Partial<User>): Promise<User> =>
  (
    await axios.patch(`${url}/me`, user, {
      withCredentials: !!user.password,
    })
  ).data;

const getAll = async (params?: Partial<QueryParams>): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(url, params)).data;

const getAllFiltered = async (params: QueryParams): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(url, params)).data;

const getAllWithPlatform = async (): Promise<User[]> =>
  (await axios.get(`${url}/with-platform`)).data;

const getOne = async (id: string): Promise<User> =>
  (await axios.get(`${url}/${id}`)).data;

const updateOneAdmin = async ({
  id,
  data,
}: {
  id: string;
  data: UserProfile;
}): Promise<User> => (await axios.patch(`${url}/${id}`, data)).data;

const updateStatusAdmin = async ({
  id,
  data,
}: {
  id: string;
  data: { status: UserStatus };
}): Promise<User> => (await axios.patch(`${url}/${id}/status`, data)).data;

const getAllTraders = async (params?: Partial<QueryParams>): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(`${url}/traders`, params)).data;

const getAllMerchants = async (
  params?: Partial<QueryParams>,
): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(`${url}/merchants`, params)).data;

const remove = async (user: User): Promise<User[]> =>
  (await axios.delete(`${url}/${user.id}`)).data;

const getReferralTraders = async (): Promise<Referral[]> =>
  (await axios.get(`${url}/referral/traders`)).data;

const resetPassword = apiUtils.withAxiosInstance<
  { id: string },
  { password: string }
>(
  async (axiosInstance, { id }) =>
    (await axiosInstance.post(`${url}/${id}/reset-password`)).data,
);

const disableTwoFA = apiUtils.withAxiosInstance<{ id: string }, User>(
  async (axiosInstance, { id }) =>
    (await axiosInstance.post(`${url}/${id}/disable-2fa`)).data,
);

const updateAgents = async ({
  id,
  data,
}: {
  id: string;
  data: UserAgentInfo;
}): Promise<User> => (await axios.post(`${url}/${id}/agents`, data)).data;

const cancelTelegramNotifications = async () =>
  (await axios.post(`${url}/me/cancel-telegram-notifications`)).data;

const getAssetHolders = async (
  params?: Partial<QueryParams>,
): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(`${url}/asset-holders`, params)).data;

const getAssetHoldersWithProvider = async (
  params?: Partial<QueryParams>,
): Promise<User[]> =>
  (
    await apiUtils.getWithQueryParams(
      `${url}/asset-holders-with-provider`,
      params,
    )
  ).data;

const getExternalWalletHolders = async (
  params?: Partial<QueryParams>,
): Promise<User[]> =>
  (await apiUtils.getWithQueryParams(`${url}/external-wallet-holders`, params))
    .data;

export const usersApi = {
  getProfile,
  updateProfile,
  getAll,
  getAllWithPlatform,
  getAllFiltered,
  getOne,
  getAllTraders,
  getAllMerchants,
  remove,
  getReferralTraders,
  updateOneAdmin,
  updateStatusAdmin,
  disableTwoFA,
  updateAgents,
  resetPassword,
  cancelTelegramNotifications,
  getAssetHolders,
  getAssetHoldersWithProvider,
  getExternalWalletHolders,
};
