import { find } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { paymentLimitsApi } from 'api';
import {
  CopyTextId,
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DataGridColumnHeader,
  dataGridColumns,
  RequisitesInfo,
  StatisticalInfo,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  PayoutRequisitesAutomationLimits,
  PayoutRequisitesAutomationLimitsFilters,
} from 'types';
import { formatUtils, formUtils, requisitesUtils } from 'utils';

export const PayoutRequisitesLimitsList: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.limits_list',
  });
  const { t: tRequisites } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation();

  const { isAdminOrTechOperator } = useUser();
  const canManage = useMemo(
    () => isAdminOrTechOperator,
    [isAdminOrTechOperator],
  );

  const { banks, paymentTypes } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResult = usePartialQuery(
    QueryKey.PayoutRequisitesAutomationLimits,
    paymentLimitsApi.getAllPayoutRequisitesAutomationLimitsPaginated,
  );

  const queryClient = useQueryClient();
  const { mutate: remove } = useMutation(
    paymentLimitsApi.removePayoutRequisitesAutomationLimits,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.PayoutRequisitesAutomationLimits,
        );
      },
      notifierType: 'remove',
    },
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutRequisitesAutomationLimits>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => (
          <div>
            <RequisitesInfo
              paymentTypeId={
                item.requisitesAutomation?.requisites?.paymentTypeId
              }
              bankId={item.requisitesAutomation?.requisites?.bankId}
              fiatCurrencyId={
                item.requisitesAutomation?.requisites?.fiatCurrencyId
              }
            />
            <div className="tw-mt-1">
              <CopyTextId id={item.requisitesAutomation?.requisitesId!} />
            </div>
          </div>
        ),
        valueClassName: 'tw-min-w-[200px]',
      },
      {
        header: t('table.columns.automation'),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-mb-1">{item.requisitesAutomation?.name}</div>
            <CopyTextId id={item.requisitesAutomationId} />
          </Fragment>
        ),
      },
      {
        header: t('table.columns.customer_bank'),
        valueGetter: (item) => find(banks, { id: item.customerBankId })?.name,
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('table.columns.limit_count')}
            tooltip={t('table.columns.period')}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limitCountPerDay}
              total={item.defaultPaymentLimits?.payoutLimitCountPerDay!}
            />
            <StatisticalInfo
              used={item.limitCountPerMonth}
              total={item.defaultPaymentLimits?.payoutLimitCountPerMonth!}
            />
          </div>
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('table.columns.limit_sum')}
            tooltip={t(`table.columns.period`)}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limitSumPerDay}
              total={item.defaultPaymentLimits?.payoutLimitSumPerDay!}
            />
            <StatisticalInfo
              used={item.limitSumPerMonth}
              total={item.defaultPaymentLimits?.payoutLimitSumPerMonth!}
            />
          </div>
        ),
      },
      {
        header: t('table.columns.throttle_until_at'),
        valueKey: 'throttleUntilAt',
        valueFormatter: formatUtils.formatDate,
      },
    ],
    [t, banks],
  );

  const filtersDefinitions: FilterDefinition<PayoutRequisitesAutomationLimitsFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.requisites_automation_id'),
          name: 'requisitesAutomationId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: tCommon('filters.bank'),
          name: 'bankId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(banks),
          getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
        },
        {
          label: tRequisites('filters.payment_type'),
          name: 'paymentTypeId',
          type: FilterDefinitionType.Select,
          options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
          getDisplayName: (paymentType: string) =>
            requisitesUtils.getPaymentTypeLabel(
              find(paymentTypes, { id: paymentType })!,
            ),
        },
        {
          label: tRequisites('filters.fiat_currency'),
          name: 'fiatCurrencyId',
          type: FilterDefinitionType.Select,
          options: fiatCurrenciesOptions,
          getDisplayName: getFiatCurrencyCode,
        },
      ],
      [
        t,
        banks,
        tCommon,
        tRequisites,
        getFiatCurrencyCode,
        fiatCurrenciesOptions,
        paymentTypes,
      ],
    );

  return (
    <CrudPage
      table={{
        queryResult,
        columns,
        paginated: true,
        hideActions: !canManage,
        actions: [
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ],
      }}
      filters={{ filtersDefinitions }}
    />
  );
};
