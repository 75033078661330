export enum DefaultPaymentTypeCode {
  SBP = 'sbp',
  TSBP = 'tsbp',
  SberPay = 'sberpay',
  AccountNumber = 'account_number',
  PhoneNumber = 'phone_number',
  Card2Card = 'card2card',
  TCard2Card = 'tcard2card',
  PhonePe = 'phone_pe',
  PhoneTM = 'phone_tm',
  ERIP = 'erip',
  AccountNumberSEPA = 'account_number_sepa',
  AccountNumberIBAN = 'account_number_iban',
  IDCard = 'transfer_via_id_card',
  UPI = 'upi',
  IMPS = 'imps',
}
