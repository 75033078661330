import { AxiosError } from 'axios';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { automationSourcesApi } from 'api';
import {
  CopyTextId,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  QueryFilters,
} from 'components';
import { AutomationSourceType, FilterDefinitionType } from 'enums';
import { useMutation, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  AutomationSource,
  AutomationSourceFilters,
  FilterDefinition,
} from 'types';
import { automationSourcesUtils } from 'utils';

import { useAutomationSources } from './AutomationSourcesList.context';

type Props = {
  onEdit?: (item: AutomationSource) => void;
};

export const AutomationSourcesList: React.FC<Props> = ({ onEdit }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.automation_sources',
  });
  const { automationSourcesQuery, isExtended } = useAutomationSources();
  const { getTradeMethodLabel, tradeMethodsOptions } = useUserContextUtils();

  const removeAutomationSourceMutation = useMutation<
    AutomationSource,
    AxiosError,
    string
  >(automationSourcesApi.remove, {
    onSuccess: () => {
      automationSourcesQuery.refetch();
    },
    notifierType: 'remove',
  });

  const columns: DataGridColumnDefinition<AutomationSource>[] = useMemo(
    () => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.trade_method'),
        valueGetter: (item) => {
          if (item.tradeMethodId) {
            const label = getTradeMethodLabel(item.tradeMethodId);

            return (
              <div>
                <CopyTextId id={item.id} disableIconColor />
                {label && <div>{label}</div>}
              </div>
            );
          }

          return null;
        },
        hidden: !isExtended,
      },
      {
        header: t('fields.type'),
        valueGetter: (item) => automationSourcesUtils.getTypeLabel(item.type),
        hidden: !isExtended,
      },
    ],
    [t, isExtended, getTradeMethodLabel],
  );

  const filters: FilterDefinition<AutomationSourceFilters>[] = useMemo(
    () => [
      {
        label: t('fields.name'),
        name: 'name',
        type: FilterDefinitionType.Text,
      },
      {
        label: t('fields.trade_method'),
        name: 'tradeMethodId',
        type: FilterDefinitionType.Select,
        options: tradeMethodsOptions,
        getDisplayName: getTradeMethodLabel,
        hidden: !isExtended,
      },
      {
        label: t('fields.type'),
        name: 'type',
        type: FilterDefinitionType.Enum,
        enum: AutomationSourceType,
        getDisplayName: automationSourcesUtils.getTypeLabel,
        hidden: !isExtended,
      },
    ],
    [t, tradeMethodsOptions, getTradeMethodLabel, isExtended],
  );

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filters} />
      <CrudTable
        columns={columns}
        queryResult={automationSourcesQuery}
        actions={[
          {
            type: CrudTableActionType.Details,
            onClick: onEdit,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item: AutomationSource, { close }) =>
              removeAutomationSourceMutation.mutate(item.id, {
                onSuccess: close,
              }),
          },
        ]}
        paginated
      />
    </Fragment>
  );
};
