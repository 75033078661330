import React, { useCallback, useEffect, useState } from 'react';

import { ordersApi, payoutOrdersApi } from 'api';
import { ColorCodeSelect, ColorCodeSelectOption } from 'components';
import { OrderColorCode, OrderType } from 'enums';
import { useMutation, usePrevious, useUser } from 'hooks';
import { Order } from 'types';

const options: ColorCodeSelectOption[] = [
  {
    value: 'default',
    color: '#ffffff',
  },
  {
    value: 'blue',
    color: '#3b9bff',
  },
  {
    value: 'red',
    color: '#ff5c5c',
  },
  {
    value: 'green',
    color: '#2bc52b',
  },
];

type Props = {
  order: Order;
  orderType: OrderType;
};

export const OrderColorCodeSelect: React.FC<Props> = ({ order, orderType }) => {
  const [value, setValue] = useState(order.colorCode || OrderColorCode.Default);
  const prevOrderColorCode = usePrevious(order.colorCode);

  const { role, user } = useUser();

  const [tooltip, setTooltip] = useState(order?.assignee?.name);

  const { mutate: updateColorCode, reset } = useMutation(
    orderType === OrderType.Payin
      ? ordersApi.updateColorCodeAsRole(role)
      : payoutOrdersApi.updateColorCodeAsRole(role),
    {
      onSuccess: (data) => {
        setValue(data?.colorCode);
        if (data?.colorCode === OrderColorCode.Default) {
          setTooltip('');
        } else {
          setTooltip(user.name);
        }
      },
      notifierType: 'execute',
    },
  );

  const handleChange = useCallback(
    (color: string) => {
      updateColorCode({ id: order.id, colorCode: color as OrderColorCode });
    },
    [order.id, updateColorCode],
  );

  useEffect(() => {
    if (order?.colorCode !== prevOrderColorCode) {
      setValue(order?.colorCode || OrderColorCode.Default);
      setTooltip(order?.assignee?.name);
      reset();
    }
  }, [order, prevOrderColorCode, reset]);

  return (
    <ColorCodeSelect
      options={options}
      value={value}
      asToggle
      tooltip={tooltip}
      onChange={handleChange}
    />
  );
};
