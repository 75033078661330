import React, { useState, useCallback } from 'react';

import { SecureConfirmContext } from 'context';
import { SecureConfirmType } from 'enums';
import { SecureConfirmData } from 'types';

import { SecureConfirmDialog } from '../SecureConfirmDialog';

export const SecureConfirmProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [secureConfirms, setSecureConfirms] = useState<
    SecureConfirmType[] | undefined
  >();
  const [promiseHandlers, setPromiseHandlers] = useState<{
    resolve: (data: SecureConfirmData) => void;
    reject: () => void;
  } | null>(null);

  const requestSecureConfirm = useCallback((confirms?: SecureConfirmType[]) => {
    setOpen(true);
    setSecureConfirms(confirms);

    return new Promise<SecureConfirmData>((resolve, reject) => {
      setPromiseHandlers({ resolve, reject });
    });
  }, []);

  const handleClose = useCallback(
    (result: { ok: boolean; data?: any }) => {
      if (result.ok && result.data) {
        promiseHandlers?.resolve(result.data.values);
      } else {
        promiseHandlers?.reject();
      }

      setOpen(false);
      setSecureConfirms(undefined);
      setPromiseHandlers(null);

      result.data?.formikHelpers?.resetForm?.();
    },
    [promiseHandlers],
  );

  return (
    <SecureConfirmContext.Provider value={{ requestSecureConfirm }}>
      {children}
      <SecureConfirmDialog
        open={open}
        secureConfirms={secureConfirms}
        onClose={handleClose}
      />
    </SecureConfirmContext.Provider>
  );
};
