import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { map } from 'lodash';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { payoutRequisitesAutomationApi } from 'api';
import { ConfirmButton, DataWrapper, Dialog } from 'components';
import { PayoutRequisitesAutomationWarningType, QueryKey } from 'enums';
import { useMoney, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationWarning,
} from 'types';

type Props = {
  automation: PayoutRequisitesAutomation;
};

export const RequisitesAutomationWarnings: React.FC<Props> = ({
  automation,
}) => {
  const queryClient = useQueryClient();
  const { isTrader } = useUser();
  const { getFormattedMoney } = useMoney();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation.warnings',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reviewed, setReviewed] = useState(false);

  const queryResult = useQuery(
    QueryKey.PayoutRequisitesAutomationWarnings,
    payoutRequisitesAutomationApi.getWarnings(automation.id),
    {
      enabled: dialogOpen,
    },
  );

  const reviewMutation = useMutation(
    payoutRequisitesAutomationApi.reviewWarning,
    {
      onSettled: () => {
        queryResult.refetch();
      },
      notifierType: 'execute',
    },
  );

  const handleDialogClose = useCallback(() => {
    queryResult.remove();
    if (reviewed) {
      queryClient.invalidateQueries(QueryKey.PayoutRequisitesAutomation);
    }
    setDialogOpen(false);
  }, [reviewed, queryClient, queryResult]);

  const review = useCallback(
    (warningId: string) => {
      setReviewed(true);
      reviewMutation.mutate({
        id: automation.id,
        warningId,
      });
    },
    [automation, reviewMutation],
  );

  const formatValue = useCallback(
    (warning: PayoutRequisitesAutomationWarning, value: string) => {
      if (
        warning.type === PayoutRequisitesAutomationWarningType.ExpectedBalance
      ) {
        return getFormattedMoney({
          value: +value,
          fiatCurrencyId: automation.balanceFiatCurrencyId,
          symbol: true,
        });
      }
      return value;
    },
    [automation, getFormattedMoney],
  );

  if (!automation.activeWarningsCount) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip title={t('title')}>
        <IconButton onClick={() => setDialogOpen(true)}>
          <WarningAmberIcon color="warning" />
        </IconButton>
      </Tooltip>
      <Dialog
        title={
          queryResult.data?.length && !queryResult.isRefetching
            ? `${t('title')} (${queryResult.data.length})`
            : t('title')
        }
        open={dialogOpen}
        modal
        onClose={handleDialogClose}
      >
        <DataWrapper queryResult={queryResult}>
          <div className="tw-grid tw-cols-1 tw-gap-6">
            {map(queryResult.data, (warning) => (
              <div key={warning.id}>
                <div className="tw-flex tw-items-center tw-font-medium tw-mb-2">
                  <WarningAmberIcon color="warning" />
                  <div className="tw-ml-1">
                    {t(`dialog.types.${warning.type}`, {
                      defaultValue: warning.type,
                    })}
                  </div>
                </div>
                <div>{`${t('dialog.fields.value')} ${formatValue(
                  warning,
                  warning.value,
                )}`}</div>
                <div>{`${t('dialog.fields.expected_value')} ${formatValue(
                  warning,
                  warning.expectedValue,
                )}`}</div>
                {!isTrader && (
                  <ConfirmButton
                    disabled={reviewMutation.isLoading}
                    variant="outlined"
                    size="small"
                    sx={{ mt: 2 }}
                    onConfirm={() => review(warning.id)}
                  >
                    {t('dialog.review')}
                  </ConfirmButton>
                )}
              </div>
            ))}
          </div>
        </DataWrapper>
      </Dialog>
    </Fragment>
  );
};
