import { find, map, values } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  FilterDefinitionType,
  OrdersTab,
  PayoutOrderAutomationMode,
  PayoutOrderAutomationStatus,
} from 'enums';
import { useCurrencies, usePayoutOrdersContext, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters, PayoutOrderFilters } from 'types';
import { formatUtils, formUtils, orderUtils, requisitesUtils } from 'utils';

import { Orders } from '../../Orders';

type Props = {
  tab: OrdersTab;
  additionalFilters?: FilterDefinition<OrderFilters>[];
  additionalColumns?: AdditionalOrderColumn[];
};

export const ManageOrdersList: React.FC<Props> = ({
  tab,
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const { t: tFilters } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const { paymentTypes, banks, p2pProviders } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();
  const { shops, merchants, traders } = usePayoutOrdersContext();

  const automationStatusOptions = useMemo(
    () =>
      map(values(PayoutOrderAutomationStatus), (status) => ({
        value: status,
        label: orderUtils.getPayoutAutomationStatusLabel(status),
      })),
    [],
  );

  const automationModeOptions = useMemo(
    () =>
      map(values(PayoutOrderAutomationMode), (mode) => ({
        value: mode,
        label: orderUtils.getPayoutAutomationModeLabel(mode),
      })),
    [],
  );

  const filters: FilterDefinition<PayoutOrderFilters>[] = useMemo(
    () => [
      {
        label: tFilters('customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('trader'),
        name: 'traderId',
        type: FilterDefinitionType.User,
        users: traders,
        getDisplayName: (traderId: string) =>
          find(traders, { id: traderId })?.name,
        hidden: additionalColumns.includes(AdditionalOrderColumn.P2PProvider),
      },
      {
        label: tFilters('merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: merchants,
        getDisplayName: (merchantId: string) =>
          find(merchants, { id: merchantId })?.name,
      },
      {
        label: tFilters('shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops,
        getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
      },
      {
        label: tFilters('external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
      {
        label: tFilters('payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tFilters('bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tFilters('fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tFilters('p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(p2pProviders),
        getDisplayName: (value: string) => {
          const provider = find(p2pProviders, { id: value });
          return formatUtils.formatName(provider);
        },
        hidden: additionalColumns.includes(AdditionalOrderColumn.Trader),
      },
      {
        label: tFilters('requisites_automation_id'),
        name: 'requisitesAutomationId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tFilters('automation_id'),
        name: 'orderAutomationId',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tFilters('automation_status'),
        name: 'orderAutomationStatus',
        type: FilterDefinitionType.Select,
        options: automationStatusOptions,
        getDisplayName: orderUtils.getPayoutAutomationStatusLabel,
      },
      {
        label: tFilters('automation_mode'),
        name: 'orderAutomationMode',
        type: FilterDefinitionType.Select,
        options: automationModeOptions,
        getDisplayName: orderUtils.getPayoutAutomationModeLabel,
      },
    ],
    [
      tFilters,
      traders,
      additionalColumns,
      merchants,
      shops,
      paymentTypes,
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      p2pProviders,
      automationStatusOptions,
      automationModeOptions,
    ],
  );

  return (
    <Orders
      tab={tab}
      additionalFilters={[...filters, ...additionalFilters]}
      additionalColumns={[
        AdditionalOrderColumn.CustomerContacts,
        AdditionalOrderColumn.Merchant,
        AdditionalOrderColumn.AutomationStatus,
        ...additionalColumns,
      ]}
    />
  );
};
