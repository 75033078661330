import { Button, Divider, Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { filter, isEmpty, map, omit } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { parsingMethodsApi } from 'api';
import {
  FormActions,
  FormControls,
  FormikCheckbox,
  FormikMultiSelect,
  FormikSelect,
  FormikTextField,
} from 'components';
import { ParsingMethodType } from 'enums';
import { useMutation, useUserContext, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { ParsingMethod, CreateParsingMethodsDto, OrderAutomation } from 'types';
import { validationUtils } from 'utils';

import { ParsingMethodsTestForm } from './ParsingMethodsTestForm';

type Values = CreateParsingMethodsDto & { applyToAll: boolean };

type Props = {
  bankId?: string;
  orderAutomation: OrderAutomation;
  onSuccess: (data: ParsingMethod[], applyToAll: boolean) => void;
};

export const ParsingMethodsMultipleForm: React.FC<Props> = ({
  bankId,
  orderAutomation,
  onSuccess,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.parsing_methods',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const options = map(ParsingMethodType, (type) => ({
    value: type,
    label: t(`types.${type}`),
  }));
  const { getTradeMethodsOptions } = useUserContextUtils();
  const { tradeMethods } = useUserContext();

  const initialValues: Values = useMemo(
    () => ({
      template: '',
      type: ParsingMethodType.Payin,
      description: '',
      tradeMethodsIds: [],
      applyToAll: false,
    }),
    [],
  );

  const prepareParsingMethod = useCallback((parsingMethod: Values) => {
    if (parsingMethod.type !== ParsingMethodType.Technical) {
      parsingMethod.description = null;
    }

    return parsingMethod;
  }, []);

  const createParsingMethods = useMutation<ParsingMethod[], AxiosError, Values>(
    (parsingMethod) =>
      parsingMethodsApi.createByTradeMethodsIds(
        omit(parsingMethod, ['applyToAll']),
      ),
    {
      onBeforeMutation: prepareParsingMethod,
    },
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      createParsingMethods.mutate(values, {
        onSuccess: (data) => onSuccess(data, values.applyToAll),
        onError: (error) => {
          formikHelpers.setErrors(validationUtils.getFormErrors(error));
        },
        onSettled: () => formikHelpers.setSubmitting(false),
      });
    },
    [onSuccess, createParsingMethods],
  );

  const tradeMethodsOptions = useMemo(() => {
    if (!bankId) {
      return [];
    }

    const bankTradeMethods = filter(
      tradeMethods,
      (tradeMethod) => tradeMethod.bankId === bankId,
    );

    return getTradeMethodsOptions(bankTradeMethods);
  }, [bankId, tradeMethods, getTradeMethodsOptions]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Fragment>
          <Form>
            <FormControls>
              <FormikTextField
                label={t('details_form.fields.template')}
                name="template"
                required
              />
              {values.type === ParsingMethodType.Technical && (
                <FormikTextField
                  label={t('details_form.fields.description')}
                  name="description"
                />
              )}
              <FormikSelect
                label={t('details_form.fields.type')}
                name="type"
                options={options}
              />
              {!isEmpty(tradeMethodsOptions) && (
                <FormikMultiSelect
                  label={t('details_form.fields.trade_methods')}
                  name="tradeMethodsIds"
                  options={tradeMethodsOptions}
                  required
                />
              )}
              <FormikCheckbox
                label={t('details_form.fields.apply_to_all')}
                name="applyToAll"
              />
            </FormControls>
            <FormActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                {tCommon('buttons.save')}
              </Button>
            </FormActions>
          </Form>
          <Divider sx={{ mt: 4 }} />
          <Paper sx={{ mt: 4 }}>
            <ParsingMethodsTestForm
              template={values.template}
              message={orderAutomation.text}
            />
          </Paper>
        </Fragment>
      )}
    </Formik>
  );
};
