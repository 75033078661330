import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { Dialog, DialogProps } from 'components';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrderAutomationRequest } from 'types';

import { AutomationRequestsTable } from './AutomationRequestsTable';

type Props = {
  queryResult: UseQueryResult<PayoutOrderAutomationRequest[]>;
} & DialogProps;

export const AutomationRequestsDialog: React.FC<Props> = ({
  queryResult,
  open,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.automation_requests',
  });
  const [formatJson, setFormatJson] = useState(false);
  const prevOpen = usePrevious(open);

  const toggleSetFormatJson = useCallback(() => {
    setFormatJson((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!prevOpen && open) {
      queryResult.refetch();
    }
  }, [prevOpen, open, queryResult]);

  return (
    <Dialog
      title={t('title')}
      open={open}
      modal
      maxWidth="xl"
      onClose={onClose}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={formatJson} onChange={toggleSetFormatJson} />
          }
          label={t('dialog.format_json')}
        />
      </FormGroup>
      <AutomationRequestsTable
        queryResult={queryResult}
        formatJson={formatJson}
      />
    </Dialog>
  );
};
