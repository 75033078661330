import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  FormControlProps,
  IconButton,
  TextField,
  InputAdornment,
  InputProps,
} from '@mui/material';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useState } from 'react';

import { useFormikError } from 'hooks';

type Props = {
  name: string;
  label: string;
  size?: 'small' | 'medium';
  helperText?: string;
} & Partial<FormControlProps> &
  Omit<Partial<InputProps>, 'margin' | 'size'>;

export const FormikPasswordInput: React.FC<Props> = ({
  name,
  label,
  autoComplete,
  size = 'small',
  helperText,
  ...rest
}) => {
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(() => {
    setVisiblePassword(!visiblePassword);
  }, [visiblePassword]);

  const [field, , helpers] = useField(name);

  const { errorVisible, errorMessage } = useFormikError(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      helpers.setValue(event.target.value),
    [helpers],
  );

  return (
    <TextField
      size={size}
      {...rest}
      placeholder={label}
      type={visiblePassword ? 'text' : 'password'}
      value={field.value as string}
      error={errorVisible}
      InputProps={{
        autoComplete,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              size="small"
            >
              {visiblePassword ? (
                <VisibilityOffIcon fontSize={size} />
              ) : (
                <VisibilityIcon fontSize={size} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      helperText={errorMessage || helperText}
    />
  );
};
