import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { CopyText } from 'components';

type Props = {
  title: string;
  value?: ReactNode;
  canCopy?: boolean;
  description?: string;
};

export const DescriptionRow: FC<Props> = ({
  title,
  value,
  canCopy,
  description,
}) => (
  <Stack direction="column" spacing={0.5}>
    <Typography variant="subtitle2">
      {title}
      {description && (
        <Tooltip title={description}>
          <HelpIcon sx={{ ml: 1 }} fontSize="small" color="secondary" />
        </Tooltip>
      )}
    </Typography>
    {canCopy && typeof value === 'string' ? (
      <CopyText text={value} />
    ) : (
      value || '-'
    )}
  </Stack>
);
