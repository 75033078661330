import { env } from 'config';
import { P2PProviderPayinWebhook, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/p2p-providers-payin-webhooks`;

export const p2pProvidersPayinWebhooksApi = {
  getForOrder:
    (providerOrderId: string) =>
    async (params?: Partial<QueryParams>): Promise<P2PProviderPayinWebhook[]> =>
      (
        await apiUtils.getWithQueryParams(
          `${url}/order/${providerOrderId}`,
          params,
        )
      ).data,
};
