import { Form, Formik, FormikHelpers } from 'formik';
import { assign, keys, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikTextField,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { BankDto } from 'types';

type Values = BankDto;

type Props = DialogProps<CloseFormikDialogResult<Values>>;

export const BankDetailsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.banks',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const title = useMemo(
    () =>
      data ? t('details_dialog.edit_title') : t('details_dialog.create_title'),
    [t, data],
  );

  const getInitialValues = useCallback(
    () => ({
      code: '',
      name: '',
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string().required(tCommon('errors.required')),
        name: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<Values>) => {
      if (!data.ok) {
        data.data?.formikHelpers?.resetForm();
      }
      onClose(data);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { formikHelpers, values } });
    },
    [handleClose],
  );

  useEffect(() => {
    const _initialValues = getInitialValues();
    setInitialValues(assign(_initialValues, pick(data, keys(_initialValues))));
  }, [data, getInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      validateOnChange
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
          okDisabled={!formik.isValid}
          {...rest}
        >
          <Form>
            <FormControls>
              <FormikTextField label={t('fields.code')} name="code" required />
              <FormikTextField label={t('fields.name')} name="name" required />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
