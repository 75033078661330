import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { TranslationNamespace } from 'i18n';

import { TemplateErrorHandledOrderAutomation } from './TemplateErrorHandledOrderAutomation';

import {
  ActiveOrderAutomation,
  AllOrderAutomation,
  ArchiveOrderAutomation,
  ErrorOrderAutomation,
  TechnicalOrderAutomation,
  TemplateErrorOrderAutomation,
  ErrorReviewedOrderAutomation,
} from '.';

enum OrderAutomationTab {
  Active = 'active',
  Archive = 'archive',
  Error = 'error',
  ErrorReviewed = 'error_reviewed',
  TemplateError = 'template_error',
  TemplateErrorHandled = 'template_error_handled',
  Technical = 'technical',
  All = 'all',
}

export const ManageOrderAutomationPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.order_automation',
  });

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={OrderAutomationTab}>
        <Tab value={OrderAutomationTab.Active} label={t('tabs.active')} />
        <Tab value={OrderAutomationTab.Archive} label={t('tabs.archive')} />
        <Tab value={OrderAutomationTab.Error} label={t('tabs.error')} />
        <Tab
          value={OrderAutomationTab.ErrorReviewed}
          label={t('tabs.error_reviewed')}
        />
        <Tab
          value={OrderAutomationTab.TemplateError}
          label={t('tabs.template_error')}
        />
        <Tab
          value={OrderAutomationTab.TemplateErrorHandled}
          label={t('tabs.template_error_handled')}
        />
        <Tab value={OrderAutomationTab.Technical} label={t('tabs.technical')} />
        <Tab value={OrderAutomationTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrderAutomationTab.Active}>
        <ActiveOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.Archive}>
        <ArchiveOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.Error}>
        <ErrorOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.ErrorReviewed}>
        <ErrorReviewedOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.TemplateError}>
        <TemplateErrorOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.TemplateErrorHandled}>
        <TemplateErrorHandledOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.Technical}>
        <TechnicalOrderAutomation />
      </QueryTabPanel>
      <QueryTabPanel value={OrderAutomationTab.All}>
        <AllOrderAutomation />
      </QueryTabPanel>
    </Fragment>
  );
};
