import { find } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdditionalOrderColumn, FilterDefinitionType, OrdersTab } from 'enums';
import { Orders } from 'features/orders';
import { useCurrencies, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { formUtils, orderUtils, requisitesUtils } from 'utils';

export const TraderRequisitesVerificationOrders: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const { paymentTypes, banks } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: t('payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: t('requisites_verification_status'),
        name: 'requisitesVerificationStatus',
        type: FilterDefinitionType.Select,
        options: orderUtils.getOrderRequisitesVerificationStatusOptions(),
        getDisplayName: orderUtils.getOrderRequisitesVerificationStatusLabel,
      },
      {
        label: t('bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: t('fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [banks, fiatCurrenciesOptions, getFiatCurrencyCode, paymentTypes, t],
  );

  return (
    <Orders
      tab={OrdersTab.RequisitesVerification}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.TraderCard,
        AdditionalOrderColumn.RequisitesVerificationResult,
      ]}
    />
  );
};
