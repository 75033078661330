import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Divider, Tooltip, Typography } from '@mui/material';
import { map, sortBy } from 'lodash';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { DataWrapper, DateLabel } from 'components';
import { PayoutOrderStatus, PayoutOrderStatusDetails } from 'enums';
import { TranslationNamespace } from 'i18n';
import {
  PayoutOrder,
  PayoutOrderStatusHistory as PayoutOrderStatusHistoryType,
} from 'types';
import { formatUtils, orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
  loading?: boolean;
  statusHistoryQueryResult: UseQueryResult<PayoutOrderStatusHistoryType[]>;
};

export const PayoutOrderStatusHistory: React.FC<Props> = ({
  order,
  loading,
  statusHistoryQueryResult,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.table.columns',
  });

  const renderHistoryStatusItem = useCallback(
    (
      status: PayoutOrderStatus,
      statusDetails: PayoutOrderStatusDetails,
      statusDate: Date,
      traderName?: string,
    ) => (
      <div className="tw-my-1">
        <div className="tw-flex">
          <Typography color="textSecondary">
            {orderUtils.getStatusLabel(status)}
          </Typography>
          {(traderName || statusDetails) && (
            <Tooltip
              title={
                <div>
                  {traderName && <div className="tw-text-xs">{traderName}</div>}
                  {statusDetails && (
                    <div className="tw-text-xs">
                      {orderUtils.getPayoutStatusDetailsLabel(statusDetails)}
                    </div>
                  )}
                </div>
              }
            >
              <InfoOutlinedIcon
                sx={{ ml: 1 }}
                fontSize="small"
                color="primary"
              />
            </Tooltip>
          )}
        </div>
        <DateLabel>{formatUtils.formatDate(statusDate)}</DateLabel>
      </div>
    ),
    [],
  );

  return (
    <Fragment>
      <Divider textAlign="left">{t('status_history')}</Divider>
      <DataWrapper isLoading={loading || statusHistoryQueryResult.isLoading}>
        <div className="tw-flex tw-flex-wrap">
          {map(
            sortBy(statusHistoryQueryResult.data, (s) => s.statusAt),
            (orderStatus, index) => (
              <div key={index} className="tw-flex tw-align-middle">
                {renderHistoryStatusItem(
                  orderStatus.status,
                  orderStatus.statusDetails,
                  orderStatus.statusAt,
                  orderStatus.trader?.user?.name,
                )}
                <div className="tw-mx-2 tw-self-center">{'→'}</div>
              </div>
            ),
          )}
          {renderHistoryStatusItem(
            order.status,
            order.statusDetails,
            order.statusAt,
          )}
        </div>
      </DataWrapper>
    </Fragment>
  );
};
