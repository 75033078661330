import { ArrowRightAlt as ArrowIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useUserContext } from 'hooks';

export const BanksHelperText = ({ from, to }: { from: string; to: string }) => {
  const { banks } = useUserContext();
  const banksMap = useMemo(() => keyBy(banks, 'id'), [banks]);

  return !!from && !!to ? (
    <Typography variant="caption" color="GrayText" className="!tw-mt-0 tw-mb-2">
      <span>
        {banksMap[from]?.name}
        <ArrowIcon sx={{ ml: 1 }} fontSize="small" />
        {banksMap[to]?.name}
      </span>
    </Typography>
  ) : null;
};
