import { Fragment, useCallback, useMemo, useState } from 'react';
import { MutateOptions } from 'react-query';

import { RequisitesStatusToggle } from 'components';
import { RequisitesStatus, RequisitesVerificationStatus } from 'enums';
import { useMeta } from 'hooks';
import { PayoutRequisites, Requisites } from 'types';

import { RequisitesBlocked } from './RequisitesBlocked';
import { RequisitesVerificationInfo } from './RequisitesVerificationInfo';

type Props = {
  requisites: Requisites | PayoutRequisites;
  canChangeStatus?: boolean;
  isPayout?: boolean;
  updateStatus: (
    id: string,
    status: RequisitesStatus,
    options: MutateOptions<any, any, any, any>,
  ) => void;
};

export const RequisitesStatusInfo: React.FC<Props> = ({
  requisites,
  canChangeStatus,
  isPayout,
  updateStatus,
}) => {
  const { payinRequisitesVerificationRequired } = useMeta();
  const [status, setStatus] = useState(requisites.status);
  const isVerificationExpired = useMemo(
    () =>
      ![
        RequisitesVerificationStatus.Success,
        RequisitesVerificationStatus.Disabled,
      ].includes((requisites as Requisites).verificationStatus),
    [requisites],
  );
  const isPayinVerificationRequired = useMemo(
    () => !isPayout && payinRequisitesVerificationRequired,
    [isPayout, payinRequisitesVerificationRequired],
  );

  const requisitesToggleDisabled = useMemo(
    () =>
      !canChangeStatus ||
      (isPayinVerificationRequired && isVerificationExpired),
    [canChangeStatus, isPayinVerificationRequired, isVerificationExpired],
  );

  const handleStatusChange = useCallback(
    (updatedStatus: RequisitesStatus) =>
      updateStatus(requisites.id, updatedStatus, {
        onSuccess: () => setStatus(updatedStatus),
      }),
    [updateStatus, requisites.id],
  );

  if (status === RequisitesStatus.Blocked) {
    return (
      <RequisitesBlocked
        blockedBy={requisites.blockedBy}
        blockedMessage={requisites.blockedMessage}
        blockedAt={requisites.blockedAt}
        statusDetails={requisites.statusDetails}
      />
    );
  }

  return (
    <Fragment>
      <RequisitesStatusToggle
        status={status}
        disabled={requisitesToggleDisabled}
        updateStatus={handleStatusChange}
      />
      {!isPayout && (
        <RequisitesVerificationInfo requisites={requisites as Requisites} />
      )}
    </Fragment>
  );
};
