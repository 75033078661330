import { filter, find, map, some, without } from 'lodash';

import { formUtils } from './form.utils';
import { requisitesUtils } from './requisites.utils';
import { Bank, Currency, PaymentType, TradeMethod } from '../types';

export type TradeMethodKeys = keyof Omit<TradeMethod, 'id'>;

export const getAvailableValuesByTradeMethods = <
  T extends { id: string },
  V extends Partial<Record<TradeMethodKeys, string>>,
>(
  tradeMethods: TradeMethod[],
  values: V,
  data: T[],
  property: TradeMethodKeys,
) => {
  const properties: TradeMethodKeys[] = without(
    ['bankId', 'paymentTypeId', 'fiatCurrencyId'],
    property,
  );

  return filter(data, (item) =>
    some(
      tradeMethods,
      (method: TradeMethod) =>
        method[property] === item.id &&
        (!values[properties[0]] ||
          method[properties[0]] === values[properties[0]]) &&
        (!values[properties[1]] ||
          method[properties[1]] === values[properties[1]]),
    ),
  );
};

const getTradeMethodLabel = ({
  tradeMethodId,
  tradeMethods,
  banks,
  paymentTypes,
  fiatCurrencies,
}: {
  tradeMethodId: string;
  tradeMethods: TradeMethod[];
  banks: Bank[];
  paymentTypes: PaymentType[];
  fiatCurrencies: Currency[];
}) => {
  const tradeMethod = find(tradeMethods, ({ id }) => tradeMethodId === id);
  if (!tradeMethod) {
    return '';
  }
  const paymentType = find(paymentTypes, { id: tradeMethod.paymentTypeId });
  const paymentTypeLabel = paymentType
    ? requisitesUtils.getPaymentTypeLabel(paymentType)
    : tradeMethod.paymentTypeId;

  return `${
    find(banks, ({ id }) => id === tradeMethod.bankId)?.name
  } / ${paymentTypeLabel} / ${
    find(fiatCurrencies, ({ id }) => id === tradeMethod.fiatCurrencyId)?.code
  }`;
};

const getTradeMethodOptions = ({
  tradeMethods,
  banks,
  paymentTypes,
  fiatCurrencies,
}: {
  tradeMethods: TradeMethod[];
  banks: Bank[];
  paymentTypes: PaymentType[];
  fiatCurrencies: Currency[];
}) =>
  formUtils.getOptions(
    map(tradeMethods, ({ id }) => ({
      id,
      name: getTradeMethodLabel({
        tradeMethodId: id,
        tradeMethods,
        banks,
        paymentTypes,
        fiatCurrencies,
      }),
    })),
  );

export const tradeMethodsUtils = {
  getTradeMethodLabel,
  getTradeMethodOptions,
};
