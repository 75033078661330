import { Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import { useCallback } from 'react';

import { CloseFormikDialogResult, Dialog, DialogProps } from 'components';

type Values = any;

type Props = {
  formikProps: Omit<FormikConfig<Values>, 'onSubmit'>;
  dialogProps: DialogProps;
  children: ({ formik }: { formik: FormikProps<Values> }) => React.ReactNode;
};

export const FormikDialog: React.FC<Props> = ({
  formikProps,
  dialogProps,
  children,
}) => {
  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result?.data?.formikHelpers.resetForm();
      }
      dialogProps.onClose(result);
    },
    [dialogProps],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  return (
    <Formik enableReinitialize {...formikProps} onSubmit={handleSubmit}>
      {(formik) => (
        <Dialog
          {...dialogProps}
          okDisabled={!formik.isValid}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
        >
          {children({ formik })}
        </Dialog>
      )}
    </Formik>
  );
};
