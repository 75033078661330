import { Tab } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { useQueryTab, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { TradeMethodPayoutPDFTemplate } from 'types';

import {
  PayoutPDFTemplates,
  PayoutPDFtemplateDetailsDialog,
} from './PayoutPDFTemplates';

enum TradeMethodFilesValidationTab {
  PayoutPDFTemplates = 'payout-pdf-templates',
}

export const TradeMethodFilesValidationPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.trade_methods.files_validation',
  });
  const { getTradeMethodLabel } = useUserContextUtils();
  const { id: tradeMethodId = '' } = useParams<{ id: string }>();
  const { tab } = useQueryTab();
  const [
    payoutPDFtemplateDetailsDialogOpen,
    setPayoutPDFtemplateDetailsDialogOpen,
  ] = useState(false);

  const subtitle = useMemo(
    () => getTradeMethodLabel(tradeMethodId),
    [getTradeMethodLabel, tradeMethodId],
  );

  const renderRightContentButton = useCallback(() => {
    if (tab === TradeMethodFilesValidationTab.PayoutPDFTemplates) {
      return {
        onClick: () => setPayoutPDFtemplateDetailsDialogOpen(true),
      };
    }
  }, [tab]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        subtitle={subtitle}
        divider={false}
        rightContentButton={renderRightContentButton()}
      />
      <QueryTabs tabsEnum={TradeMethodFilesValidationTab}>
        <Tab
          value={TradeMethodFilesValidationTab.PayoutPDFTemplates}
          label={t('payout_pdf_templates.title')}
        />
      </QueryTabs>
      <QueryTabPanel value={TradeMethodFilesValidationTab.PayoutPDFTemplates}>
        <PayoutPDFTemplates tradeMethodId={tradeMethodId} />
      </QueryTabPanel>
      <PayoutPDFtemplateDetailsDialog
        open={payoutPDFtemplateDetailsDialogOpen}
        data={{ tradeMethodId } as TradeMethodPayoutPDFTemplate}
        onClose={() => setPayoutPDFtemplateDetailsDialogOpen(false)}
      />
    </Fragment>
  );
};
