import { Grid } from '@mui/material';
import { findIndex, groupBy, map, mapValues, sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { assetsApi } from 'api';
import {
  DataWrapper,
  FundsRequestDialog,
  AssetCard,
  InsuranceDepositWithdrawalDialog,
  CloseDialogResult,
  AssetExchangeDialog,
} from 'components';
import { AssetType, OperationType, QueryKey, UserRole } from 'enums';
import { useCurrencies } from 'hooks';
import { Asset, Trader } from 'types';

type Props = {
  role: UserRole;
  trader?: Trader;
};

export const MyAssets: React.FC<Props> = ({ role, trader }) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery(
    QueryKey.MyAssets,
    role === UserRole.Merchant ? assetsApi.getAllShops : assetsApi.getAllMy,
  );
  const { assetCurrencies } = useCurrencies();
  const sortedAssets = useMemo(() => {
    const assetGroups = mapValues(
      groupBy(queryResult.data, (asset) => asset.type),
      (assets) =>
        sortBy(assets, (asset) =>
          findIndex(assetCurrencies, { id: asset.assetCurrencyId }),
        ),
    );
    return [
      ...(assetGroups[AssetType.Balance] || []),
      ...(assetGroups[AssetType.InsuranceDeposit] || []),
    ];
  }, [queryResult.data, assetCurrencies]);

  const [fundsRequestDialogProps, setFundsRequestDialogProps] = useState<{
    open: boolean;
    asset?: Asset;
  }>({ open: false });

  const [exchangeDialogProps, setExchangeDialogProps] = useState<{
    open: boolean;
    data?: Asset;
  }>({ open: false });

  const [
    insuranceDepositWithdrawalDialogProps,
    setInsuranceDepositWithdrawalDialogProps,
  ] = useState<{
    open: boolean;
    asset?: Asset;
  }>({ open: false });

  const handleFundsRequest = useCallback(
    (asset: Asset, operationType: OperationType) => {
      if (operationType === OperationType.Deposit) {
        return;
      }
      if (asset.type === AssetType.InsuranceDeposit) {
        setInsuranceDepositWithdrawalDialogProps({
          open: true,
          asset,
        });
      } else {
        setFundsRequestDialogProps({
          open: true,
          asset,
        });
      }
    },
    [],
  );

  const handleFundsRequestDialogClose = useCallback(() => {
    setFundsRequestDialogProps({
      open: false,
    });
    queryClient.invalidateQueries(QueryKey.MyAssets);
    queryClient.invalidateQueries(QueryKey.ShopAssets);
  }, [queryClient]);

  const handleInsuranceWithdrawal = useCallback(
    ({ ok }: CloseDialogResult) => {
      if (ok) {
        queryClient.invalidateQueries(QueryKey.MyAssets);
      }
      setInsuranceDepositWithdrawalDialogProps({ open: false });
    },
    [queryClient],
  );

  const handleExchange = useCallback((asset: Asset) => {
    setExchangeDialogProps({ data: asset, open: true });
  }, []);

  return (
    <div>
      <DataWrapper queryResult={queryResult}>
        <Grid container spacing={3}>
          {map(sortedAssets, (asset, index) => (
            <Grid key={asset.id || index} item xs={12} sm={6} lg={4}>
              <AssetCard
                trader={trader}
                asset={asset}
                onFundsRequest={() =>
                  handleFundsRequest(asset, OperationType.Withdrawal)
                }
                onExchange={() => {
                  handleExchange(asset);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DataWrapper>
      <FundsRequestDialog
        {...fundsRequestDialogProps}
        onClose={handleFundsRequestDialogClose}
      />
      <InsuranceDepositWithdrawalDialog
        {...insuranceDepositWithdrawalDialogProps}
        onClose={handleInsuranceWithdrawal}
      />
      <AssetExchangeDialog
        {...exchangeDialogProps}
        onClose={() => setExchangeDialogProps({ open: false })}
      />
    </div>
  );
};
