import { map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGridColumnDefinition } from 'components';
import {
  AppConfigArea,
  AppConfigKey,
  FilterDefinitionType,
  PayoutRequisitesAutomationType,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigFilters, FilterDefinition } from 'types';
import { appConfigUtils, payoutRequisitesAutomationUtils } from 'utils';

import { ConfigsPageTab } from '../ConfigsPageTab';

export const PayoutRequisitesAutomationTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<AppConfig>[] => [
      {
        header: t('fields.payout_requisites_automation_type'),
        valueKey: 'payoutRequisitesAutomationType',
        valueFormatter: payoutRequisitesAutomationUtils.getTypeLabel,
      },
    ],
    [t],
  );

  const payoutRequisitesAutomationTypeOptions = useMemo(
    () =>
      map(PayoutRequisitesAutomationType, (type) => ({
        label: payoutRequisitesAutomationUtils.getTypeLabel(type),
        value: type,
      })),
    [],
  );

  const additionalFilters: FilterDefinition<AppConfigFilters>[] = useMemo(
    () => [
      {
        label: t('fields.payout_requisites_automation_type'),
        name: 'payoutRequisitesAutomationType',
        type: FilterDefinitionType.Select,
        options: payoutRequisitesAutomationTypeOptions,
        getDisplayName: payoutRequisitesAutomationUtils.getTypeLabel,
      },
      {
        label: t('fields.key'),
        name: 'key',
        type: FilterDefinitionType.Enum,
        enum: [AppConfigKey.TradeMethods],
        getDisplayName: appConfigUtils.getKeyLabel,
      },
    ],
    [payoutRequisitesAutomationTypeOptions, t],
  );

  return (
    <ConfigsPageTab
      area={AppConfigArea.PayoutRequisitesAutomation}
      additionalColumns={additionalColumns}
      additionalFilters={additionalFilters}
    />
  );
};
