import { Popover, IconButton, Box, Tooltip } from '@mui/material';
import { find, map } from 'lodash';
import React, { useState, useCallback, useMemo } from 'react';

export type ColorCodeSelectOption = {
  color: string;
  value: string;
};

type Props = {
  value: string;
  options: ColorCodeSelectOption[];
  tooltip?: string;
  asToggle?: boolean;
  onChange: (value: string) => void;
};

export const ColorCodeSelect: React.FC<Props> = ({
  value,
  options,
  tooltip,
  asToggle,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const selectedOption = useMemo(
    () => find(options, { value }),
    [options, value],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectColor = useCallback(
    (value: string) => {
      onChange(value);
      handleClose();
    },
    [handleClose, onChange],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const isCmdOrCtrl = event.ctrlKey || event.metaKey; // metaKey for Mac (Cmd), ctrlKey for Windows

      if (asToggle && !isCmdOrCtrl && options?.length > 1) {
        handleSelectColor(
          selectedOption?.value === options[0].value
            ? options[1].value
            : options[0].value,
        );
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [asToggle, handleSelectColor, options, selectedOption?.value],
  );

  const renderCurrentIcon = useCallback(
    () => (
      <IconButton
        onClick={handleClick}
        sx={{
          backgroundColor: selectedOption?.color,
          width: 20,
          height: 20,
          borderRadius: '50%',
          border: '1px solid #ccc',
          '&:hover': {
            opacity: 0.8,
            backgroundColor: selectedOption?.color,
            borderWidth: 2,
          },
        }}
      />
    ),
    [handleClick, selectedOption?.color],
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {tooltip ? (
        <Tooltip title={tooltip}>{renderCurrentIcon()}</Tooltip>
      ) : (
        renderCurrentIcon()
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: 1 }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            paddingX: 2,
            paddingY: 1,
            border: '1px solid gray',
            borderRadius: 10,
          }}
        >
          {map(options, ({ color, value }) => (
            <IconButton
              key={color}
              onClick={() => handleSelectColor(value)}
              sx={{
                backgroundColor: color,
                width: 18,
                height: 18,
                borderRadius: '50%',
                border: '1px solid gray',
                transition: 'background-color 0.2s',
                '&:hover': {
                  opacity: 0.75,
                  backgroundColor: color,
                },
              }}
            />
          ))}
        </Box>
      </Popover>
    </div>
  );
};
