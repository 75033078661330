import { HowToReg as HowToRegIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  CircularProgress,
} from '@mui/material';
import { AxiosError } from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { invitesApi } from 'api';
import {
  FormActions,
  FormControls,
  FormikPasswordInput,
  FormikTextField,
  RoleLabel,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AcceptInviteData, Invite, InviteToAccept } from 'types';
import { authUtils, validationUtils } from 'utils';

type Values = Pick<
  AcceptInviteData,
  'name' | 'telegram' | 'password' | 'referralUserId'
> &
  Pick<Invite, 'email'> & {
    confirmPassword: string;
  };

export const AcceptInvitePage = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'pages.accept_invite',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const params = useParams<{ token: string }>();
  const navigate = useNavigate();
  const paramsToken = useMemo(() => params.token as string, [params.token]);

  const acceptInviteSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(tCommon('errors.required'))
          .email(tCommon('errors.email')),
        password: Yup.string().required(tCommon('errors.required')),
        confirmPassword: Yup.string()
          .required(tCommon('errors.required'))
          .oneOf([Yup.ref('password')], tCommon('errors.password_match')),
      }),
    [tCommon],
  );

  const [initialValues, setInitialValues] = useState<Values>({
    name: '',
    telegram: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { mutate: acceptInvite } = useMutation(invitesApi.accept, {
    onSuccess: (authData: any) => {
      authUtils.setAuthData(authData);
      navigate(ROUTE_PATH.ADMIN.MAIN);
    },
    onError: (error: AxiosError) => {
      formik.setErrors(validationUtils.getFormErrors(error));
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const {
    mutate: getInviteToAccept,
    data: inviteToAccept,
    isLoading: inviteToAcceptLoading,
  } = useMutation(invitesApi.getInviteToAccept, {
    onSuccess: (inviteToAccept: InviteToAccept) => {
      setInitialValues({
        name: '',
        telegram: '',
        email: inviteToAccept.email,
        password: '',
        confirmPassword: '',
        referralUserId: inviteToAccept.referralUserId,
      });
    },
    notifierType: 'none',
  });

  const handleSubmit = useCallback(
    (values: Values) => {
      const acceptInviteData = {
        name: values.name,
        telegram: values.telegram,
        password: values.password,
        referralUserId: values.referralUserId,
      };
      acceptInvite({ token: paramsToken, data: acceptInviteData });
    },
    [paramsToken, acceptInvite],
  );

  useEffect(() => {
    if (paramsToken) {
      getInviteToAccept(paramsToken);
    }
  }, [getInviteToAccept, paramsToken]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: acceptInviteSchema,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  if (!inviteToAccept) {
    return (
      <div className="tw-text-center tw-my-8">
        {inviteToAcceptLoading ? <CircularProgress /> : t('errors.not_found')}
      </div>
    );
  }

  // TODO: markup is taken from examples. Needs to be revisited
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <HowToRegIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('title')}
        </Typography>
        <div className="tw-flex tw-my-4">
          <span className="tw-mr-1">{`${t('role_title')}:`}</span>
          <RoleLabel role={inviteToAccept.role} />
        </div>
        <FormikProvider value={formik}>
          <Form>
            <FormControls sx={{ display: 'block' }}>
              <FormikTextField
                id="name"
                label={t('form.name')}
                name="name"
                autoComplete="name"
                fullWidth
                required
                autoFocus
              />
              <FormikTextField
                id="telegram"
                fullWidth
                label={t('form.telegram')}
                name="telegram"
              />
              <FormikTextField
                id="email"
                label={t('form.email')}
                name="email"
                fullWidth
                disabled
              />
              <FormikPasswordInput
                id="password"
                label={t('form.password')}
                name="password"
                autoComplete="new-password"
                type="password"
                required
                fullWidth
              />
              <FormikPasswordInput
                id="confirm-password"
                name="confirmPassword"
                label={t('form.confirm_password')}
                autoComplete="new-password"
                type="password"
                required
                fullWidth
              />
              {initialValues.referralUserId && (
                <FormikTextField
                  id="referralUserId"
                  fullWidth
                  label={t('form.referral_id')}
                  name="referralUserId"
                  disabled
                />
              )}
            </FormControls>
            <FormActions>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {t('form.submit_btn')}
              </Button>
            </FormActions>
          </Form>
        </FormikProvider>
      </Box>
    </Container>
  );
};
