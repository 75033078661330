import { Grid } from '@mui/material';
import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoCard, StylizedMoney } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  balances: { id: string; amount: number }[];
};

export const TotalBalance: React.FC<Props> = ({ balances }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'assets.total_balance',
  });

  return (
    <Grid container spacing={3}>
      {map(balances, ({ id, amount }) => (
        <Grid key={id} item xs={12} sm={6} lg={4}>
          <InfoCard
            title={t('title')}
            content={
              <StylizedMoney
                variant="h2"
                symbol
                value={amount}
                assetCurrencyId={id}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
