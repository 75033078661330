import { Divider, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { filter, find, isEmpty, map, some, toUpper, values } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import {
  currencyExchangeApi,
  ordersApi,
  requisitesApi,
  shopsApi,
  tradersApi,
} from 'api';
import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikNumericField,
  FormikSelect,
  FormikSelectOption,
  FormikTextField,
  RequisitesSelect,
  ShopSelect,
  TraderSelect,
} from 'components';
import { QueryKey, WebhookMethod } from 'enums';
import {
  useCurrencies,
  useMutation,
  useShopsQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { CreateOrderDto } from 'types';
import { orderUtils } from 'utils';

type Values = CreateOrderDto;

type Props = DialogProps;

export const CreateOrderDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.create_order_dialog',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { tradeMethods } = useUserContext();
  const { role } = useUser();
  const queryClient = useQueryClient();
  const { fiatCurrenciesOptions } = useCurrencies();
  const [mappedFiatCurrenciesOptions, setMappedFiatCurrenciesOptions] =
    useState<FormikSelectOption[]>([]);

  const initialValues: Values = useMemo(
    () => ({
      amount: 0,
      fiatCurrencyId: '',
      shopId: '',
      traderId: '',
      requisitesId: '',
      customer: {
        id: '',
        name: '',
        email: '',
        phone: '',
      },
      integration: {
        externalOrderId: '',
        callbackUrl: '',
        callbackMethod: '',
      },
    }),
    [],
  );

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultTraders = useQuery(QueryKey.Traders, () =>
    tradersApi.getAllAsRole(role)(),
  );
  const queryResultCurrencyExchange = useQuery(
    QueryKey.CurrencyExchangeRates,
    () => currencyExchangeApi.getRates(),
  );

  const { mutate: create } = useMutation(ordersApi.createAsRole(role), {
    onError: (error: AxiosError) => {
      formik.setErrors(orderUtils.getFormErrors(error));
    },
  });

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok) {
        result?.data?.formikHelpers.resetForm();
        onClose(result);
      } else if (result.data?.values) {
        create(result.data?.values, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.TraderDisputeOrders);
            result?.data?.formikHelpers.resetForm();
            onClose(result);
          },
        });
      }
    },
    [queryClient, create, onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { values, formikHelpers } });
    },
    [handleClose],
  );

  const validationSchema: Yup.ObjectSchema<Partial<Values>> = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .moreThan(0, tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        shopId: Yup.string().required(tCommon('errors.required')),
        traderId: Yup.string().required(tCommon('errors.required')),
        requisitesId: Yup.string().required(tCommon('errors.required')),
        customer: Yup.object().shape({
          id: Yup.string().required(tCommon('errors.required')),
          name: Yup.string(),
          email: Yup.string(),
          phone: Yup.string(),
        }),
        integration: Yup.object().shape({
          externalOrderId: Yup.string(),
          callbackUrl: Yup.string(),
          callbackMethod: Yup.string().is(values(WebhookMethod)),
        }),
      }),
    [tCommon],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  const queryResultRequisites = useQuery(
    [QueryKey.Requisites, formik.values.traderId],
    () => requisitesApi.getAllForTraderAsRole(role)(formik.values.traderId),
    { enabled: !!formik.values.traderId },
  );

  const handleShopSelectChange = useCallback(
    (newShopId: string) => {
      const shop = find(queryResultShops.data, { id: newShopId });
      const shopAssetCurrencyId = shop?.fiatCurrencyId;
      const shopFiatCurrencyId = shop?.fiatCurrencyId;
      const newMappedFiatCurrenciesOptions = shopFiatCurrencyId
        ? filter(fiatCurrenciesOptions, { value: shopFiatCurrencyId })
        : filter(
            fiatCurrenciesOptions,
            (option) =>
              some(tradeMethods, { fiatCurrencyId: option.value }) &&
              some(queryResultCurrencyExchange.data, {
                fiatCurrencyId: option.value,
                assetCurrencyId: shopAssetCurrencyId,
              }),
          );
      setMappedFiatCurrenciesOptions(newMappedFiatCurrenciesOptions);

      if (isEmpty(newMappedFiatCurrenciesOptions)) {
        formik.setFieldValue('fiatCurrencyId', '');
      }

      if (newMappedFiatCurrenciesOptions.length === 1) {
        formik.setFieldValue(
          'fiatCurrencyId',
          newMappedFiatCurrenciesOptions[0]?.value,
        );
      }
    },
    [
      formik,
      fiatCurrenciesOptions,
      queryResultCurrencyExchange.data,
      queryResultShops.data,
      tradeMethods,
    ],
  );

  const isFiatSelectDisabled = useMemo(
    () => mappedFiatCurrenciesOptions.length === 1,
    [mappedFiatCurrenciesOptions],
  );

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={t('title')}
        data={{ values: formik.values, formikHelpers: formik }}
        okDisabled={!formik.isValid}
        onClose={handleClose}
      >
        <FormControls>
          <Typography variant="subtitle1">
            {t('sections.order_info')}
          </Typography>
          <ShopSelect
            name="shopId"
            shops={queryResultShops.data}
            onChange={handleShopSelectChange}
            required
          />
          <FormControls row>
            <FormikNumericField
              label={t('fields.amount')}
              name="amount"
              required
              fullWidth
              allowNegative={false}
            />
            <FormikSelect
              label={t('fields.currency')}
              name="fiatCurrencyId"
              required
              fullWidth
              options={mappedFiatCurrenciesOptions}
              noneOption
              disabled={isFiatSelectDisabled}
            />
          </FormControls>
        </FormControls>
        <Divider sx={{ margin: '24px 0 12px' }} />
        <FormControls>
          <Typography variant="subtitle1">
            {t('sections.recipient_info')}
          </Typography>
          <TraderSelect
            name="traderId"
            required
            traders={queryResultTraders.data}
            onChange={() => {
              formik.setFieldValue('requisitesId', '');
            }}
          />
          <RequisitesSelect
            name="requisitesId"
            disabled={!formik.values.traderId}
            required
            requisites={queryResultRequisites.data}
            labelClassName="tw-max-w-[400px] tw-truncate"
            fiatCurrencyId={formik.values.fiatCurrencyId}
            crossFiatCurrencyId={
              find(queryResultShops.data, { id: formik.values.shopId })
                ?.payinFiatCurrencyExchange?.crossFiatCurrencyId
            }
          />
        </FormControls>
        <Divider sx={{ margin: '24px 0 12px' }} />
        <FormControls>
          <Typography variant="subtitle1">
            {t('sections.client_info')}
          </Typography>
          <FormikTextField name="customer.id" label={t('fields.id')} required />
          <FormikTextField name="customer.name" label={t('fields.name')} />
          <FormikTextField name="customer.email" label={t('fields.email')} />
          <FormikTextField name="customer.phone" label={t('fields.phone')} />
        </FormControls>
        <Divider sx={{ margin: '24px 0 12px' }} />
        <FormControls>
          <Typography variant="subtitle1">
            {t('sections.integration')}
          </Typography>
          <FormikTextField
            name="integration.externalOrderId"
            label={t('fields.external_order_id')}
          />
          <FormikTextField
            name="integration.callbackUrl"
            label={t('fields.callback_url')}
          />
          <FormikSelect
            name="integration.callbackMethod"
            label={t('fields.callback_method')}
            noneOption
            options={map(values(WebhookMethod), (method) => ({
              label: toUpper(method),
              value: method,
            }))}
          />
        </FormControls>
      </Dialog>
    </FormikProvider>
  );
};
