import { TextFieldProps, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { isNumber } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormatProps } from 'react-number-format';

import { FormikNumericField } from 'components';

type Props = {
  label: string;
  minName: string;
  maxName: string;
  percentageSuffix?: boolean;
  thousandSeparator?: string;
  allowNegative?: boolean;
  onChangeHandler?: (minValue: number, maxValue: number) => void;
} & Partial<NumericFormatProps> &
  Partial<TextFieldProps>;

export const FormikRange: React.FC<Props> = ({
  minName,
  maxName,
  label,
  suffix,
  percentageSuffix,
  allowNegative = false,
  thousandSeparator = ' ',
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const minValue = values[minName];
  const maxValue = values[maxName];

  const [isMinFocused, setIsMinFocused] = useState(false);
  const [isMaxFocused, setIsMaxFocused] = useState(false);

  const { t } = useTranslation();

  const handleMinBlur = useCallback(() => {
    if (isNumber(minValue) && isNumber(maxValue)) {
      if (minValue > maxValue) {
        setFieldValue(minName, maxValue);
      }
    }
    setIsMinFocused(false);
  }, [minValue, maxValue, setFieldValue, minName]);

  const handleMaxBlur = useCallback(() => {
    if (isNumber(maxValue) && isNumber(minValue)) {
      if (maxValue < minValue) {
        setFieldValue(maxName, minValue);
      }
    }
    setIsMaxFocused(false);
  }, [maxValue, minValue, setFieldValue, maxName]);

  return (
    <div className="tw-flex-col tw-pt-2">
      <Typography variant="body1" color="textSecondary">
        {`${label}:`}
      </Typography>
      <div className="tw-flex tw-gap-2 tw-mt-2">
        <FormikNumericField
          percentageSuffix={percentageSuffix}
          suffix={suffix}
          key={minName}
          name={minName}
          allowNegative={allowNegative}
          onBlur={handleMinBlur}
          placeholder={isMinFocused ? '' : t('filters.from')}
          onFocus={() => setIsMinFocused(true)}
          variant="standard"
          thousandSeparator={thousandSeparator}
        />
        {'—'}
        <FormikNumericField
          percentageSuffix={percentageSuffix}
          suffix={suffix}
          key={maxName}
          name={maxName}
          allowNegative={allowNegative}
          onBlur={handleMaxBlur}
          onFocus={() => setIsMaxFocused(true)}
          placeholder={isMaxFocused ? '' : t('filters.to')}
          variant="standard"
          thousandSeparator={thousandSeparator}
        />
      </div>
    </div>
  );
};
