import { isNumber } from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { formatUtils } from 'utils';

type Props = {
  total: number;
  used?: number;
  label?: string;
  formatter?: (value: number) => string;
};

export const StatisticalInfo: React.FC<Props> = ({
  total,
  used,
  label,
  formatter = formatUtils.formatNumber,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'common',
  });
  return (
    <div className="tw-text-xs tw-whitespace-nowrap">
      {label && `${label}: `}
      {isNumber(used) && (
        <Fragment>{`${formatter(used)} ${t('of')} `}</Fragment>
      )}
      {formatter(total)}
    </div>
  );
};
