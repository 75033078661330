import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { has, isEmpty, omit } from 'lodash';

import { QueryParams } from 'types';

const queryParamsToAxiosRequestConfig = (
  queryParams?: Partial<QueryParams>,
): AxiosRequestConfig<any> => {
  const isUseQueryPayload =
    has(queryParams, 'queryKey') && has(queryParams, 'meta'); // duck typing
  const params = isUseQueryPayload ? null : omit(queryParams, ['signal']);
  return {
    ...(queryParams?.signal && { signal: queryParams?.signal }),
    ...(!isEmpty(params) && { params }),
  };
};

const getWithQueryParams = (url: string, queryParams?: Partial<QueryParams>) =>
  axios.get(url, queryParamsToAxiosRequestConfig(queryParams));

const requestDownload = async <T>(
  config: AxiosRequestConfig<T>,
  filename: string,
) =>
  await axios
    .request({
      responseType: 'blob',
      ...config,
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });

const withAxiosInstance =
  <TVariables extends object = any, TResult = any>(
    apiFn: (
      axiosInstance: AxiosInstance,
      variables: TVariables,
    ) => Promise<TResult>,
  ) =>
  (
    variables: TVariables & { axiosInstance?: AxiosInstance },
  ): Promise<TResult> => {
    const { axiosInstance, ...restVariables } = variables;
    const instance = axiosInstance || axios;
    return apiFn(instance, restVariables as TVariables);
  };

export const apiUtils = {
  requestDownload,
  getWithQueryParams,
  withAxiosInstance,
};
