import axios from 'axios';

import { env } from 'config';
import { OperationType } from 'enums';
import {
  FundsRequest,
  CreateFundsRequest,
  QueryParams,
  PaginatedData,
  CreatePlatformFundsRequest,
} from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/funds-requests`;

const createWithdrawal = async (
  fundsRequest: CreateFundsRequest,
): Promise<FundsRequest> =>
  (await axios.post(`${url}/withdrawal`, fundsRequest)).data;

const createPlatformWithdrawal = apiUtils.withAxiosInstance<
  CreatePlatformFundsRequest,
  FundsRequest
>(
  async (axiosInstance, fundsRequest) =>
    (await axiosInstance.post(`${url}/withdrawal/platform`, fundsRequest)).data,
);
const createDepositForUser = apiUtils.withAxiosInstance<
  {
    userId: string;
    fundsRequest: CreateFundsRequest;
  },
  FundsRequest
>(
  async (axiosInstance, { userId, fundsRequest }) =>
    (await axiosInstance.post(`${url}/deposit/${userId}`, fundsRequest)).data,
);

const createWithdrawalForUser = apiUtils.withAxiosInstance<
  {
    userId: string;
    fundsRequest: CreateFundsRequest;
  },
  FundsRequest
>(
  async (axiosInstance, { userId, fundsRequest }) =>
    (await axiosInstance.post(`${url}/withdrawal/${userId}`, fundsRequest))
      .data,
);

const createDeposit = async (
  fundsRequest: CreateFundsRequest,
): Promise<FundsRequest> =>
  (await axios.post(`${url}/deposit`, fundsRequest)).data;

const revoke = async (fundsRequest: FundsRequest): Promise<FundsRequest> =>
  (await axios.patch(`${url}/${fundsRequest.id}/revoke`)).data;

const processCancel = async (
  fundsRequest: FundsRequest,
): Promise<FundsRequest> =>
  (await axios.patch(`${url}/${fundsRequest.id}/process/cancel`)).data;

const processComplete = apiUtils.withAxiosInstance<
  FundsRequest & {
    sendTransaction?: boolean;
  },
  FundsRequest
>(async (axiosInstance, fundsRequest) => {
  const dto =
    fundsRequest.operationType === OperationType.Withdrawal
      ? {
          hash: fundsRequest.hash,
          sendTransaction: fundsRequest.sendTransaction,
        }
      : null;
  return (
    await axiosInstance.patch(`${url}/${fundsRequest.id}/process/complete`, dto)
  ).data;
});

const getPaginatedByUrl =
  (url: string) =>
  async (params: QueryParams): Promise<PaginatedData<FundsRequest>> =>
    (await apiUtils.getWithQueryParams(url, params)).data;

const getAllMyPaginated = getPaginatedByUrl(`${url}/my`);

const getMyActivePaginated = getPaginatedByUrl(`${url}/my/active`);

const getMyArchivePaginated = getPaginatedByUrl(`${url}/my/archive`);

const getAllPaginated = getPaginatedByUrl(url);

const getAllActivePaginated = getPaginatedByUrl(`${url}/active`);

const getAllArchivePaginated = getPaginatedByUrl(`${url}/archive`);

const remove = async (fundsRequest: FundsRequest): Promise<FundsRequest> =>
  (await axios.delete(`${url}/${fundsRequest.id}`)).data;

export const fundsRequestsApi = {
  getAllPaginated,
  getAllMyPaginated,
  getMyActivePaginated,
  getMyArchivePaginated,
  getAllActivePaginated,
  getAllArchivePaginated,
  createWithdrawal,
  createPlatformWithdrawal,
  createDeposit,
  createDepositForUser,
  createWithdrawalForUser,
  revoke,
  remove,
  processCancel,
  processComplete,
};
