import i18next from 'i18next';
import { map } from 'lodash';

import { FormikSelectOption } from 'components';
import { P2PProviderType } from 'enums';

const getTypeLabel = (type: P2PProviderType) =>
  i18next.t(`p2p_providers.${type}`, { defaultValue: type });

const typeOptions: FormikSelectOption[] = map(P2PProviderType, (type) => ({
  value: type,
  label: getTypeLabel(type),
}));

export const p2pProviderUtils = {
  getTypeLabel,
  typeOptions,
};
