import { useTranslation } from 'react-i18next';

import { OrderType } from 'enums';
import { RequisiteLimits } from 'types';

type Props = {
  limits: RequisiteLimits;
  orderType: OrderType;
};

export const LimitsInfo: React.FC<Props> = ({ limits, orderType }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="tw-flex tw-items-center">
        {orderType === OrderType.Payin
          ? `${limits?.payinLimitCountPerDay} / ${limits?.payinLimitCountPerMonth}`
          : `${limits?.payoutLimitCountPerDay} / ${limits?.payoutLimitCountPerMonth}`}
      </div>
      <div className="tw-flex tw-items-center">
        {orderType === OrderType.Payin
          ? `${limits?.payinLimitSumPerDay} / ${limits?.payinLimitSumPerMonth}`
          : `${limits?.payoutLimitSumPerDay} / ${limits?.payoutLimitSumPerMonth}`}
      </div>
      {orderType === OrderType.Payout && limits?.payoutThrottleDuration && (
        <div>{`${limits?.payoutThrottleDuration} ${t('common.minutes')}`}</div>
      )}
    </div>
  );
};
