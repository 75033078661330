import { Stack } from '@mui/material';
import React from 'react';

import { assetsApi, shopsApi } from 'api';
import { AssetsTable, TotalBalance } from 'components';
import { AssetsTabs, QueryKey } from 'enums';
import {
  useCalculateAssetBalances,
  usePartialQuery,
  useShopsQuery,
  useUser,
} from 'hooks';

export const MerchantsBalance: React.FC = () => {
  const { role } = useUser();
  const queryResult = usePartialQuery(
    QueryKey.MerchantsAssets,
    assetsApi.getAllMerchantsAsRole(role),
    { paginated: false },
  );

  const shopsQueryResult = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  const { balances } = useCalculateAssetBalances(queryResult.data);

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />
      <AssetsTable
        tab={AssetsTabs.MerchantsBalance}
        shops={shopsQueryResult.data}
        queryResult={queryResult}
      />
    </Stack>
  );
};
