import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikRadioGroup, FormikRadioGroupOption } from 'components';
import { TranslationNamespace } from 'i18n';

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  yesValue?: unknown;
  noValue?: unknown;
  onChange?: (value: any) => void;
};

export const FormikYesNoRadioGroup: React.FC<Props> = ({
  name,
  label,
  disabled,
  yesValue = true,
  noValue = false,
  onChange,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'common',
  });
  const options: FormikRadioGroupOption[] = useMemo(
    () => [
      {
        label: t('yes'),
        value: yesValue,
        disabled,
      },
      {
        label: t('no'),
        value: noValue,
        disabled,
      },
    ],
    [t, yesValue, noValue, disabled],
  );
  return (
    <FormikRadioGroup
      name={name}
      disabled={disabled}
      label={label}
      options={options}
      onChange={onChange}
    />
  );
};
