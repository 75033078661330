import { env } from 'config';
import { AppConfigArea } from 'enums';
import { AppConfig, AppConfigSchema, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

import { createCrudApi } from './crud.api';

const path = '/app-configs';
const url = `${env.apiUrl}${path}`;

const getForAreaPaginated =
  (area: AppConfigArea) =>
  async (params?: QueryParams): Promise<PaginatedData<AppConfig>> =>
    (await apiUtils.getWithQueryParams(`${url}/area/${area}/paginated`, params))
      .data;

const getAreaSchemas =
  (area: AppConfigArea) =>
  async (params?: Partial<QueryParams>): Promise<AppConfigSchema[]> =>
    (await apiUtils.getWithQueryParams(`${url}/schemas/${area}`, params)).data;

const getConfigSchema =
  (id: string) =>
  async (params?: Partial<QueryParams>): Promise<AppConfigSchema> =>
    (await apiUtils.getWithQueryParams(`${url}/${id}/schema`, params)).data;

export const appConfigsApi = {
  getForAreaPaginated,
  getAreaSchemas,
  getConfigSchema,
  ...createCrudApi<AppConfig>(path),
};
