import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  DisputeStatusDetails,
  FilterDefinitionType,
  OrderDisputeType,
  OrdersTab,
  OrderStatusReason,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

type Props = {
  tab?: OrdersTab;
  additionalFilters?: FilterDefinition<OrderFilters>[];
  additionalColumns?: AdditionalOrderColumn[];
};

export const DisputeOrders: React.FC<Props> = ({
  tab = OrdersTab.Dispute,
  additionalFilters: propsFilters = [],
  additionalColumns: propsColumns = [],
}) => {
  const { t: tFilters } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      ...propsFilters,
      {
        label: tFilters('dispute'),
        name: 'statusDetails',
        type: FilterDefinitionType.Enum,
        enum: Object.values(DisputeStatusDetails),
        getDisplayName: (value: DisputeStatusDetails) =>
          orderUtils.getStatusDetailsLabel(value),
      },
      {
        label: tFilters('dispute_reason'),
        name: 'statusReason',
        type: FilterDefinitionType.Enum,
        enum: Object.values(OrderStatusReason),
        getDisplayName: (value: OrderStatusReason) =>
          orderUtils.getStatusReasonLabel(value),
        hidden: tab !== OrdersTab.TraderDispute,
      },
      {
        label: tFilters('dispute_type'),
        name: 'disputeType',
        type: FilterDefinitionType.Enum,
        enum: Object.values(OrderDisputeType),
        getDisplayName: (value: OrderDisputeType) =>
          orderUtils.getDisputeTypeLabel(value),
        hidden: tab !== OrdersTab.TraderDispute,
      },
      {
        label: tFilters('callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
    ],
    [propsFilters, tFilters, tab],
  );

  return (
    <ManageOrdersList
      tab={tab}
      additionalColumns={[
        ...propsColumns,
        AdditionalOrderColumn.CustomerPayment,
        AdditionalOrderColumn.TraderCard,
        ...(tab === OrdersTab.TraderDispute
          ? []
          : [AdditionalOrderColumn.ResolveDispute]),
      ]}
      additionalFilters={additionalFilters}
    />
  );
};
