import { useFormikContext } from 'formik';
import { filter, find, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControls, FormikSelect } from 'components';
import { AppConfigArea } from 'enums';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigSchema } from 'types';
import { appConfigUtils } from 'utils';

import { ConfigValueField } from '../../ConfigValueField';

type Props = {
  data?: Partial<AppConfig>;
  schemas: AppConfigSchema[];
};

export const ConfigForm: React.FC<Props> = ({ data, schemas }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const formik = useFormikContext<any>();

  const isPayinP2PProvider = useMemo(
    () => data?.area === AppConfigArea.PayinP2PProvider,
    [data],
  );
  const isPayoutP2PProvider = useMemo(
    () => data?.area === AppConfigArea.PayoutP2PProvider,
    [data],
  );
  const isP2PProvider = useMemo(
    () => isPayinP2PProvider || isPayoutP2PProvider,
    [isPayinP2PProvider, isPayoutP2PProvider],
  );

  const entityName: keyof AppConfigSchema = useMemo(
    () =>
      isP2PProvider ? 'p2pProviderType' : 'payoutRequisitesAutomationType',
    [isP2PProvider],
  );

  const entityOptions = useMemo(
    () =>
      uniqBy(schemas, entityName).map((schema) => ({
        label: appConfigUtils.getEntityTypeLabel(data?.area)(
          schema[entityName],
        ),
        value: schema[entityName]!,
      })),
    [schemas, entityName, data],
  );

  const keyOptions = useMemo(
    () =>
      filter(
        schemas,
        (schema) => schema[entityName] === formik.values[entityName],
      ).map((schema) => ({
        label: appConfigUtils.getKeyLabel(schema.key),
        value: schema.key,
      })),
    [formik.values, schemas, entityName],
  );

  const keySchema = useMemo(
    () =>
      find(
        schemas,
        (schema) =>
          schema[entityName] === formik.values[entityName] &&
          schema.key === formik.values.key,
      ),
    [formik.values, schemas, entityName],
  );

  const handleEntityChange = useCallback(() => {
    formik.setFieldValue('key', '');
    formik.setFieldTouched('key', false, true);
  }, [formik]);

  return (
    <FormControls>
      <FormikSelect
        label={
          isP2PProvider
            ? t('fields.provider')
            : t('fields.payout_requisites_automation_type')
        }
        name={entityName}
        options={entityOptions}
        disabled={!!data?.id}
        required
        noneOption
        onChange={handleEntityChange}
      />
      <FormikSelect
        label={t('fields.key')}
        name="key"
        options={keyOptions}
        disabled={!!data?.id || !formik.values[entityName]}
        required
        noneOption
      />
      {keySchema && data && <ConfigValueField schema={keySchema} data={data} />}
    </FormControls>
  );
};
