import { filter, map, reduce } from 'lodash';
import { useMemo } from 'react';

import { Math } from 'classes';
import { useCurrencies } from 'hooks';
import { Asset } from 'types';

export const useCalculateAssetBalances = (assets: Asset[] = []) => {
  const { assetCurrencies } = useCurrencies();
  const balances = useMemo(
    () =>
      map(assetCurrencies, ({ id }) => ({
        id,
        amount: reduce(
          filter(assets, { assetCurrencyId: id }),
          (acc, asset) => acc.plus(asset.balance, asset.holdBalance),
          new Math(0),
        ).value,
      })),
    [assetCurrencies, assets],
  );

  return { balances };
};
