import { Tab } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { PayoutOrdersContext, PayoutOrdersContextProps } from 'context';
import { OrdersTab, QueryKey } from 'enums';
import { useShopsQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { AllOrders } from './AllOrders';
import { CreateOrderDialog } from './CreateOrderDialog';

import {
  ActiveOrders,
  CompletedOrders,
  CancelledOrders,
  HoldCompletedOrders,
  TraderDisputeOrders,
  ProviderDisputeOrders,
} from '.';

export const ManagePayoutOrders: React.FC = () => {
  const { role, isAdmin, isTechOperator } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const [createOrderDialogOpen, setCreateOrderDialogOpen] = useState(false);

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  const handleCreate = useCallback(() => setCreateOrderDialogOpen(true), []);

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
  );
  const queryResultMerchants = useQuery(
    QueryKey.UsersMerchants,
    usersApi.getAllMerchants,
  );

  const contextValue: PayoutOrdersContextProps = useMemo(
    () => ({
      shops: queryResultShops.data,
      traders: queryResultTraders.data,
      merchants: queryResultMerchants.data,
    }),
    [queryResultMerchants.data, queryResultShops.data, queryResultTraders.data],
  );

  return (
    <PayoutOrdersContext.Provider value={contextValue}>
      <PageHeader
        title={t('title')}
        divider={false}
        {...(canManage && {
          rightContentButton: { onClick: handleCreate },
        })}
      />
      <QueryTabs tabsEnum={OrdersTab}>
        <Tab value={OrdersTab.Active} label={t('tabs.active')} />
        <Tab value={OrdersTab.Completed} label={t('tabs.completed')} />
        <Tab value={OrdersTab.HoldCompleted} label={t('tabs.hold_completed')} />
        <Tab value={OrdersTab.Cancelled} label={t('tabs.cancelled')} />
        <Tab value={OrdersTab.TraderDispute} label={t('tabs.trader_dispute')} />
        <Tab
          value={OrdersTab.ProviderDispute}
          label={t('tabs.provider_dispute')}
        />
        <Tab value={OrdersTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={OrdersTab.Active}>
        <ActiveOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Completed}>
        <CompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.HoldCompleted}>
        <HoldCompletedOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.Cancelled}>
        <CancelledOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.TraderDispute}>
        <TraderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.ProviderDispute}>
        <ProviderDisputeOrders />
      </QueryTabPanel>
      <QueryTabPanel value={OrdersTab.All}>
        <AllOrders />
      </QueryTabPanel>
      {canManage && (
        <CreateOrderDialog
          open={createOrderDialogOpen}
          onClose={() => setCreateOrderDialogOpen(false)}
        />
      )}
    </PayoutOrdersContext.Provider>
  );
};
