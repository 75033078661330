import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { appConfigsApi } from 'api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  dataGridColumns,
  ExpandedJson,
} from 'components';
import { AppConfigArea, QueryKey } from 'enums';
import { useMutation, usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigFilters, FilterDefinition } from 'types';
import { appConfigUtils } from 'utils';

import { ConfigDetailsDialog } from '../ConfigDetailsDialog';

type Props = {
  area: AppConfigArea;
  additionalColumns: DataGridColumnDefinition<AppConfig>[];
  additionalFilters: FilterDefinition<AppConfigFilters>[];
};

export const ConfigsPageTab: React.FC<Props> = ({
  area,
  additionalColumns,
  additionalFilters,
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const queryResult = usePartialQuery(
    QueryKey.Configs,
    appConfigsApi.getForAreaPaginated(area),
  );

  const { mutate: remove } = useMutation(appConfigsApi.remove, {
    onSuccess: () => {
      queryResult.refetch();
    },
    notifierType: 'remove',
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<AppConfig>[] => [
      dataGridColumns.getIdColumn(),
      ...additionalColumns,
      {
        header: t('fields.key'),
        valueKey: 'key',
        valueFormatter: appConfigUtils.getKeyLabel,
      },
      {
        header: t('fields.value'),
        valueGetter: (item) => {
          if (item.jsonObjectValue) {
            return <ExpandedJson text={JSON.stringify(item.jsonObjectValue)} />;
          } else if (item.jsonArrayValue) {
            return <ExpandedJson text={JSON.stringify(item.jsonArrayValue)} />;
          }
        },
      },
    ],
    [t, additionalColumns],
  );

  const filtersDefinitions: FilterDefinition<AppConfigFilters>[] = useMemo(
    () => [...additionalFilters],
    [additionalFilters],
  );

  return (
    <CrudPage
      table={{
        queryResult,
        columns,
        paginated: true,
        actions: [
          {
            type: CrudTableActionType.Details,
            renderDialog: ConfigDetailsDialog,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ],
      }}
      filters={{ filtersDefinitions }}
    />
  );
};
