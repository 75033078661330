import { Form, Formik } from 'formik';
import { map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  FormControls,
  FormikNumericField,
  FormikSelect,
} from 'components';
import { TRADER_SELECT_DISPUTE_DETAILS } from 'constants/order.constants';
import { DisputeStatusDetails, OrderStatusReason } from 'enums';
import { useCurrencies, usePrevious, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';
import { orderUtils } from 'utils';

import { DisputeSelect } from './DisputeSelect';

type Values = {
  statusDetails: DisputeStatusDetails;
  statusReason?: OrderStatusReason;
  amount?: number;
};

type Props = {
  open: boolean;
  title: string;
  order: PayinOrder;
  onClose: CloseDialogHandler<Values>;
};

export const DisputeDialog: React.FC<Props> = ({
  open,
  title,
  order,
  onClose,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const prevOpen = usePrevious(open);
  const { getFiatCurrencySymbol } = useCurrencies();
  const { isManager } = useUser();

  const statusReasons = useMemo(
    () => [OrderStatusReason.WaitingTrader, OrderStatusReason.WaitingMerchant],
    [],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        statusDetails: Yup.mixed<DisputeStatusDetails>()
          .oneOf(TRADER_SELECT_DISPUTE_DETAILS)
          .required(tCommon('errors.required')),
        amount: Yup.number().when('statusDetails', {
          is: (statusDetails: DisputeStatusDetails) =>
            statusDetails === DisputeStatusDetails.DisputeDifferentAmount,
          then: (schema) =>
            schema
              .moreThan(0, tCommon('errors.natural_number'))
              .notOneOf(
                [order.amount],
                tCommon('errors.different_value', { value: order.amount }),
              )
              .required(tCommon('errors.required')),
        }),
        statusReason: Yup.mixed<OrderStatusReason>().oneOf(statusReasons),
      }),
    [order, statusReasons, tCommon],
  );

  const defaultDetails = useMemo(
    () =>
      (TRADER_SELECT_DISPUTE_DETAILS.includes(
        order?.statusDetails as DisputeStatusDetails,
      )
        ? order.statusDetails
        : TRADER_SELECT_DISPUTE_DETAILS[0]) as DisputeStatusDetails,
    [order],
  );
  const currencySymbol = useMemo(
    () => getFiatCurrencySymbol(order?.fiatCurrencyId),
    [order, getFiatCurrencySymbol],
  );

  const [initialValues, setInitialValues] = useState<Values>({
    statusDetails: defaultDetails,
    amount: order?.amount || 0,
  });

  const statusReasonOptions = useMemo(
    () =>
      map(statusReasons, (status) => ({
        label: orderUtils.getStatusReasonLabel(status),
        value: status,
      })),
    [statusReasons],
  );

  const handleClose = useCallback(
    (data: CloseDialogResult<Values>) => {
      onClose(data);
      if (data.data) {
        setInitialValues(data.data);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      handleClose({ ok: true, data: values });
    },
    [handleClose],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      setInitialValues({
        statusDetails: defaultDetails,
        amount: order?.amount || 0,
      });
    }
  }, [order, defaultDetails, open, prevOpen]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          okDisabled={!formik.isValid}
          data={formik.values}
          onClose={handleClose}
        >
          <Form>
            <FormControls>
              <DisputeSelect />
              {isManager && (
                <FormikSelect
                  name="statusReason"
                  label={tCommon('features.orders.dispute.reason')}
                  options={statusReasonOptions}
                  noneOption
                />
              )}
              {formik.values.statusDetails ===
                DisputeStatusDetails.DisputeDifferentAmount && (
                <FormikNumericField
                  name="amount"
                  label={`${tCommon(
                    'features.orders.dispute.received_amount',
                  )} (${currencySymbol})`}
                  allowNegative={false}
                  required
                  fullWidth
                />
              )}
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
