import { Tab } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageHeader,
  QueryTabs,
  QueryTabPanel,
  TradersBalance,
  MerchantsBalance,
} from 'components';
import { AssetsTabs } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { P2PProvidersBalance } from './P2PProvidersBalance';
import { PlatformBalance } from './PlatformBalance';

export const AssetsPage: React.FC = () => {
  const { isAdmin, isTechOperator } = useUser();
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.assets',
  });
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );

  const tabs = useMemo(
    () => [
      ...(isAdmin ? [AssetsTabs.PlatformBalance] : []),
      AssetsTabs.TradersBalance,
      ...(canManage
        ? [AssetsTabs.MerchantsBalance, AssetsTabs.P2PProvidersBalance]
        : []),
    ],
    [isAdmin, canManage],
  );

  return (
    <div>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabs={tabs}>
        {isAdmin && (
          <Tab
            value={AssetsTabs.PlatformBalance}
            label={t('tabs.platform_balance')}
          />
        )}
        <Tab
          value={AssetsTabs.TradersBalance}
          label={t('tabs.traders_balance')}
        />
        {canManage && (
          <Tab
            value={AssetsTabs.MerchantsBalance}
            label={t('tabs.merchants_balance')}
          />
        )}
        {canManage && (
          <Tab
            value={AssetsTabs.P2PProvidersBalance}
            label={t('tabs.p2p_providers_balance')}
          />
        )}
      </QueryTabs>
      {isAdmin && (
        <QueryTabPanel value={AssetsTabs.PlatformBalance}>
          <PlatformBalance />
        </QueryTabPanel>
      )}
      <QueryTabPanel value={AssetsTabs.TradersBalance}>
        <TradersBalance />
      </QueryTabPanel>
      {canManage && (
        <QueryTabPanel value={AssetsTabs.MerchantsBalance}>
          <MerchantsBalance />
        </QueryTabPanel>
      )}
      {canManage && (
        <QueryTabPanel value={AssetsTabs.P2PProvidersBalance}>
          <P2PProvidersBalance />
        </QueryTabPanel>
      )}
    </div>
  );
};
