import { Sync as SyncIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateLabel } from 'components';
import { useMoney } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutRequisitesAutomation } from 'types';
import { formatUtils } from 'utils';

import { RequisitesAutomationWarnings } from '../RequisitesAutomationWarnings';

type Props = {
  automation: PayoutRequisitesAutomation;
  disabled?: boolean;
  showWarnings?: boolean;
  getAccountInfo: () => void;
};

export const PayoutAutomationAccountInfoDetails: React.FC<Props> = ({
  automation,
  disabled,
  showWarnings,
  getAccountInfo,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation.account_info',
  });
  const { getFormattedMoney } = useMoney();

  const isEqualBalances = useMemo(
    () =>
      automation.accountInfo?.balance === automation.expectedBalance &&
      automation.accountInfoAt === automation.expectedBalanceAt,
    [automation],
  );

  return (
    <div>
      <div className="tw-flex tw-items-center">
        {automation?.accountInfo && (
          <Fragment>
            {automation.accountInfo.balance &&
              (isEqualBalances || !automation.expectedBalance) && (
                <div className="tw-mr-1">
                  <div>
                    {`${t('balance')}: ${getFormattedMoney({
                      value: automation.accountInfo.balance,
                      fiatCurrencyId: automation.balanceFiatCurrencyId,
                      symbol: true,
                    })}`}
                  </div>
                </div>
              )}
          </Fragment>
        )}
        {automation.expectedBalance && !isEqualBalances && (
          <div className="tw-mr-1">
            <div>
              {`${t('balance')}: ~${getFormattedMoney({
                value: automation.expectedBalance,
                fiatCurrencyId: automation.balanceFiatCurrencyId,
                symbol: true,
              })}`}
            </div>
          </div>
        )}
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={getAccountInfo}
        >
          <SyncIcon />
        </IconButton>
        {showWarnings && (
          <RequisitesAutomationWarnings automation={automation} />
        )}
      </div>
      {automation?.accountInfoAt && isEqualBalances && (
        <DateLabel>
          {formatUtils.formatDate(automation.accountInfoAt)}
        </DateLabel>
      )}
      {automation.expectedBalanceAt && !isEqualBalances && (
        <DateLabel>
          {formatUtils.formatDate(automation.expectedBalanceAt)}
        </DateLabel>
      )}
    </div>
  );
};
