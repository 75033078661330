import { Route, Navigate } from 'react-router-dom';

import { TraderRoute, PayRoutes } from 'components';
import { ROUTE_PATH as ROUTE_PATH_ALL } from 'constants/routes';
import { ArchivePage } from 'features/archive';
import { CommonLimitsPage } from 'features/common-limits';
import { CurrencyExchangeRequestsPage } from 'features/currency-exchange-requests';
import { ManageOrderAutomationPage } from 'features/order-automation';
import { ManagePayoutOrderAutomationPage } from 'features/payout-order-automation';
import {
  PayoutRequisitesAutomationDetailsPage,
  PayoutRequisitesPage,
} from 'features/payout-requisites';
import { RequisitesGroupDetails, RequisitesPage } from 'features/requisites';
import {
  TraderMainPage,
  TraderFundsRequestsPage,
  TraderInternalTransfersPage,
  TraderBalanceHistoryPage,
  TraderSettingsPage,
  TraderPayinRequisitesDetailsPage,
  TraderPayoutRequisitesDetailsPage,
  TraderOrdersPage,
  TraderPayinOrdersPage,
  TraderPayoutOrdersPage,
  TraderReferralSystemPage,
  TraderRequisitesTelegramAutomationDetailsPage,
  TraderPayinStatisticsPage,
  TraderPayoutStatisticsPage,
} from 'pages';

const ROUTE_PATH = ROUTE_PATH_ALL.TRADER;

export const TraderRoutes = () => (
  <Route path={ROUTE_PATH.MAIN} element={<TraderRoute />}>
    <Route path="" element={<TraderMainPage />} />
    <Route
      path={ROUTE_PATH.FUNDS_REQUESTS}
      element={<TraderFundsRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.INTERNAL_TRANSFERS}
      element={<TraderInternalTransfersPage />}
    />
    <Route
      path={ROUTE_PATH.BALANCE_HISTORY}
      element={<TraderBalanceHistoryPage />}
    />
    <Route path={ROUTE_PATH.SETTINGS} element={<TraderSettingsPage />} />

    <Route
      path={`${ROUTE_PATH.REQUISITES}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.REQUISITES}
          payinElement={<RequisitesPage />}
          payoutElement={<PayoutRequisitesPage />}
          commonElement={<CommonLimitsPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_DETAILS}
      element={<TraderPayinRequisitesDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_GROUP_DETAILS}
      element={<RequisitesGroupDetails />}
    />
    <Route
      path={ROUTE_PATH.PAYOUT_REQUISITES_DETAILS}
      element={<TraderPayoutRequisitesDetailsPage />}
    />

    <Route
      path={ROUTE_PATH.ORDERS}
      element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />}
    />
    <Route path={ROUTE_PATH.ORDERS} element={<TraderOrdersPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_ORDERS}
        element={<TraderPayinOrdersPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_ORDERS}
        element={<TraderPayoutOrdersPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />} />
    </Route>

    <Route
      path={ROUTE_PATH.REFERRAL_SYSTEM}
      element={<TraderReferralSystemPage />}
    />
    <Route
      path={`${ROUTE_PATH.ORDER_AUTOMATION}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.ORDER_AUTOMATION}
          payinElement={<ManageOrderAutomationPage />}
          payoutElement={<ManagePayoutOrderAutomationPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.REQUISITES_TELEGRAM_AUTOMATION_DETAILS}
      element={<TraderRequisitesTelegramAutomationDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.PAYOUT_REQUISITES_AUTOMATION_DETAILS}
      element={<PayoutRequisitesAutomationDetailsPage />}
    />

    <Route
      path={`${ROUTE_PATH.STATISTICS}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.STATISTICS}
          payinElement={<TraderPayinStatisticsPage />}
          payoutElement={<TraderPayoutStatisticsPage />}
        />
      }
    />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE}
      element={<CurrencyExchangeRequestsPage />}
    />
    <Route path={ROUTE_PATH.ARCHIVE} element={<ArchivePage />} />
  </Route>
);
