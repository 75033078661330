import { AxiosError } from 'axios';
import { findIndex } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { p2pProvidersApi } from 'api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DateLabel,
  SidebarLayout,
  dataGridColumns,
  SidebarSize,
  P2PProviderNameInfo,
  Money,
  StylizedNumber,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, P2PProvider, P2PProvidersFilters } from 'types';
import { formatUtils, p2pProviderUtils, filtersUtils } from 'utils';

import { P2PProviderDetails } from './P2PProviderDetails';

type Props = {
  archived?: boolean;
};

export const P2PProvidersPage: React.FC<Props> = ({ archived }) => {
  const { isAdmin, isTechOperator } = useUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });

  const { t: tCommon } = useTranslation();

  const [selectedP2PProvider, setSelectedP2PProvider] =
    useState<P2PProvider | null>(null);

  const queryResult = usePartialQuery(
    archived ? QueryKey.P2PProvidersArchive : QueryKey.P2PProviders,
    archived
      ? p2pProvidersApi.getAllArchivePaginated
      : p2pProvidersApi.getAllPaginated,
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<P2PProvider>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.name'),
        valueGetter: (item) => <P2PProviderNameInfo provider={item} />,
      },
      {
        header: t('fields.type'),
        valueGetter: (item) => p2pProviderUtils.getTypeLabel(item.type),
      },
      {
        header: t('fields.enabled'),
        valueGetter: (item) => {
          const payinEnabled = formatUtils.formatBoolean(
            item.payinConfig?.enabled,
          );
          const payoutEnabled = formatUtils.formatBoolean(
            item.payoutConfig?.enabled,
          );
          return (
            <div className="tw-inline-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-2">
              <div>{tCommon('common.payin')}</div>
              <div>{payinEnabled}</div>
              <div>{tCommon('common.payout')}</div>
              <div>{payoutEnabled}</div>
            </div>
          );
        },
      },
      {
        header: t('fields.last_taken_order_at'),
        valueGetter: (item) => {
          const payinDate = item.payinConfig?.lastTakenOrderAt
            ? formatUtils.formatDate(item.payinConfig.lastTakenOrderAt)
            : '-';
          const payoutDate = item.payoutConfig?.lastTakenOrderAt
            ? formatUtils.formatDate(item.payoutConfig.lastTakenOrderAt)
            : '-';

          return (
            <div className="tw-inline-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-2">
              {tCommon('common.payin')}
              <DateLabel>{payinDate}</DateLabel>
              {tCommon('common.payout')}
              <DateLabel>{payoutDate}</DateLabel>
            </div>
          );
        },
        valueClassName: 'tw-min-w-[160px]',
      },
      {
        header: t('details.payout_limits.title'),
        valueGetter: (item) => {
          const limits = item.payoutLimits;
          return limits ? (
            <div className="tw-inline-grid tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-2">
              <div className="tw-mr-2">{t('details.payout_limits.sum')}</div>
              <Money
                symbol
                value={limits?.limitSum || 0}
                fiatCurrencyId={item.fiatCurrencyId}
              />
              <div className="tw-mr-2">{t('details.payout_limits.count')}</div>
              <StylizedNumber value={limits?.limitCount || 0} />
            </div>
          ) : null;
        },
      },
    ],
    [t, tCommon],
  );

  const { mutate: archive } = useMutation<P2PProvider, AxiosError, string>(
    p2pProvidersApi.archive,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.P2PProviders);
      },
      notifierType: 'remove',
    },
  );

  const handleSelectP2PProvider = useCallback((p2pProvider: P2PProvider) => {
    setSelectedP2PProvider(p2pProvider);
  }, []);

  const handleDeselectP2PProvider = useCallback(() => {
    setSelectedP2PProvider(null);
  }, []);

  const selectedRowIndex = useMemo(
    () =>
      findIndex(queryResult?.data?.items, {
        id: selectedP2PProvider?.id,
      }),
    [queryResult, selectedP2PProvider],
  );

  const detailsPath = useMemo(() => {
    if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.P2P_PROVIDERS_DETAILS;
    } else if (isAdmin) {
      return ROUTE_PATH.ADMIN.P2P_PROVIDERS_DETAILS;
    }
  }, [isTechOperator, isAdmin]);

  const handleCreate = useCallback(() => {
    navigate(generatePath(detailsPath!, { id: NEW_ID }));
  }, [detailsPath, navigate]);

  const filtersDefinitions: FilterDefinition<P2PProvidersFilters>[] = useMemo(
    () => [
      {
        label: tCommon('filters.id'),
        name: 'id',
        type: FilterDefinitionType.Text,
        format: 'uuid',
      },
      {
        label: tCommon('filters.name'),
        name: 'name',
        type: FilterDefinitionType.Text,
      },
      {
        label: tCommon('filters.type'),
        name: 'type',
        type: FilterDefinitionType.Select,
        options: p2pProviderUtils.typeOptions,
        getDisplayName: p2pProviderUtils.getTypeLabel,
      },
      filtersUtils.getEnabledStatusFilter<P2PProvidersFilters>(
        tCommon('filters.payin_status'),
        'payinEnabled',
      ),
      filtersUtils.getEnabledStatusFilter<P2PProvidersFilters>(
        tCommon('filters.payout_status'),
        'payoutEnabled',
      ),
    ],
    [tCommon],
  );

  return (
    <SidebarLayout
      sidebarProps={{
        open: !!selectedP2PProvider,
        title: t('details.title'),
        size: SidebarSize.md,
        onClose: handleDeselectP2PProvider,
        content: selectedP2PProvider && (
          <P2PProviderDetails p2pProvider={selectedP2PProvider} />
        ),
      }}
    >
      <CrudPage
        header={
          archived
            ? undefined
            : {
                title: t('title'),
                ...((isAdmin || isTechOperator) && {
                  rightContentButton: { onClick: handleCreate },
                }),
              }
        }
        filters={{ filtersDefinitions }}
        table={{
          queryResult,
          columns,
          paginated: true,
          actions: [
            {
              type: CrudTableActionType.Details,
              path: detailsPath,
            },
            {
              type: CrudTableActionType.Remove,
              onRemove: (item, { close }) =>
                archive(item.id, { onSuccess: close }),
            },
          ],
          onRowClick: handleSelectP2PProvider,
          selectedRowIndex,
          hideActions: archived,
        }}
      />
    </SidebarLayout>
  );
};
