import { filter, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';

import { FormikSelect, FormikSelectProps } from 'components';
import { useUserContext, useUserContextUtils } from 'hooks';

type Props = {
  name: string;
  label: string;
  fiatCurrencyId?: string;
  crossFiatCurrencyId?: string;
  exclude?: string[];
} & Partial<FormikSelectProps>;

export const TradeMethodSelect: React.FC<Props> = ({
  name,
  label,
  fiatCurrencyId,
  crossFiatCurrencyId,
  exclude,
  ...rest
}) => {
  const { tradeMethods } = useUserContext();
  const { getTradeMethodLabel } = useUserContextUtils();

  const fiatCurrenciesIds = useMemo(
    () => filter([fiatCurrencyId, crossFiatCurrencyId]),
    [crossFiatCurrencyId, fiatCurrencyId],
  );

  const tradeMethodsOptions = useMemo(
    () =>
      map(
        filter(
          tradeMethods,
          (tradeMethod) =>
            isEmpty(fiatCurrenciesIds) ||
            (fiatCurrenciesIds.includes(tradeMethod.fiatCurrencyId) &&
              !exclude?.includes(tradeMethod.id)),
        ),
        ({ id }) => ({
          value: id,
          label: getTradeMethodLabel(id),
        }),
      ),
    [tradeMethods, fiatCurrenciesIds, exclude, getTradeMethodLabel],
  );

  return (
    <FormikSelect
      noneOption
      label={label}
      {...rest}
      name={name}
      options={tradeMethodsOptions}
    />
  );
};
