import { find } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdditionalOrderColumn, FilterDefinitionType, OrdersTab } from 'enums';
import { Orders } from 'features/orders';
import { useCurrencies, useOrdersContext, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { formUtils, orderUtils, requisitesUtils } from 'utils';

type Props = {
  tab: OrdersTab;
  additionalFilters?: FilterDefinition<OrderFilters>[];
  additionalColumns?: AdditionalOrderColumn[];
};

export const ManageOrdersList: React.FC<Props> = ({
  tab,
  additionalFilters = [],
  additionalColumns = [],
}) => {
  const { t: tFilters } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const { paymentTypes, banks, p2pProviders } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();
  const { shops, merchants, traders } = useOrdersContext();

  const filters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: tFilters('customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('trader'),
        name: 'traderId',
        type: FilterDefinitionType.User,
        users: traders,
        getDisplayName: (traderId: string) =>
          find(traders, { id: traderId })?.name,
        hidden: additionalColumns.includes(AdditionalOrderColumn.P2PProvider),
      },
      {
        label: tFilters('merchant'),
        name: 'merchantId',
        type: FilterDefinitionType.User,
        users: merchants,
        getDisplayName: (merchantId: string) =>
          find(merchants, { id: merchantId })?.name,
      },
      {
        label: tFilters('shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops,
        getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
      },
      {
        label: tFilters('external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tFilters('bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tFilters('fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      {
        label: tFilters('requisites_verification_status'),
        name: 'requisitesVerificationStatus',
        type: FilterDefinitionType.Select,
        options: orderUtils.getOrderRequisitesVerificationStatusOptions(),
        getDisplayName: orderUtils.getOrderRequisitesVerificationStatusLabel,
      },
      {
        label: tFilters('p2p_provider'),
        name: 'p2pProviderId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(p2pProviders),
        getDisplayName: (value: string) =>
          find(p2pProviders, { id: value })?.name,
        hidden: additionalColumns.includes(AdditionalOrderColumn.Trader),
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [
      tFilters,
      traders,
      additionalColumns,
      merchants,
      shops,
      paymentTypes,
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      p2pProviders,
    ],
  );

  return (
    <Orders
      tab={tab}
      additionalFilters={[...filters, ...additionalFilters]}
      additionalColumns={[
        ...(tab === OrdersTab.RequisitesVerification
          ? [AdditionalOrderColumn.RequisitesVerificationResult]
          : [
              AdditionalOrderColumn.CustomerContacts,
              AdditionalOrderColumn.Merchant,
              AdditionalOrderColumn.CustomerPayment,
            ]),
        ...additionalColumns,
      ]}
    />
  );
};
