import { Chip, Tooltip } from '@mui/material';
import { isNumber } from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'hooks';
import { PayinOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayinOrder;
};

export const OrderStatusDetailsInfo: React.FC<Props> = ({ order }) => {
  const { isManager, isMerchant } = useUser();
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="tw-flex tw-items-center">
        {order.statusDetails && (
          <div>{orderUtils.getStatusDetailsLabel(order.statusDetails)}</div>
        )}
        {isNumber(order.disputeCount) && order.disputeCount > 1 && (
          <div className="tw-ml-1">
            <Tooltip
              title={t('features.orders.table.columns.reopened_dispute')}
            >
              <Chip
                label="x2"
                color="primary"
                variant="outlined"
                size="small"
                clickable
              />
            </Tooltip>
          </div>
        )}
      </div>
      {order.statusReason && (isManager || isMerchant) && (
        <div>{orderUtils.getStatusReasonLabel(order.statusReason)}</div>
      )}
      {order.internalStatusDetails && isManager && (
        <div>
          {orderUtils.getInternalStatusDetailsLabel(
            order.internalStatusDetails,
          )}
        </div>
      )}
    </Fragment>
  );
};
