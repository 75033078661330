import i18next from 'i18next';
import { map } from 'lodash';

import {
  DataGridColumnDefinition,
  DataGridColumnHeader,
  StatisticalInfo,
} from 'components';
import { FilterDefinition, PaymentType, Requisites } from 'types';

import { formUtils } from './form.utils';
import { formatUtils } from './format.utils';
import {
  FilterDefinitionType,
  OrderAutomationType,
  RequisitesStatus,
} from '../enums';

const getStatusLabel = (status: RequisitesStatus) => {
  const { t } = i18next;
  const key = `requisites_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getOrderAutomationTypeLabel = (type: OrderAutomationType) =>
  i18next.t(
    `features.requisites.requisites_list.order_automation_types.${type}`,
    { defaultValue: type },
  );

const getPaymentTypeLabel = (paymentType: PaymentType) =>
  i18next.t(`payment_types.${paymentType.code}`, {
    defaultValue: paymentType.name,
  });

const getPaymentTypesOptions = (paymentTypes: PaymentType[]) =>
  formUtils.getOptions(
    map(paymentTypes, (paymentType) => ({
      id: paymentType.id,
      name: getPaymentTypeLabel(paymentType),
    })),
  );

const getRequisitesFieldsFilters = <T,>(): FilterDefinition<T>[] => {
  const { t } = i18next;
  return [
    {
      name: 'cardInfo' as Extract<keyof T, string>,
      label: t('features.requisites.requisites_list.filters.card_info'),
      type: FilterDefinitionType.Text,
    },
    {
      name: 'phone' as Extract<keyof T, string>,
      label: t('features.requisites.requisites_list.filters.phone'),
      type: FilterDefinitionType.Text,
    },
    {
      name: 'accountNumber' as Extract<keyof T, string>,
      label: t('features.requisites.requisites_list.filters.account_number'),
      type: FilterDefinitionType.Text,
    },
  ];
};

const getLimitsColumns = (): DataGridColumnDefinition<Requisites>[] => {
  const { t } = i18next;
  const prefix = `features.requisites.requisites_list.table.columns`;
  return [
    {
      header: (
        <DataGridColumnHeader
          title={t(`${prefix}.count`)}
          tooltip={t(`${prefix}.period`)}
        />
      ),
      valueGetter: (item) => (
        <div>
          <StatisticalInfo
            used={item.limits?.limitCountPerHour}
            total={item.limitCountPerHour}
          />
          <StatisticalInfo
            used={item.limits?.limitCountPerDay}
            total={item.limitCountPerDay}
          />
          <StatisticalInfo
            used={item.limits?.limitCountPerMonth}
            total={item.limitCountPerMonth}
          />
        </div>
      ),
    },
    {
      header: (
        <DataGridColumnHeader
          title={t(`${prefix}.sum`)}
          tooltip={t(`${prefix}.period`)}
        />
      ),
      valueGetter: (item) => (
        <div>
          <StatisticalInfo
            used={item.limits?.limitSumPerHour}
            total={item.limitSumPerHour}
            formatter={formatUtils.formatMoney}
          />
          <StatisticalInfo
            used={item.limits?.limitSumPerDay}
            total={item.limitSumPerDay}
            formatter={formatUtils.formatMoney}
          />
          <StatisticalInfo
            used={item.limits?.limitSumPerMonth}
            total={item.limitSumPerMonth}
            formatter={formatUtils.formatMoney}
          />
        </div>
      ),
    },
  ];
};

const getStatusOptions = (archived: boolean | undefined) => {
  if (archived) {
    return [RequisitesStatus.Blocked, RequisitesStatus.Inactive];
  } else {
    return [...Object.values(RequisitesStatus)];
  }
};

export const requisitesUtils = {
  getStatusOptions,
  getStatusLabel,
  getLimitsColumns,
  getOrderAutomationTypeLabel,
  getPaymentTypesOptions,
  getRequisitesFieldsFilters,
  getPaymentTypeLabel,
};
