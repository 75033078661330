import axios from 'axios';

import { env } from 'config';
import {
  QueryParams,
  RequisiteLimits,
  PlatformPaymentLimits,
  PayinRequisiteLimits,
  PayoutRequisitesAutomationLimits,
} from 'types';
import { apiUtils } from 'utils';

const path = `${env.apiUrl}/payment-limits`;
const defaultPaymentLimitsPath = `${path}/default`;
const defaultPlatformPaymentLimitsPath = `${path}/default/platform`;

const getDefaultRequisitesLimits = async (
  params?: Partial<QueryParams>,
): Promise<RequisiteLimits[]> =>
  (await apiUtils.getWithQueryParams(defaultPaymentLimitsPath, params)).data;

const createDefaultRequisitesLimits = async (
  body: Partial<RequisiteLimits>,
): Promise<RequisiteLimits> =>
  (await axios.post(defaultPaymentLimitsPath, body)).data;

const updateDefaultRequisitesLimits = async ({
  id,
  data,
}: {
  id: string;
  data: Partial<RequisiteLimits>;
}): Promise<RequisiteLimits> =>
  (await axios.patch(`${defaultPaymentLimitsPath}/${id}`, data)).data;

const removeDefaultRequisitesLimits = async (
  id: string,
): Promise<RequisiteLimits> =>
  (await axios.delete(`${defaultPaymentLimitsPath}/${id}`)).data;

const getDefaultPlatformLimits = async (
  params?: Partial<QueryParams>,
): Promise<PlatformPaymentLimits[]> =>
  (await apiUtils.getWithQueryParams(defaultPlatformPaymentLimitsPath, params))
    .data;

const createDefaultPlatformLimits = async (
  body: Partial<PlatformPaymentLimits>,
): Promise<PlatformPaymentLimits> =>
  (await axios.post(defaultPlatformPaymentLimitsPath, body)).data;

const updateDefaultPlatformRequisitesLimits = async ({
  id,
  data,
}: {
  id: string;
  data: Partial<PlatformPaymentLimits>;
}): Promise<PlatformPaymentLimits> =>
  (await axios.patch(`${defaultPlatformPaymentLimitsPath}/${id}`, data)).data;

const removeDefaultPlatformLimits = async (
  id: string,
): Promise<PlatformPaymentLimits> =>
  (await axios.delete(`${defaultPlatformPaymentLimitsPath}/${id}`)).data;

const getAllPayinRequisitesLimitsPaginated = async (
  params?: Partial<QueryParams>,
): Promise<PayinRequisiteLimits[]> =>
  (await apiUtils.getWithQueryParams(`${path}/payin/requisites`, params)).data;

const getPayinRequisitesLimits =
  (requisitesId?: string) =>
  async (params?: Partial<QueryParams>): Promise<PayinRequisiteLimits[]> =>
    (
      await apiUtils.getWithQueryParams(
        `${path}/payin/requisites/${requisitesId}`,
        params,
      )
    ).data;

const removePayinRequisitesLimits = async (
  id: string,
): Promise<PayinRequisiteLimits> =>
  (await axios.delete(`${path}/payin/${id}`)).data;

const getAllPayoutRequisitesAutomationLimitsPaginated = async (
  params?: Partial<QueryParams>,
): Promise<PayoutRequisitesAutomationLimits[]> =>
  (
    await apiUtils.getWithQueryParams(
      `${path}/payout/requisites-automation`,
      params,
    )
  ).data;

const getPayoutRequisitesAutomationLimits =
  (requisitesAutomationId?: string) =>
  async (
    params?: Partial<QueryParams>,
  ): Promise<PayoutRequisitesAutomationLimits[]> =>
    (
      await apiUtils.getWithQueryParams(
        `${path}/payout/requisites-automation/${requisitesAutomationId}`,
        params,
      )
    ).data;

const removePayoutRequisitesAutomationLimits = async (
  id: string,
): Promise<PayoutRequisitesAutomationLimits> =>
  (await axios.delete(`${path}/payout/${id}`)).data;

export const paymentLimitsApi = {
  getDefaultRequisitesLimits,
  createDefaultRequisitesLimits,
  updateDefaultRequisitesLimits,
  removeDefaultRequisitesLimits,
  getDefaultPlatformLimits,
  createDefaultPlatformLimits,
  updateDefaultPlatformRequisitesLimits,
  removeDefaultPlatformLimits,
  getAllPayinRequisitesLimitsPaginated,
  getPayinRequisitesLimits,
  removePayinRequisitesLimits,
  getPayoutRequisitesAutomationLimits,
  getAllPayoutRequisitesAutomationLimitsPaginated,
  removePayoutRequisitesAutomationLimits,
};
