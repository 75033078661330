import { SettingsBackupRestoreOutlined as SettingsBackupRestoreOutlinedIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { map } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { ordersApi } from 'api';
import {
  CloseFormikDialogResult,
  FormControls,
  FormikDialog,
  FormikSelect,
} from 'components';
import { OrderStatusReason, QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayinOrder;
};

export const OrderCancelledToDisputeColumn: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.cancelled_to_dispute_dialog',
  });
  const queryClient = useQueryClient();
  const { role } = useUser();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onStatusChange = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.CancelledOrders);
    setDialogOpen(false);
  }, [queryClient]);

  const { mutate: cancelledToDispute } = useMutation<
    PayinOrder,
    AxiosError,
    { id: string; statusReason: OrderStatusReason }
  >(ordersApi.cancelledToDisputeAsRole(role), {
    onSuccess: onStatusChange,
  });

  const statusReasonOptions = useMemo(
    () =>
      map(
        [OrderStatusReason.WaitingTrader, OrderStatusReason.WaitingMerchant],
        (status) => ({
          label: orderUtils.getStatusReasonLabel(status),
          value: status,
        }),
      ),
    [],
  );

  const handleDialogClose = useCallback(
    ({ ok, data }: CloseFormikDialogResult<PayinOrder>) => {
      if (ok && data) {
        cancelledToDispute({
          id: order.id,
          statusReason: data.values.statusReason,
        });
      } else {
        setDialogOpen(false);
      }
    },
    [order, cancelledToDispute],
  );

  const handleClick = useCallback((event: any) => {
    (event as MouseEvent)?.stopPropagation();
    (event as MouseEvent)?.preventDefault();
    setDialogOpen(true);
  }, []);

  if (!order?.traderId && !order?.p2pProviderOrderId) {
    return null;
  }

  return (
    <Fragment>
      <IconButton color="inherit" onClick={handleClick}>
        <SettingsBackupRestoreOutlinedIcon color="primary" />
      </IconButton>
      <FormikDialog
        dialogProps={{
          open: dialogOpen,
          title: t('title'),
          onClose: handleDialogClose,
        }}
        formikProps={{
          initialValues: {
            statusReason: '',
          },
        }}
      >
        {() => (
          <FormControls>
            <div>{t('description')}</div>
            <FormikSelect
              name="statusReason"
              label={t('reason')}
              options={statusReasonOptions}
              noneOption
            />
          </FormControls>
        )}
      </FormikDialog>
    </Fragment>
  );
};
