import { Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty, isNil, merge, omit, pick } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { usersApi, userWalletsApi } from 'api';
import {
  CloseFormikDialogResult,
  DataWrapper,
  Dialog,
  DialogProps,
  FormControls,
  FormikNumericField,
  FormikTextField,
  NetworkSelect,
  UserSelect,
} from 'components';
import { Network, QueryKey } from 'enums';
import { useCurrencies, useMutation, usePrevious, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User, UserWallet } from 'types';
import { filtersUtils, validationUtils } from 'utils';

type Values = Pick<UserWallet, 'maxAutoWithdrawalAmount'> &
  Partial<Pick<UserWallet, 'id' | 'userId' | 'address' | 'network'>>;

type Props = DialogProps<UserWallet> & {
  initialData?: Pick<UserWallet, 'userId' | 'address' | 'network'>;
  externalWalletHolders?: User[];
};

export const UserWalletDetailsDialog: React.FC<Props> = ({
  open,
  data,
  initialData,
  externalWalletHolders,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.user_wallets',
  });
  const { t: tCommon } = useTranslation();
  const { role } = useUser();
  const prevOpen = usePrevious(open);

  const queryClient = useQueryClient();
  const { getDefaultAssetCurrency } = useCurrencies();

  const defaultInitialData = useMemo(
    () => ({
      id: '',
      userId: '',
      address: '',
      network: Network.TRC20,
      maxAutoWithdrawalAmount: 0,
    }),
    [],
  );

  const [initialValues, setInitialValues] =
    useState<Values>(defaultInitialData);

  const resetInitialValues = useCallback(() => {
    setInitialValues(defaultInitialData);
  }, [defaultInitialData]);

  const initialDataQuery = useMemo(() => {
    if (initialData) {
      return filtersUtils.encode(
        JSON.stringify({
          userId: initialData.userId,
          address: initialData.address,
          network: initialData.network,
        }),
      );
    }
  }, [initialData]);

  const setInitialValuesFromPartialData = useCallback(
    (item: Partial<UserWallet>) => {
      setInitialValues(
        merge({}, defaultInitialData, {
          id: item.id,
          userId: item.userId,
          address: item.address,
          network: item.network,
          maxAutoWithdrawalAmount: item.maxAutoWithdrawalAmount || 0,
        }),
      );
    },
    [defaultInitialData],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        userId: Yup.string().required(tCommon('errors.required')),
        address: Yup.string().required(tCommon('errors.required')),
        network: Yup.string().required(tCommon('errors.required')),
      }),
    [tCommon],
  );

  const queryResultByInitialData = useQuery(
    [QueryKey.UserWallets, initialDataQuery],
    () =>
      userWalletsApi.getAllPaginatedAsRole(role)({
        page: 1,
        take: 1,
        filters: initialDataQuery!,
      }),
    {
      enabled: open && !!initialData,
      onSuccess: (responsePaginated) => {
        if (!isEmpty(responsePaginated.items)) {
          setInitialValuesFromPartialData(responsePaginated.items[0]);
        } else {
          setInitialValuesFromPartialData(initialData!);
        }
      },
    },
  );

  const isNew = useMemo(() => !initialValues?.id, [initialValues]);

  const isFetchRequired = useMemo(
    () => !!data?.id || initialData,
    [data?.id, initialData],
  );

  const canChangeMainProperties = useMemo(
    () => isNew && !initialData,
    [initialData, isNew],
  );

  const queryResultExternalWalletHolders = useQuery(
    QueryKey.ExternalWalletHolders,
    usersApi.getExternalWalletHolders,
    {
      enabled: !!open && isNil(externalWalletHolders),
    },
  );

  const queryResultById = useQuery(
    [QueryKey.UserWallets, data?.id],
    () => userWalletsApi.getOneAsRole(role)(data?.id!),
    {
      enabled: open && !!data?.id,
      onSuccess: setInitialValuesFromPartialData,
    },
  );

  const queryResult = useMemo(() => {
    if (data?.id) {
      return queryResultById;
    } else if (initialData) {
      return queryResultByInitialData;
    }
  }, [data?.id, initialData, queryResultById, queryResultByInitialData]);

  const mutationCreate = useMutation<UserWallet, AxiosError, Values>(
    userWalletsApi.createAsRoleWithAxiosConfig(role),
    {
      requireSecureConfirm: true,
    },
  );

  const mutationUpdate = useMutation<
    UserWallet,
    AxiosError,
    { id: string; data: Values }
  >(userWalletsApi.updateAsRole(role));

  const isLoading = useMemo(
    () => queryResult?.isLoading || queryResult?.isRefetching,
    [queryResult?.isLoading, queryResult?.isRefetching],
  );

  const title = useMemo(
    () =>
      isLoading
        ? t('details_dialog.title.loading')
        : isNew
        ? t('details_dialog.title.create')
        : t('details_dialog.title.edit'),
    [isLoading, isNew, t],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.UserWallets);
          formikHelpers.resetForm();
          onClose({ ok: true });
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => {
          formikHelpers.setSubmitting(false);
        },
      };

      formikHelpers.setSubmitting(true);

      values = omit(values, ['id']);
      if (isNew) {
        mutationCreate.mutate(values, options);
      } else {
        values = pick(values, ['maxAutoWithdrawalAmount']);
        mutationUpdate.mutate(
          { id: initialValues?.id!, data: values },
          options,
        );
      }
    },
    [
      isNew,
      queryClient,
      onClose,
      mutationCreate,
      mutationUpdate,
      initialValues?.id,
    ],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      resetInitialValues();
    }
  }, [open, prevOpen, resetInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          {...rest}
          title={title}
          open={open}
          data={{ values: formik.values, formikHelpers: formik }}
          mutation={isNew ? mutationCreate : mutationUpdate}
          disabled={formik.isSubmitting}
          okDisabled={isLoading || !formik.isValid}
          onClose={handleClose}
        >
          <DataWrapper queryResult={isFetchRequired ? queryResult : undefined}>
            <Fragment>
              {initialData && initialValues.id && (
                <Alert severity="success" sx={{ mb: 10 }}>
                  {t('details_dialog.messages.exists')}
                </Alert>
              )}
              <FormControls>
                <UserSelect
                  name="userId"
                  users={
                    externalWalletHolders ||
                    queryResultExternalWalletHolders?.data
                  }
                  label={t('fields.user')}
                  disabled={!canChangeMainProperties}
                />
                <NetworkSelect
                  name="network"
                  disabled={!canChangeMainProperties}
                />
                <FormikTextField
                  name="address"
                  label={t('fields.address')}
                  disabled={!canChangeMainProperties}
                />
                <FormikNumericField
                  name="maxAutoWithdrawalAmount"
                  label={t('fields.max_auto_withdrawal_amount')}
                  helperText={t(
                    'fields.max_auto_withdrawal_amount_description',
                  )}
                  autoFocus={!canChangeMainProperties}
                  allowNegative={false}
                  suffix={getDefaultAssetCurrency()?.symbol}
                />
              </FormControls>
            </Fragment>
          </DataWrapper>
        </Dialog>
      )}
    </Formik>
  );
};
