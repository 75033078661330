import { env } from 'config';
import { P2PProviderRequest, QueryParams } from 'types';
import { apiUtils } from 'utils';

const url = `${env.apiUrl}/p2p-providers-payout-requests`;

export const p2pProvidersPayoutRequestsApi = {
  getForOrder:
    (orderId: string) =>
    async (params?: Partial<QueryParams>): Promise<P2PProviderRequest[]> =>
      (await apiUtils.getWithQueryParams(`${url}/order/${orderId}`, params))
        .data,
};
