import moment from 'moment';
import React from 'react';

import { AdditionalOrderColumn, OrdersTab } from 'enums';
import { useQueryFilters } from 'hooks';
import { OrderFilters } from 'types';

import { ManageOrdersList } from '../ManageOrderList';

export const RequisitesVerificationOrders: React.FC = () => {
  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  return (
    <ManageOrdersList
      tab={OrdersTab.RequisitesVerification}
      additionalColumns={[
        AdditionalOrderColumn.Trader,
        AdditionalOrderColumn.TraderCard,
      ]}
    />
  );
};
