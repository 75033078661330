import i18next from 'i18next';
import { identity } from 'lodash';

import { AppConfigArea, AppConfigKey, AppConfigValueType } from 'enums';

import { p2pProviderUtils } from './p2p-provider.utils';
import { payoutRequisitesAutomationUtils } from './payout-requisites-automation.utils';

const getEntityTypeLabel = (area?: AppConfigArea): ((type: any) => string) => {
  switch (area) {
    case AppConfigArea.PayinP2PProvider:
    case AppConfigArea.PayoutP2PProvider:
      return p2pProviderUtils.getTypeLabel;
    case AppConfigArea.PayoutRequisitesAutomation:
      return payoutRequisitesAutomationUtils.getTypeLabel;
    default:
      return identity;
  }
};

const getKeyLabel = (key: AppConfigKey) =>
  i18next.t(`app_config_key.${key}`, { defaultValue: key });

const getValueField = (valueType: AppConfigValueType) => {
  if (valueType === AppConfigValueType.JsonObject) {
    return 'jsonObjectValue';
  } else if (valueType === AppConfigValueType.JsonArray) {
    return 'jsonArrayValue';
  }
  return '';
};

export const appConfigUtils = {
  getEntityTypeLabel,
  getKeyLabel,
  getValueField,
};
