import { Navigate, Route } from 'react-router-dom';

import { AdminRoute, PayRoutes } from 'components';
import { ROUTE_PATH as ROUTE_PATH_ALL } from 'constants/routes';
import { BalanceReconciliationPage } from 'features/balance-reconciliation';
import { BlockedCustomersPage } from 'features/blocked-customers';
import { CommonLimitsPage } from 'features/common-limits';
import { ConfigsPage } from 'features/configs';
import { CurrencyExchangeRequestsPage } from 'features/currency-exchange-requests';
import { ExecutionMetricsPage } from 'features/execution-metrics';
import { ManageOrderAutomationPage } from 'features/order-automation';
import {
  P2PProvidersPage,
  P2PProviderDetailsPage,
} from 'features/p2p-providers';
import { ManagePayoutOrderAutomationPage } from 'features/payout-order-automation';
import { PayoutRequisitesPage } from 'features/payout-requisites';
import {
  RequisitesDetailsPage,
  RequisitesGroupDetails,
  RequisitesPage,
} from 'features/requisites';
import {
  AdminMainPage,
  AdminSettingsPage,
  AdminUsersPage,
  AdminTradersPage,
  AdminShopsPage,
  AdminShopDetailsPage,
  AdminPayinCashbackPage,
  AdminPayoutCashbackPage,
  AdminTraderDetails,
  AdminUserDetailsPage,
  AdminFundsRequestsPage,
  AdminInternalTransfersPage,
  AdminAssetBalanceHistoryPage,
  AdminOrdersPage,
  AdminPayinOrdersPage,
  AdminPayoutOrdersPage,
  AdminGroupsPage,
  AdminGroupDetailsPage,
  AdminCurrenciesPage,
  AdminCurrencyExchangeDetailsPage,
  AdminRequisitesDistributionPage,
  AdminBanksPage,
  AdminPayinStatisticsPage,
  AdminPayoutStatisticsPage,
  AdminTransactionsPage,
  AdminDepositTransactionsPage,
  AdminWithdrawalTransactionsPage,
  AdminTradeMethodsPage,
  AdminTradeMethodParsingsPage,
  AdminTradeMethodAutomationSourcesPage,
  AdminTradeMethodFilesValidationPage,
  AdminAnomaliesPage,
  AdminArchivePage,
  AdminWebhooksPage,
  AdminAutomationSourcesPage,
  AdminSciDomainsPage,
  AdminWalletsPage,
  AdminAuditPage,
} from 'pages';

const ROUTE_PATH = ROUTE_PATH_ALL.ADMIN;

export const AdminRoutes = () => (
  <Route path={ROUTE_PATH.MAIN} element={<AdminRoute />}>
    <Route path="" index element={<AdminMainPage />} />
    <Route path={ROUTE_PATH.SETTINGS} element={<AdminSettingsPage />} />
    <Route path={ROUTE_PATH.USERS} element={<AdminUsersPage />} />
    <Route path={ROUTE_PATH.TRADERS} element={<AdminTradersPage />} />
    <Route path={ROUTE_PATH.SHOPS} element={<AdminShopsPage />} />

    <Route path={ROUTE_PATH.SHOP_DETAILS} element={<AdminShopDetailsPage />} />
    <Route
      path={`${ROUTE_PATH.REQUISITES}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.REQUISITES}
          payinElement={<RequisitesPage />}
          payoutElement={<PayoutRequisitesPage />}
          commonElement={<CommonLimitsPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_DETAILS}
      element={<RequisitesDetailsPage />}
    />
    <Route
      path={`${ROUTE_PATH.CASHBACK}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.CASHBACK}
          payinElement={<AdminPayinCashbackPage />}
          payoutElement={<AdminPayoutCashbackPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_GROUP_DETAILS}
      element={<RequisitesGroupDetails />}
    />

    <Route path={ROUTE_PATH.TRADER_DETAILS} element={<AdminTraderDetails />} />
    <Route path={ROUTE_PATH.USER_DETAILS} element={<AdminUserDetailsPage />} />
    <Route
      path={ROUTE_PATH.FUNDS_REQUESTS}
      element={<AdminFundsRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.INTERNAL_TRANSFERS}
      element={<AdminInternalTransfersPage />}
    />
    <Route
      path={ROUTE_PATH.ASSET_BALANCE_HISTORY}
      element={<AdminAssetBalanceHistoryPage />}
    />
    <Route path={ROUTE_PATH.PAYIN_ORDERS} element={<AdminOrdersPage />} />

    <Route
      path={ROUTE_PATH.ORDERS}
      element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />}
    />
    <Route path={ROUTE_PATH.ORDERS} element={<AdminOrdersPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_ORDERS}
        element={<AdminPayinOrdersPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_ORDERS}
        element={<AdminPayoutOrdersPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />} />
    </Route>

    <Route path={ROUTE_PATH.GROUPS} element={<AdminGroupsPage />} />
    <Route
      path={ROUTE_PATH.GROUP_DETAILS}
      element={<AdminGroupDetailsPage />}
    />
    <Route path={ROUTE_PATH.CURRENCIES} element={<AdminCurrenciesPage />} />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE_DETAILS}
      element={<AdminCurrencyExchangeDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.DISTRIBUTION}
      element={<AdminRequisitesDistributionPage />}
    />
    <Route path={ROUTE_PATH.BANKS} element={<AdminBanksPage />} />

    <Route
      path={`${ROUTE_PATH.STATISTICS}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.STATISTICS}
          payinElement={<AdminPayinStatisticsPage />}
          payoutElement={<AdminPayoutStatisticsPage />}
        />
      }
    />
    <Route
      path={ROUTE_PATH.TRANSACTIONS}
      element={<Navigate to={ROUTE_PATH.DEPOSIT_TRANSACTIONS} />}
    />
    <Route path={ROUTE_PATH.TRANSACTIONS} element={<AdminTransactionsPage />}>
      <Route
        index
        path={ROUTE_PATH.DEPOSIT_TRANSACTIONS}
        element={<AdminDepositTransactionsPage />}
      />
      <Route
        path={ROUTE_PATH.WITHDRAWAL_TRANSACTIONS}
        element={<AdminWithdrawalTransactionsPage />}
      />
      <Route
        path="*"
        element={<Navigate to={ROUTE_PATH.DEPOSIT_TRANSACTIONS} />}
      />
    </Route>

    <Route path={ROUTE_PATH.TRADE_METHODS}>
      <Route
        index
        path={ROUTE_PATH.TRADE_METHODS}
        element={<AdminTradeMethodsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_PARSINGS}
        element={<AdminTradeMethodParsingsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_AUTOMATION_SOURCES}
        element={<AdminTradeMethodAutomationSourcesPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_FILES_VALIDATION}
        element={<AdminTradeMethodFilesValidationPage />}
      />
    </Route>
    <Route path={ROUTE_PATH.ANOMALIES} element={<AdminAnomaliesPage />} />
    <Route
      path={`${ROUTE_PATH.ORDER_AUTOMATION}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.ORDER_AUTOMATION}
          payinElement={<ManageOrderAutomationPage />}
          payoutElement={<ManagePayoutOrderAutomationPage />}
        />
      }
    ></Route>
    <Route path={ROUTE_PATH.ARCHIVE} element={<AdminArchivePage />} />
    <Route path={ROUTE_PATH.WEBHOOKS} element={<AdminWebhooksPage />} />
    <Route
      path={ROUTE_PATH.AUTOMATION_SOURCES}
      element={<AdminAutomationSourcesPage />}
    />
    <Route path={ROUTE_PATH.SCI_DOMAINS} element={<AdminSciDomainsPage />} />
    <Route path={ROUTE_PATH.WALLETS} element={<AdminWalletsPage />} />
    <Route path={ROUTE_PATH.AUDIT} element={<AdminAuditPage />} />
    <Route
      path={ROUTE_PATH.BALANCE_RECONCILIATION}
      element={<BalanceReconciliationPage />}
    />
    <Route path={ROUTE_PATH.P2P_PROVIDERS} element={<P2PProvidersPage />} />
    <Route
      path={ROUTE_PATH.P2P_PROVIDERS_DETAILS}
      element={<P2PProviderDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE}
      element={<CurrencyExchangeRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.BLOCKED_CUSTOMERS}
      element={<BlockedCustomersPage />}
    />
    <Route path={ROUTE_PATH.CONFIGS} element={<ConfigsPage />} />
    <Route
      path={ROUTE_PATH.EXECUTION_METRICS}
      element={<ExecutionMetricsPage />}
    />
  </Route>
);
