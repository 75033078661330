import { Check } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ordersApi } from 'api';
import {
  CloseDialogHandler,
  CloseDialogResult,
  DataWrapper,
  Dialog,
  Money,
  Timer,
  RequisitesInfo,
} from 'components';
import {
  CancelStatusDetails,
  OrderStatus,
  OrderStatusDetails,
  QueryKey,
  RequisitesVerificationStatus,
} from 'enums';
import { useMeta, useMutation, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayinOrder, Requisites } from 'types';
import { formatUtils } from 'utils';

const REFETCH_INTERVAL = 1000 * 10; // 10 seconds

type CompleteOrderViewProps = {
  orderId: string;
  onOrderUpdate: (order: PayinOrder) => void;
};

const CompleteOrderView: React.FC<CompleteOrderViewProps> = ({
  orderId,
  onOrderUpdate,
}) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.requisites.verification',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState<PayinOrder>();

  const isSuccess = useMemo(() => {
    if (!order) {
      return false;
    }

    if (
      order.status === OrderStatus.Cancelled &&
      order.statusDetails ===
        CancelStatusDetails.CancelledRequisitesVerificationOrder
    ) {
      return true;
    }

    return false;
  }, [order]);

  const showError = useMemo(() => {
    if (error) {
      return true;
    }
    if (!order) {
      return false;
    }
    if (
      order.status === OrderStatus.Cancelled &&
      order.statusDetails ===
        CancelStatusDetails.CancelledRequisitesVerificationOrder
    ) {
      return false;
    }

    // other status details
    if (order.status === OrderStatus.Cancelled) {
      return true;
    }
  }, [error, order]);

  const handleError = useCallback((error: any) => {
    setLoading(false);
    setError(error);
  }, []);

  const handleSuccess = useCallback(
    (data: PayinOrder) => {
      setOrder(data);
      onOrderUpdate(data);
      if (data?.status === OrderStatus.Cancelled) {
        setLoading(false);
      }
    },
    [onOrderUpdate],
  );

  useQuery(QueryKey.Order, () => ordersApi.getOneAsTrader(orderId), {
    refetchInterval: REFETCH_INTERVAL,
    onError: handleError,
    onSuccess: handleSuccess,
    enabled: !isSuccess && !showError,
  });

  return (
    <div className="tw-relative">
      <DataWrapper
        isLoading={loading}
        loadingView={
          <div className="tw-text-center">
            <CircularProgress classes={{ root: 'tw-text-center' }} />
            <div className="tw-mt-2">{t('complete_order_view.loading')}</div>
          </div>
        }
      >
        <Fragment>
          {!loading && showError && <div>{t('complete_order_view.error')}</div>}
          {!loading && isSuccess && (
            <div className="tw-text-center">
              <Check color="success" fontSize="large" className="tw-mx-auto" />
              <div className="tw-mt-4">{t('complete_order_view.success')}</div>
            </div>
          )}
        </Fragment>
      </DataWrapper>
    </div>
  );
};

type Props = {
  open: boolean;
  requisites?: Requisites;
  onClose: CloseDialogHandler;
};

enum WizardStep {
  Initial,
  Requisites,
  CompleteOrder,
  ActivateRequisites,
}

export const TraderRequisitesVerificationDialog: React.FC<Props> = ({
  open,
  requisites,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.requisites.verification',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites',
  });
  const { payinRequisitesVerificationExpirationAfter } = useMeta();

  const prevOpen = usePrevious(open);
  const [wizardStep, setWizardStep] = useState<WizardStep>(WizardStep.Initial);
  const [order, setOrder] = useState<PayinOrder>();

  const verificationExpirationDate = useMemo(
    () =>
      moment(requisites?.verifiedAt)
        .add(payinRequisitesVerificationExpirationAfter, 'hours')
        .toDate(),
    [requisites, payinRequisitesVerificationExpirationAfter],
  );

  const {
    mutate: createVerificationOrder,
    isLoading: createVerificationOrderLoading,
    error: createVerificationOrderError,
    reset,
  } = useMutation(
    () => ordersApi.createRequisitesVerificationOrder(requisites?.id!),
    { onSuccess: setOrder, notifierType: 'none' },
  );

  const okLabel = useMemo(() => {
    if (wizardStep === WizardStep.Initial) {
      return t('initial_view.next');
    }
    if (wizardStep === WizardStep.Requisites && order) {
      return t('requisites_view.next');
    }
  }, [order, wizardStep, t]);

  const okDisabled = useMemo(
    () =>
      createVerificationOrderLoading ||
      !!createVerificationOrderError ||
      (wizardStep === WizardStep.CompleteOrder &&
        order?.statusDetails !==
          OrderStatusDetails.CancelledRequisitesVerificationOrder),
    [
      wizardStep,
      createVerificationOrderError,
      createVerificationOrderLoading,
      order,
    ],
  );

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok && wizardStep !== WizardStep.CompleteOrder) {
        setWizardStep((prev) => ++prev);
        return;
      }

      reset();
      setOrder(undefined);
      onClose(result);
    },
    [wizardStep, reset, onClose],
  );

  useEffect(() => {
    if (open && !prevOpen) {
      setWizardStep(WizardStep.Initial);
    }
  }, [open, prevOpen]);

  useEffect(() => {
    if (!order && requisites && wizardStep === WizardStep.Requisites) {
      createVerificationOrder();
    }
  }, [order, wizardStep, requisites, createVerificationOrder]);

  return (
    <Dialog
      title={t('title')}
      open={open}
      okLabel={okLabel}
      okDisabled={okDisabled}
      onClose={handleClose}
    >
      {wizardStep === WizardStep.Initial && requisites && (
        <Fragment>
          <div>
            <div>{`${t('status')}: ${tCommon(
              `verification_status.${
                requisites.verificationStatus ||
                RequisitesVerificationStatus.Expired
              }`,
            )}`}</div>
            {requisites?.verifiedAt && (
              <div>
                <div className="tw-mt-2">{`${tCommon(
                  'verification.last_verification_at',
                )}: ${formatUtils.formatDate(requisites.verifiedAt)}`}</div>

                <div className="tw-mt-2">{`${tCommon(
                  'verification.verification_expiration_at',
                )}: ${formatUtils.formatDate(
                  verificationExpirationDate,
                )}`}</div>
              </div>
            )}
            <div className="tw-mt-4">{t('initial_view.message1')}</div>
          </div>
        </Fragment>
      )}
      {wizardStep === WizardStep.Requisites && (
        <DataWrapper
          isLoading={createVerificationOrderLoading}
          isError={!!createVerificationOrderError}
        >
          <Fragment>
            {order && (
              <Fragment>
                <div className="tw-mb-2">
                  {t('requisites_view.order_created')}
                </div>
                <RequisitesInfo requisite={requisites} />
                <div className="tw-flex tw-mt-4 tw-text-lg">
                  <div className="tw-mr-2">
                    {t('requisites_view.order_amount')}
                  </div>
                  <Money
                    value={order.amount}
                    fiatCurrencyId={order.fiatCurrencyId}
                    symbol
                  />
                </div>
                {order.statusAt && (
                  <div className="tw-mt-4">
                    <Timer endTime={moment(order.statusTimeoutAt).toDate()} />
                  </div>
                )}
                {order.status === OrderStatus.Cancelled &&
                  order.statusDetails !==
                    OrderStatusDetails.CancelledRequisitesVerificationOrder && (
                    <div className="tw-text-red-500">
                      {t('requisites_view.order_cancelled')}
                    </div>
                  )}
              </Fragment>
            )}
          </Fragment>
        </DataWrapper>
      )}
      {wizardStep === WizardStep.CompleteOrder && order && (
        <CompleteOrderView orderId={order.id} onOrderUpdate={setOrder} />
      )}
    </Dialog>
  );
};
