import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';

import { authApi } from 'api';
import { StatusCode } from 'enums';
import { AuthData, authUtils } from 'utils';

import { env } from './env';

let isRefreshing = false;
type RefreshCallback = () => void;
let refreshSubscribers: RefreshCallback[] = [];

function getConfigWithToken(config: InternalAxiosRequestConfig) {
  if (
    config.url?.startsWith(env.apiUrl) ||
    config.url?.startsWith(env.dataApiUrl)
  ) {
    const accessToken = authUtils.getAccessToken();
    if (accessToken) {
      config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
  }
  return config;
}

function subscribeTokenRefresh(fn: RefreshCallback) {
  refreshSubscribers.push(fn);
}

function onRefreshed() {
  refreshSubscribers.map((callback: RefreshCallback) => callback());
}

function resetRefresh() {
  refreshSubscribers = [];
  isRefreshing = false;
}

const NOT_AUTHORIZED_WHITELIST = [
  '/api/auth/login',
  '/api/auth/2fa/authenticate',
];

const REFRESH_ACCESS_TOKEN_URL = '/api/auth/refresh/access-token';

export function getConfigWithTokenAfterAccessTokenRefresh(
  config: InternalAxiosRequestConfig<any>,
): Promise<InternalAxiosRequestConfig<any>> {
  if (!isRefreshing) {
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      authApi
        .refreshAccessToken()
        .then((authData: AuthData) => {
          authUtils.setAuthData(authData);
          onRefreshed();
          resetRefresh();
          resolve(getConfigWithToken(config));
        })
        .catch(() => {
          resetRefresh();
          reject();
        });
    });
  } else {
    return new Promise((resolve) => {
      subscribeTokenRefresh(() => resolve(getConfigWithToken(config)));
    });
  }
}

export function applyGlobalResponseInterceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (config) => config,
    (error: AxiosError) => {
      const statusCode = error?.response?.status;
      if (error?.config?.url) {
        const url = new URL(error.config?.url);
        const isRefreshTokenUrl = url?.pathname === REFRESH_ACCESS_TOKEN_URL;
        if (isRefreshTokenUrl) {
          authUtils.logout(error);
        } else if (
          statusCode === StatusCode.Unauthorized &&
          !NOT_AUTHORIZED_WHITELIST.includes(url.pathname)
        ) {
          return getConfigWithTokenAfterAccessTokenRefresh(error?.config).then(
            (config) => axios(config),
          );
        }
      }
      return Promise.reject(error);
    },
  );
}

export const applyGlobalRequestInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
      const url = config?.url ? new URL(config.url) : null;
      if (
        authUtils.accessTokenExpiresSoon() &&
        url?.pathname !== REFRESH_ACCESS_TOKEN_URL
      ) {
        return getConfigWithTokenAfterAccessTokenRefresh(config);
      }
      return getConfigWithToken(config);
    },
  );
};

applyGlobalResponseInterceptor(axios);
applyGlobalRequestInterceptor(axios);
