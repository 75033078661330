import axios from 'axios';

import { env } from 'config';

const url = `${env.apiUrl}/auth/2fa`;

const get = async (): Promise<{ secret: string; qr: string }> =>
  (await axios.get(url)).data;

const reset = async (): Promise<{ secret: string; qr: string }> =>
  (await axios.post(`${url}/reset`)).data;

const authenticate = async (token: string) =>
  (
    await axios.post(
      `${url}/authenticate`,
      { token },
      {
        withCredentials: true,
        headers: {
          Authorization: 'Bearer two-fa', // current strategy required any token, should be re-implemented
        },
      },
    )
  ).data;

const enable = async (token: string) =>
  (await axios.post(`${url}/enable`, { token })).data;

const disable = async () => (await axios.post(`${url}/disable`)).data;

export const authTwoFAApi = {
  get,
  reset,
  authenticate,
  enable,
  disable,
};
