import { Route, Navigate } from 'react-router-dom';

import { TechOperatorRoute, PayRoutes } from 'components';
import { ROUTE_PATH as ROUTE_PATH_ALL } from 'constants/routes';
import { BlockedCustomersPage } from 'features/blocked-customers';
import { CommonLimitsPage } from 'features/common-limits';
import { ConfigsPage } from 'features/configs';
import { CurrencyExchangeRequestsPage } from 'features/currency-exchange-requests';
import { ExecutionMetricsPage } from 'features/execution-metrics';
import { ManageOrderAutomationPage } from 'features/order-automation';
import {
  P2PProvidersPage,
  P2PProviderDetailsPage,
} from 'features/p2p-providers';
import { ManagePayoutOrderAutomationPage } from 'features/payout-order-automation';
import { PayoutRequisitesPage } from 'features/payout-requisites';
import {
  RequisitesDetailsPage,
  RequisitesGroupDetails,
  RequisitesPage,
} from 'features/requisites';
import {
  TechOperatorMainPage,
  TechOperatorFundsRequestsPage,
  AdminInternalTransfersPage,
  TechOperatorOrdersPage,
  TechOperatorPayinOrdersPage,
  TechOperatorPayoutOrdersPage,
  TechOperatorTradersPage,
  TechOperatorTraderDetails,
  TechOperatorShopsPage,
  TechOperatorShopDetailsPage,
  TechOperatorPayinCashbackPage,
  TechOperatorPayoutCashbackPage,
  TechOperatorGroupsPage,
  TechOperatorGroupDetailsPage,
  TechOperatorUsersPage,
  TechOperatorUserDetailsPage,
  TechOperatorSettingsPage,
  TechOperatorCurrenciesPage,
  TechOperatorCurrencyExchangeDetailsPage,
  TechOperatorBanksPage,
  TechOperatorTradeMethodsPage,
  TechOperatorTradeMethodParsingsPage,
  TechOperatorTradeMethodAutomationSourcesPage,
  TechOperatorTradeMethodFilesValidationPage,
  TechOperatorTransactionsPage,
  TechOperatorDepositTransactionsPage,
  TechOperatorWithdrawalTransactionsPage,
  TechOperatorAutomationSourcesPage,
  TechOperatorWebhooksPage,
  TechOperatorSciDomainsPage,
  TechOperatorArchivePage,
  TechOperatorAssetBalanceHistoryPage,
  TechOperatorAnomaliesPage,
  TechOperatorPayinStatisticsPage,
  TechOperatorPayoutStatisticsPage,
  TechOperatorAuditPage,
  TechOperatorRequisitesDistributionPage,
} from 'pages';

const ROUTE_PATH = ROUTE_PATH_ALL.TECH_OPERATOR;

export const TechOperatorRoutes = () => (
  <Route path={ROUTE_PATH.MAIN} element={<TechOperatorRoute />}>
    <Route path="" index element={<TechOperatorMainPage />} />
    <Route
      path={ROUTE_PATH.FUNDS_REQUESTS}
      element={<TechOperatorFundsRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.INTERNAL_TRANSFERS}
      element={<AdminInternalTransfersPage />}
    />
    <Route path={ROUTE_PATH.ORDERS} element={<TechOperatorOrdersPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_ORDERS}
        element={<TechOperatorPayinOrdersPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_ORDERS}
        element={<TechOperatorPayoutOrdersPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />} />
    </Route>
    <Route path={ROUTE_PATH.TRADERS} element={<TechOperatorTradersPage />} />
    <Route
      path={ROUTE_PATH.TRADER_DETAILS}
      element={<TechOperatorTraderDetails />}
    />
    <Route path={ROUTE_PATH.SHOPS} element={<TechOperatorShopsPage />} />
    <Route
      path={ROUTE_PATH.SHOP_DETAILS}
      element={<TechOperatorShopDetailsPage />}
    />
    <Route
      path={`${ROUTE_PATH.CASHBACK}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.CASHBACK}
          payinElement={<TechOperatorPayinCashbackPage />}
          payoutElement={<TechOperatorPayoutCashbackPage />}
        />
      }
    ></Route>
    <Route path={ROUTE_PATH.GROUPS} element={<TechOperatorGroupsPage />} />
    <Route
      path={ROUTE_PATH.GROUP_DETAILS}
      element={<TechOperatorGroupDetailsPage />}
    />
    <Route path={ROUTE_PATH.USERS} element={<TechOperatorUsersPage />} />
    <Route
      path={ROUTE_PATH.USER_DETAILS}
      element={<TechOperatorUserDetailsPage />}
    />
    <Route path={ROUTE_PATH.SETTINGS} element={<TechOperatorSettingsPage />} />
    <Route
      path={ROUTE_PATH.CURRENCIES}
      element={<TechOperatorCurrenciesPage />}
    />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE_DETAILS}
      element={<TechOperatorCurrencyExchangeDetailsPage />}
    />
    <Route path={ROUTE_PATH.BANKS} element={<TechOperatorBanksPage />} />
    <Route path={ROUTE_PATH.TRADE_METHODS}>
      <Route
        index
        path={ROUTE_PATH.TRADE_METHODS}
        element={<TechOperatorTradeMethodsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_PARSINGS}
        element={<TechOperatorTradeMethodParsingsPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_AUTOMATION_SOURCES}
        element={<TechOperatorTradeMethodAutomationSourcesPage />}
      />
      <Route
        path={ROUTE_PATH.TRADE_METHOD_FILES_VALIDATION}
        element={<TechOperatorTradeMethodFilesValidationPage />}
      />
    </Route>
    <Route
      path={ROUTE_PATH.TRANSACTIONS}
      element={<Navigate to={ROUTE_PATH.DEPOSIT_TRANSACTIONS} />}
    />
    <Route
      path={ROUTE_PATH.TRANSACTIONS}
      element={<TechOperatorTransactionsPage />}
    >
      <Route
        index
        path={ROUTE_PATH.DEPOSIT_TRANSACTIONS}
        element={<TechOperatorDepositTransactionsPage />}
      />
      <Route
        path={ROUTE_PATH.WITHDRAWAL_TRANSACTIONS}
        element={<TechOperatorWithdrawalTransactionsPage />}
      />
      <Route
        path="*"
        element={<Navigate to={ROUTE_PATH.DEPOSIT_TRANSACTIONS} />}
      />
    </Route>
    <Route
      path={`${ROUTE_PATH.ORDER_AUTOMATION}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.ORDER_AUTOMATION}
          payinElement={<ManageOrderAutomationPage />}
          payoutElement={<ManagePayoutOrderAutomationPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.AUTOMATION_SOURCES}
      element={<TechOperatorAutomationSourcesPage />}
    />
    <Route path={ROUTE_PATH.WEBHOOKS} element={<TechOperatorWebhooksPage />} />
    <Route
      path={ROUTE_PATH.SCI_DOMAINS}
      element={<TechOperatorSciDomainsPage />}
    />
    <Route
      path={`${ROUTE_PATH.REQUISITES}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.REQUISITES}
          payinElement={<RequisitesPage />}
          payoutElement={<PayoutRequisitesPage />}
          commonElement={<CommonLimitsPage />}
        />
      }
    ></Route>
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_DETAILS}
      element={<RequisitesDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.PAYIN_REQUISITES_GROUP_DETAILS}
      element={<RequisitesGroupDetails />}
    />
    <Route path={ROUTE_PATH.ARCHIVE} element={<TechOperatorArchivePage />} />
    <Route
      path={ROUTE_PATH.ASSET_BALANCE_HISTORY}
      element={<TechOperatorAssetBalanceHistoryPage />}
    />
    <Route
      path={ROUTE_PATH.ANOMALIES}
      element={<TechOperatorAnomaliesPage />}
    />
    <Route
      path={`${ROUTE_PATH.STATISTICS}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.STATISTICS}
          payinElement={<TechOperatorPayinStatisticsPage />}
          payoutElement={<TechOperatorPayoutStatisticsPage />}
        />
      }
    />
    <Route path={ROUTE_PATH.AUDIT} element={<TechOperatorAuditPage />} />
    <Route
      path={ROUTE_PATH.DISTRIBUTION}
      element={<TechOperatorRequisitesDistributionPage />}
    />
    <Route path={ROUTE_PATH.P2P_PROVIDERS} element={<P2PProvidersPage />} />
    <Route
      path={ROUTE_PATH.P2P_PROVIDERS_DETAILS}
      element={<P2PProviderDetailsPage />}
    />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE}
      element={<CurrencyExchangeRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.BLOCKED_CUSTOMERS}
      element={<BlockedCustomersPage />}
    />
    <Route path={ROUTE_PATH.CONFIGS} element={<ConfigsPage />} />
    <Route
      path={ROUTE_PATH.EXECUTION_METRICS}
      element={<ExecutionMetricsPage />}
    />
  </Route>
);
