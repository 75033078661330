import { find } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { internalTransferApi } from 'api';
import { DataGridColumnDefinition } from 'components';
import { QueryKey, InternalTransferTab, FilterDefinitionType } from 'enums';
import { InternalTransfers } from 'features/internal-transfers';
import { usePartialQuery, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  InternalTransfer,
  InternalTransferFilters,
  Shop,
  User,
} from 'types';

type Props = {
  queryResultShops?: UseQueryResult<Shop[]>;
  queryResultUsers?: UseQueryResult<User[]>;
};

export const ArchiveInternalTransfers: React.FC<Props> = ({
  queryResultShops,
  queryResultUsers,
}) => {
  const { role, isManager } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { p2pProviders } = useUserContext();

  const queryResult = usePartialQuery(
    QueryKey.InternalTransfers,
    internalTransferApi.getAllPaginatedByRole(
      role,
      InternalTransferTab.Archived,
    ),
  );

  const additionalFilters: FilterDefinition<InternalTransferFilters>[] =
    useMemo(
      () => [
        {
          label: t('fields.sender'),
          name: 'senderUserId',
          type: FilterDefinitionType.User,
          users: queryResultUsers?.data,
          getDisplayName: (senderUserId: string) =>
            find(queryResultUsers?.data, { id: senderUserId })?.name,
          hidden: !isManager,
        },
        {
          label: t('fields.recipient'),
          name: 'recipientUserId',
          type: FilterDefinitionType.User,
          users: queryResultUsers?.data,
          getDisplayName: (recipientUserId: string) =>
            find(queryResultUsers?.data, { id: recipientUserId })?.name,
          hidden: !isManager,
        },
      ],
      [t, queryResultUsers?.data, isManager],
    );

  const additionalColumns: DataGridColumnDefinition<InternalTransfer>[] =
    useMemo(
      () => [
        {
          header: t('fields.recipient'),
          valueGetter: (item) => {
            const shopName = find(queryResultShops?.data, {
              id: item.recipientAsset?.shopId,
            })?.name;
            const p2pProviderName = item.recipientAsset?.p2pProviderId
              ? find(p2pProviders, { id: item.recipientAsset?.p2pProviderId })
                  ?.name
              : null;

            return (
              <Fragment>
                <div>{item.recipientUser?.name}</div>
                {shopName && <div>{shopName}</div>}
                {p2pProviderName && <div>{p2pProviderName}</div>}
              </Fragment>
            );
          },
        },
      ],
      [queryResultShops, p2pProviders, t],
    );

  return (
    <InternalTransfers
      queryResult={queryResult}
      queryResultShops={queryResultShops}
      additionalFilters={additionalFilters}
      additionalColumns={additionalColumns}
      hideTitle
    />
  );
};
