import i18next from 'i18next';
import { find } from 'lodash';

import { FilterDefinitionType } from 'enums';
import {
  BlockedCustomersFilters,
  FilterDefinition,
  Merchant,
  Shop,
} from 'types';

import { formUtils } from './form.utils';

const getCommonFilters = (
  merchants?: Merchant[],
  shops?: Shop[],
): FilterDefinition<BlockedCustomersFilters>[] => {
  const { t } = i18next;
  const filters: FilterDefinition<BlockedCustomersFilters>[] = [
    {
      label: t('filters.merchant'),
      name: 'merchantId',
      type: FilterDefinitionType.Select,
      getDisplayName: (merchantId: string) =>
        find(merchants, { id: merchantId })?.user?.name,
      options: formUtils.getOptions(merchants || [], 'user.name' as any),
    },
    {
      label: t('filters.shop'),
      name: 'shopId',
      type: FilterDefinitionType.Shop,
      shops,
      getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
    },
    {
      label: t('filters.customer'),
      name: 'customerId',
      type: FilterDefinitionType.Text,
    },
  ];
  return filters;
};

export const blockedCustomersUtils = {
  getCommonFilters,
};
