import React from 'react';
import { useQuery } from 'react-query';

import { shopsApi, usersApi } from 'api';
import { QueryKey } from 'enums';
import { ProcessFundsRequests } from 'features/funds-request';
import { useShopsQuery, useUser } from 'hooks';

export const ActiveFundsRequests: React.FC = () => {
  const { data: users = [] } = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );
  const { role } = useUser();

  const { data: shops = [] } = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  return <ProcessFundsRequests hideTitle users={users} shops={shops} />;
};
