export enum OrdersTab {
  Active = 'active',
  Completed = 'completed',
  HoldCompleted = 'hold-completed',
  Cancelled = 'cancelled',
  Dispute = 'dispute',
  RequisitesVerification = 'requisites-verification',
  TraderDispute = 'trader-dispute',
  ProviderDispute = 'provider-dispute',
  All = 'all',
}
