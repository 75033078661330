import { Button } from '@mui/material';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usersApi } from 'api';
import { CloseDialogResult, CopyText, Dialog } from 'components';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';

type Props = {
  user: User;
};

export const ResetPasswordButton: React.FC<Props> = ({ user }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.reset_password_button',
  });
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState('');

  const { mutate: resetPassword } = useMutation(usersApi.resetPassword, {
    onSuccess: (data) => {
      setPassword(data.password);
    },
    requireSecureConfirm: true,
  });

  const close = useCallback(() => {
    setOpen(false);
    setPassword('');
  }, []);

  const handleClose = useCallback(
    (data: CloseDialogResult) => {
      if (!data.ok || password) {
        close();
        return;
      }
      if (!password) {
        resetPassword({ id: user.id });
      }
    },
    [user, password, close, resetPassword],
  );

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Fragment>
      <Button color="error" variant="outlined" onClick={handleClick}>
        {t('button')}
      </Button>
      <Dialog
        open={open}
        title={t('dialog.title')}
        okLabel={!password ? t('dialog.buttons.confirm') : undefined}
        hideCancel={!!password}
        onClose={handleClose}
      >
        <Fragment>
          {!password && (
            <Fragment>
              <div>{t('dialog.confirm_description')}</div>
            </Fragment>
          )}
          {password && (
            <div className="tw-text-center">
              <div className="tw-text-base tw-mb-2">
                {t('dialog.done_description')}
              </div>
              <CopyText text={password} />
            </div>
          )}
        </Fragment>
      </Dialog>
    </Fragment>
  );
};
