import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CopyText,
  DataGrid,
  DataGridColumnDefinition,
  dataGridColumns,
  DataWrapper,
  Json,
} from 'components';
import { WebhookMethod } from 'enums';
import { TranslationNamespace } from 'i18n';
import { P2PProviderRequest } from 'types';
import { formatUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<P2PProviderRequest[]>;
  formatJson?: boolean;
};

export const P2PProvidersRequestsTable: React.FC<Props> = ({
  queryResult,
  formatJson,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.p2p_providers_requests',
  });
  const columns = useMemo(
    (): DataGridColumnDefinition<P2PProviderRequest>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('columns.provider'),
        valueGetter: (item) => item.provider?.name,
        valueClassName: 'tw-min-w-[100px]',
      },
      {
        header: t(`columns.request`),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-mb-4">{`${t('fields.operation')}: ${t(
              `operation.${item.operation}`,
            )}`}</div>
            <CopyText
              label={`[${item.method || WebhookMethod.Post}] ${item.url}`}
              text={item.url}
            />
            {item.requestData && (
              <div className="tw-mt-4">
                {formatJson ? (
                  <Json text={item.requestData} />
                ) : (
                  <CopyText text={item.requestData} />
                )}
              </div>
            )}
          </Fragment>
        ),
        valueClassName: 'tw-min-w-[300px] tw-break-all',
      },
      {
        header: t('columns.response'),
        valueGetter: (item) => (
          <div>
            {item.statusCode && (
              <div>{`${t('fields.http_code')}: ${item.statusCode}`}</div>
            )}
            {item.duration && (
              <div>{`${t('fields.time')}: ${formatUtils.formatNumber(
                item.duration,
                {
                  suffix: 'ms',
                },
              )}`}</div>
            )}
            {item.responseData && (
              <div className="tw-mt-4">
                {formatJson ? (
                  <Json text={item.responseData} />
                ) : (
                  <CopyText text={item.responseData} />
                )}
              </div>
            )}
          </div>
        ),
        valueClassName: 'tw-min-w-[300px] tw-break-all',
      },
    ],
    [formatJson, t],
  );

  return (
    <DataWrapper queryResult={queryResult}>
      <DataGrid columns={columns} data={queryResult.data}></DataGrid>
    </DataWrapper>
  );
};
