import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { filter, find, isEmpty } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { userWalletsApi, usersApi } from 'api';
import {
  CopyText,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  DataGridColumnHeader,
  DialogProps,
  Money,
  QueryFilters,
  dataGridColumns,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { UserWalletDetailsDialog, WalletResources } from 'features/wallets';
import { useCurrencies, useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, UserWallet, WalletResourcesData } from 'types';

type UserWalletsFilters = {
  userId: string;
  address: string;
};

export const UserWallets: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.user_wallets',
  });
  const { role } = useUser();

  const { getDefaultAssetCurrency } = useCurrencies();

  const [resources, setResources] = useState<{
    [id: string]: WalletResourcesData;
  }>({});
  const [resourcesLoading, setResourcesLoading] = useState<string[]>([]);

  const queryResult = usePartialQuery(
    QueryKey.UserWallets,
    userWalletsApi.getAllPaginatedAsRole(role),
  );

  const queryResultExternalWalletHolders = useQuery(
    QueryKey.ExternalWalletHolders,
    usersApi.getExternalWalletHolders,
  );

  const { mutate: getResources } = useMutation(userWalletsApi.getResources, {
    onMutate: (itemId: string) => {
      setResourcesLoading((data) => [...data, itemId]);
    },
    onSuccess: (data) => {
      setResources((resources) => ({
        ...resources,
        [data.id]: data,
      }));
    },
    onSettled: (data, error, itemId: string) => {
      setResourcesLoading((data) => filter(data, (id) => id !== itemId));
    },
    notifierType: 'none',
  });

  const columns = useMemo(
    (): DataGridColumnDefinition<UserWallet>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.user'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.user?.name}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.address'),
        valueGetter: (item) => (
          <Fragment>
            <div className="tw-uppercase">{item.network}</div>
            <CopyText text={item.address} />
          </Fragment>
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('fields.max_auto_withdrawal_amount')}
            tooltip={t('fields.max_auto_withdrawal_amount_description')}
          />
        ),
        valueGetter: (item) => (
          <Money
            value={item.maxAutoWithdrawalAmount}
            assetCurrencyId={getDefaultAssetCurrency()?.id}
            symbol
          />
        ),
      },
      {
        header: t('fields.creator'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.creator?.name}</div>
          </Fragment>
        ),
      },
      {
        header: t('fields.resources'),
        valueGetter: (item) => {
          if (resourcesLoading.includes(item.id)) {
            return <CircularProgress size={20} />;
          }

          return (
            <WalletResources
              resources={resources[item.id]}
              disabled={!isEmpty(resourcesLoading)}
              getResources={() => getResources(item.id)}
            />
          );
        },
      },
    ],
    [t, getDefaultAssetCurrency, resourcesLoading, resources, getResources],
  );

  const removeMutation = useMutation<UserWallet, AxiosError, string>(
    userWalletsApi.removeAsRole(role),
    {
      onSuccess: () => {
        queryResult.refetch();
      },
      notifierType: 'remove',
    },
  );

  const filtersDefinitions: FilterDefinition<UserWalletsFilters>[] = useMemo(
    () => [
      {
        label: t('fields.user'),
        name: 'userId',
        type: FilterDefinitionType.User,
        users: queryResultExternalWalletHolders.data,
        getDisplayName: (userId: string) =>
          find(queryResultExternalWalletHolders.data, { id: userId })?.name,
      },
      {
        label: t('fields.address'),
        name: 'address',
        type: FilterDefinitionType.Text,
      },
    ],
    [queryResultExternalWalletHolders.data, t],
  );

  const renderWalletDetailsDialog = useCallback(
    (props: DialogProps) => (
      <UserWalletDetailsDialog
        {...props}
        externalWalletHolders={queryResultExternalWalletHolders.data}
      />
    ),
    [queryResultExternalWalletHolders.data],
  );

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filtersDefinitions} />
      <CrudTable
        columns={columns}
        queryResult={queryResult}
        paginated
        actions={[
          {
            type: CrudTableActionType.Details,
            renderDialog: renderWalletDetailsDialog,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item: UserWallet, { close }) =>
              removeMutation.mutate(item.id, { onSuccess: close }),
          },
        ]}
      />
    </Fragment>
  );
};
