import { map } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataGridColumnDefinition } from 'components';
import {
  AppConfigArea,
  AppConfigKey,
  FilterDefinitionType,
  P2PProviderType,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigFilters, FilterDefinition } from 'types';
import { appConfigUtils, p2pProviderUtils } from 'utils';

import { ConfigsPageTab } from '../ConfigsPageTab';

export const PayoutP2PProvidersTab: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const additionalColumns = useMemo(
    (): DataGridColumnDefinition<AppConfig>[] => [
      {
        header: t('fields.provider'),
        valueKey: 'p2pProviderType',
        valueFormatter: p2pProviderUtils.getTypeLabel,
      },
    ],
    [t],
  );

  const p2pProviderTypeOptions = useMemo(
    () =>
      map(P2PProviderType, (type) => ({
        label: p2pProviderUtils.getTypeLabel(type),
        value: type,
      })),
    [],
  );

  const additionalFilters: FilterDefinition<AppConfigFilters>[] = useMemo(
    () => [
      {
        label: t('fields.provider'),
        name: 'p2pProviderType',
        type: FilterDefinitionType.Select,
        options: p2pProviderTypeOptions,
        getDisplayName: p2pProviderUtils.getTypeLabel,
      },
      {
        label: t('fields.key'),
        name: 'key',
        type: FilterDefinitionType.Enum,
        enum: [AppConfigKey.BanksMap, AppConfigKey.PaymentTypeMap],
        getDisplayName: appConfigUtils.getKeyLabel,
      },
    ],
    [p2pProviderTypeOptions, t],
  );

  return (
    <ConfigsPageTab
      area={AppConfigArea.PayoutP2PProvider}
      additionalColumns={additionalColumns}
      additionalFilters={additionalFilters}
    />
  );
};
