import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface Props {
  tooltip: string;
}

export const InfoTooltip: React.FC<Props> = ({ tooltip }) => (
  <Tooltip title={tooltip}>
    <InfoOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="primary" />
  </Tooltip>
);
