import React from 'react';

import { Timer, PayoutOrderStatusLabel } from 'components';
import { HIDE_PAYOUT_TIMER_IN_STATUSES } from 'constants/order.constants';
import { PayoutOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderStatusColumn: React.FC<Props> = ({ order }) => (
  <div>
    {!HIDE_PAYOUT_TIMER_IN_STATUSES.includes(order.status) && (
      <Timer hideTimeOver endTime={orderUtils.getMaxTimeoutAt(order)} />
    )}
    <div>
      <PayoutOrderStatusLabel status={order.status} />
    </div>
  </div>
);
