import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { Field, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { TextField } from 'formik-mui';
import { useCallback, useState, useEffect, useMemo } from 'react';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { authApi, authTwoFAApi } from 'api';
import { TWO_FA_CODE_LENGTH } from 'constants/auth.constants';
import { DEFAULT_ROLE_PATH } from 'constants/routes';
import { StatusCode } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AuthData, authUtils } from 'utils';

enum View {
  Password,
  TwoFA,
}

type Values = {
  email: string;
  password?: string;
};

export function LoginPage() {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'pages.login',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const navigate = useNavigate();
  const location = useLocation();
  const email = useMemo(() => location.state?.email, [location]);

  const [view, setView] = useState(View.Password);
  const [twoFASubmitted, setTwoFASubmitted] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [initialValues] = useState<Values>({
    email,
    password: undefined,
  });

  const navigateToDefault = useCallback(() => {
    navigate(DEFAULT_ROLE_PATH[authUtils.getUserRole()]);
  }, [navigate]);

  const { mutate: login } = useMutation(authApi.login, {
    onSuccess: (authData: AuthData) => {
      if (authUtils.getTwoFAVerified(authData.accessToken)) {
        authUtils.setAuthData(authData);
        navigateToDefault();
      } else {
        setView(View.TwoFA);
      }
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
    onError: (error: AxiosError<any>) => {
      const status = error?.response?.status;
      if (
        status === StatusCode.Unauthorized &&
        !authUtils.isAccountDisabledError(error)
      ) {
        formik.setFieldError('email', t('form.field_error'));
        formik.setFieldError('password', t('form.field_error'));
      }
    },
    notifierMessages: {
      error: (error: AxiosError<any>) => {
        const status = error?.response?.status;
        if (authUtils.isAccountDisabledError(error)) {
          return t('form.error.account_disabled');
        } else if (status === StatusCode.Unauthorized) {
          return t('form.error.invalid_credentials');
        }
        return t('form.error.unexpected');
      },
    },
  });

  const { mutate: twoFAAuthenticate, isLoading: twoFALoading } = useMutation(
    authTwoFAApi.authenticate,
    {
      onSuccess: (authData: AuthData) => {
        authUtils.setAuthData(authData);
        navigateToDefault();
      },
      notifierMessages: {
        error: t('form.error.invalid_credentials'),
      },
    },
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (!values.password) {
        return;
      }
      const credentials = {
        email: values.email,
        password: values.password,
      };
      formikHelpers.setSubmitting(true);
      login(credentials);
    },
    [login],
  );

  const cancelAuthCodeInput = useCallback(() => {
    authUtils.resetAuthData();
    setView(View.Password);
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (authCode.length === TWO_FA_CODE_LENGTH && !twoFASubmitted) {
      setTwoFASubmitted(true);
      twoFAAuthenticate(authCode);
    }
    if (authCode.length !== TWO_FA_CODE_LENGTH && twoFASubmitted) {
      setTwoFASubmitted(false);
    }
  }, [authCode, twoFAAuthenticate, twoFASubmitted]);

  // TODO: markup is taken from examples. Needs to be revisited
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('title')}
        </Typography>
        {view === View.Password && (
          <FormikProvider value={formik}>
            <Form className="tw-grid tw-gap-4">
              <Box>
                <Field
                  id="email"
                  margin="normal"
                  fullWidth
                  required
                  defaultValue={email}
                  component={TextField}
                  label={t('form.email')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Field
                  id="password"
                  margin="normal"
                  fullWidth
                  required
                  component={TextField}
                  label={t('form.password')}
                  name="password"
                  autoComplete="current-password"
                  type="password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={formik.isSubmitting}
                >
                  {t('form.submit_btn')}
                </Button>
              </Box>
            </Form>
          </FormikProvider>
        )}
        {view === View.TwoFA && (
          <div className="tw-mt-8">
            <div className="tw-text-center tw-mb-4">{t('code_2fa')}</div>
            <AuthCode
              containerClassName="tw-px-4 tw-text-center"
              inputClassName="tw-w-10 tw-h-10 tw-m-1 tw-border tw-border-2 tw-text-center"
              allowedCharacters="numeric"
              autoFocus
              onChange={setAuthCode}
              disabled={twoFALoading}
            />
            <Button
              fullWidth
              variant="outlined"
              color="error"
              sx={{ mt: 3, mb: 2 }}
              onClick={cancelAuthCodeInput}
              disabled={twoFALoading}
            >
              {tCommon('buttons.cancel')}
            </Button>
          </div>
        )}
      </Box>
    </Container>
  );
}
