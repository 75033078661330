import { Shield as ShieldIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesVerificationStatus } from 'enums';
import { useMeta } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Requisites } from 'types';
import { formatUtils } from 'utils';

type Props = {
  requisites: Requisites;
};

export const RequisitesVerificationInfo: React.FC<Props> = ({ requisites }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites',
  });
  const { payinRequisitesVerificationExpirationAfter } = useMeta();

  const verificationExpirationDate = useMemo(
    () =>
      requisites.verifiedAt
        ? moment(requisites.verifiedAt)
            .add(payinRequisitesVerificationExpirationAfter, 'hours')
            .toDate()
        : null,
    [requisites, payinRequisitesVerificationExpirationAfter],
  );

  const statusLabel = useMemo(
    () =>
      t(
        `verification_status.${
          requisites.verificationStatus || RequisitesVerificationStatus.Expired
        }`,
      ),
    [requisites, t],
  );

  const lastVerificationLabel = useMemo(
    () =>
      requisites.verifiedAt
        ? `${t('verification.last_verification_at')} ${formatUtils.formatDate(
            requisites.verifiedAt,
          )}`
        : null,
    [requisites, t],
  );

  const verificationExpirationLabel = useMemo(
    () =>
      verificationExpirationDate
        ? `${t(
            'verification.verification_expiration_at',
          )} ${formatUtils.formatDate(verificationExpirationDate)}`
        : null,
    [verificationExpirationDate, t],
  );

  const iconColor = useMemo(() => {
    switch (requisites.verificationStatus) {
      case RequisitesVerificationStatus.Success:
        return 'success';
      case RequisitesVerificationStatus.Expired:
        return 'error';
      case RequisitesVerificationStatus.Disabled:
        return 'primary';
      case RequisitesVerificationStatus.Active:
        return 'info';
      default:
        return 'error';
    }
  }, [requisites]);

  return (
    <Tooltip
      title={
        <div>
          <div>{statusLabel}</div>
          {requisites.verifiedAt &&
            requisites.verificationStatus !==
              RequisitesVerificationStatus.Disabled && (
              <Fragment>
                <div className="tw-mt-2">{lastVerificationLabel}</div>
                <div>{verificationExpirationLabel}</div>
              </Fragment>
            )}
        </div>
      }
    >
      <ShieldIcon color={iconColor} />
    </Tooltip>
  );
};
