import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PublicRoute } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import {
  AcceptInvitePage,
  LoginPage,
  SessionExpiredPage,
  AccountDisabledPage,
} from 'pages';
import {
  AdminRoutes,
  MerchantRoutes,
  OperatorRoutes,
  TechOperatorRoutes,
  TraderRoutes,
} from 'role-routes';

export const AppRoutes: React.FC = () => (
  <Routes>
    {AdminRoutes()}
    {OperatorRoutes()}
    {TechOperatorRoutes()}
    {TraderRoutes()}
    {MerchantRoutes()}

    <Route element={<PublicRoute />}>
      <Route path={ROUTE_PATH.PUBLIC.LOGIN} element={<LoginPage />} />
      <Route
        path={ROUTE_PATH.PUBLIC.ACCEPT_INVITE}
        element={<AcceptInvitePage />}
      />
      <Route
        path={ROUTE_PATH.PUBLIC.SESSION_EXPIRED}
        element={<SessionExpiredPage />}
      />
      <Route
        path={ROUTE_PATH.PUBLIC.ACCOUNT_DISABLED}
        element={<AccountDisabledPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PUBLIC.LOGIN} />} />
    </Route>
    <Route
      path="*"
      element={<Navigate to={ROUTE_PATH.PUBLIC.LOGIN} replace />}
    />
  </Routes>
);
