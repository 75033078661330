import { find, map } from 'lodash';
import { useCallback, useMemo } from 'react';

import { formUtils, requisitesUtils, tradeMethodsUtils } from 'utils';

import { useUserContext } from './use-user-context.hook';

export function useUserContextUtils() {
  const { banks, paymentTypes, fiatCurrencies, tradeMethods } =
    useUserContext();

  const getTradeMethodLabel = useCallback(
    (tradeMethodId: string) =>
      tradeMethodsUtils.getTradeMethodLabel({
        tradeMethodId,
        tradeMethods,
        banks,
        paymentTypes,
        fiatCurrencies,
      }),
    [banks, fiatCurrencies, paymentTypes, tradeMethods],
  );

  const getTradeMethodsOptions = useCallback(
    (tradeMethodsForOptions = tradeMethods) =>
      formUtils.getOptions(
        map(tradeMethodsForOptions, ({ id }) => ({
          id,
          name: getTradeMethodLabel(id),
        })),
      ),
    [getTradeMethodLabel, tradeMethods],
  );

  const tradeMethodsOptions = useMemo(
    () => getTradeMethodsOptions(),
    [getTradeMethodsOptions],
  );

  const getBankName = useCallback(
    ({ id, code }: { id?: string; code?: string }) =>
      (find(banks, { id }) || find(banks, { code }))?.name || code || id,
    [banks],
  );

  const getBankId = useCallback(
    ({ code }: { code?: string }) => find(banks, { code })?.id,
    [banks],
  );

  const getPaymentTypeLabel = useCallback(
    ({ id, code }: { id?: string; code?: string }) => {
      const paymentType =
        find(paymentTypes, { id }) || find(paymentTypes, { code });
      if (!paymentType) {
        return code || id || '';
      }
      return requisitesUtils.getPaymentTypeLabel(paymentType);
    },
    [paymentTypes],
  );

  const getPaymentTypesOptions = useCallback(
    (paymentTypesForOptions = paymentTypes) =>
      requisitesUtils.getPaymentTypesOptions(paymentTypesForOptions),
    [paymentTypes],
  );

  const paymentTypesOptions = useMemo(
    () => getPaymentTypesOptions(),
    [getPaymentTypesOptions],
  );

  return {
    getTradeMethodLabel,
    getTradeMethodsOptions,
    tradeMethodsOptions,
    getBankName,
    getPaymentTypeLabel,
    getPaymentTypesOptions,
    paymentTypesOptions,
    getBankId,
  };
}
