import { Typography } from '@mui/material';
import { map } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { paymentLimitsApi } from 'api';
import {
  CloseDialogHandler,
  DataWrapper,
  Dialog,
  StatisticalInfo,
} from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { PayinRequisiteLimits, Requisites } from 'types';

type Props = {
  open: boolean;
  requisites?: Requisites;
  onClose: CloseDialogHandler;
};

const BankLimitsInfo = ({ limits }: { limits: PayinRequisiteLimits }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.requisite_limits.show_limits.dialog',
  });
  return (
    <div className="tw-mb-4">
      <div className="tw-grid tw-gap-1">
        <Typography variant="h6" sx={{ mb: 2 }}>
          {limits.customerBank?.name}
        </Typography>
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payinLimitCountPerDay!}
          used={limits?.limitCountPerDay}
          label={t('limits_count_per_day')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payinLimitCountPerMonth!}
          used={limits?.limitCountPerMonth}
          label={t('limits_count_per_month')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payinLimitSumPerDay!}
          used={limits?.limitSumPerDay}
          label={t('limits_sum_per_day')}
        />
        <StatisticalInfo
          total={limits.defaultPaymentLimits?.payinLimitSumPerMonth!}
          used={limits?.limitSumPerMonth}
          label={t('limits_sum_per_month')}
        />
      </div>
    </div>
  );
};

export const PayinLimitsInfoDialog: React.FC<Props> = ({
  open,
  requisites,
  onClose,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.requisite_limits.show_limits.dialog',
  });

  const queryKey = useMemo(
    () => [QueryKey.PayinRequisitesLimits, requisites?.id],
    [requisites],
  );
  const queryResult = useQuery(
    queryKey,
    paymentLimitsApi.getPayinRequisitesLimits(requisites?.id),
    { enabled: !!requisites },
  );

  return (
    <Dialog open={open} title={t('title')} onClose={onClose} modal>
      <DataWrapper queryResult={queryResult}>
        <Fragment>
          {map(queryResult?.data, (limits) => (
            <BankLimitsInfo key={limits.id} limits={limits} />
          ))}
        </Fragment>
      </DataWrapper>
    </Dialog>
  );
};
