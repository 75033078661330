import { Button } from '@mui/material';
import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQueryClient } from 'react-query';

import { internalTransferApi } from 'api';
import {
  CloseDialogResult,
  DataGridColumnDefinition,
  Dialog,
} from 'components';
import { FilterDefinitionType, InternalTransferTab, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  InternalTransfer,
  InternalTransferFilters,
  Shop,
  User,
} from 'types';

import { InternalTransfers } from '../InternalTransfers';

type Props = {
  queryResultShops?: UseQueryResult<Shop[]>;
  queryResultUsers?: UseQueryResult<User[]>;
};

export const ActiveInternalTransfers: React.FC<Props> = ({
  queryResultShops,
  queryResultUsers,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { role, isAdmin, isTechOperator, isManager } = useUser();
  const queryClient = useQueryClient();

  const queryResult = usePartialQuery(
    QueryKey.InternalTransfers,
    internalTransferApi.getAllPaginatedByRole(role, InternalTransferTab.Active),
  );

  const [confirmCancelModalProps, setConfirmCancelModalProps] = useState<{
    open: boolean;
    data?: InternalTransfer;
  }>({ open: false });

  const additionalFilters: FilterDefinition<InternalTransferFilters>[] =
    useMemo(
      () => [
        {
          label: t('fields.sender'),
          name: 'senderUserId',
          type: FilterDefinitionType.User,
          users: queryResultUsers?.data,
          getDisplayName: (senderUserId: string) =>
            find(queryResultUsers?.data, { id: senderUserId })?.name,
          hidden: !isManager,
        },
      ],
      [t, queryResultUsers?.data, isManager],
    );

  const additionalColumns: DataGridColumnDefinition<InternalTransfer>[] =
    useMemo(
      () => [
        {
          header: t('fields.actions'),
          hidden: isTechOperator,
          valueGetter: (item) => (
            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() =>
                  setConfirmCancelModalProps({ open: true, data: item })
                }
              >
                {t('fields.revoke')}
              </Button>
            </div>
          ),
        },
      ],
      [isTechOperator, t],
    );

  const { mutate: cancel } = useMutation(
    isAdmin ? internalTransferApi.cancelAdmin : internalTransferApi.cancel,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.InternalTransfers);
        queryClient.invalidateQueries(QueryKey.MyAssets);
        queryClient.invalidateQueries(QueryKey.MerchantsAssets);
        setConfirmCancelModalProps({ open: false });
      },
    },
  );

  const handleCancel = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok && result.data) {
        cancel({ id: result.data?.id });
      } else {
        setConfirmCancelModalProps({ open: false });
      }
    },
    [cancel],
  );

  return (
    <Fragment>
      <InternalTransfers
        queryResult={queryResult}
        queryResultShops={queryResultShops}
        additionalFilters={additionalFilters}
        additionalColumns={additionalColumns}
      />
      <Dialog
        {...confirmCancelModalProps}
        onClose={handleCancel}
        title={t('cancel_modal.title')}
      />
    </Fragment>
  );
};
