import { Stack } from '@mui/material';
import { findIndex, sortBy } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { assetsApi } from 'api';
import { AssetExchangeDialog, AssetsTable, TotalBalance } from 'components';
import { AssetsTabs, QueryKey } from 'enums';
import { PlatformFundsRequestDialog } from 'features/funds-request';
import { useCalculateAssetBalances, useCurrencies } from 'hooks';
import { Asset } from 'types';

export const PlatformBalance: React.FC = () => {
  const { assetCurrencies } = useCurrencies();

  const queryClient = useQueryClient();
  const queryResult: UseQueryResult<Asset[]> = useQuery(
    QueryKey.PlatformAssets,
    assetsApi.getAllPlatform,
    {
      select: (assets) =>
        sortBy(assets, (asset) =>
          findIndex(assetCurrencies, { id: asset.assetCurrencyId }),
        ),
    },
  );

  const { balances } = useCalculateAssetBalances(queryResult.data);

  const [fundsRequestDialogProps, setFundsRequestDialogProps] = useState<{
    open: boolean;
    asset?: Asset;
  }>({ open: false });

  const [exchangeDialogProps, setExchangeDialogProps] = useState<{
    open: boolean;
    data?: Asset;
  }>({ open: false });

  const handleFundsRequestDialogClose = useCallback(() => {
    setFundsRequestDialogProps({
      open: false,
    });
    queryClient.invalidateQueries(QueryKey.PlatformAssets);
  }, [queryClient]);

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />

      <AssetsTable
        tab={AssetsTabs.PlatformBalance}
        queryResult={queryResult}
        onFundsRequest={(asset) =>
          setFundsRequestDialogProps({
            open: true,
            asset,
          })
        }
        onExchange={(asset) =>
          setExchangeDialogProps({
            open: true,
            data: asset,
          })
        }
      />

      <PlatformFundsRequestDialog
        {...fundsRequestDialogProps}
        onClose={handleFundsRequestDialogClose}
      />

      <AssetExchangeDialog
        {...exchangeDialogProps}
        isPlatform
        onClose={() => setExchangeDialogProps({ open: false })}
      />
    </Stack>
  );
};
