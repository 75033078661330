import { Dialog, DialogProps, QrCode } from 'components';

type Props = DialogProps & {
  data?: any;
};

export const RequisitesQrCodeDialog: React.FC<Props> = ({ data, ...rest }) => (
  <Dialog {...rest}>
    <QrCode data={data} />
  </Dialog>
);
