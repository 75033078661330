import { useCallback } from 'react';

import { UnitPosition } from 'enums';
import { Currency } from 'types';
import { formatUtils } from 'utils';

import { useCurrencies } from './use-currencies.hook';

type Options = {
  value: number;
  symbol?: boolean;
  code?: boolean;
  assetCurrencyId?: string | null;
  fiatCurrencyId?: string | null;
};

export function useMoney() {
  const {
    getAssetCurrency,
    getFiatCurrency,
    getAssetCurrencyCode,
    getAssetCurrencySymbol,
    getFiatCurrencyCode,
    getFiatCurrencySymbol,
  } = useCurrencies();

  const getAssetUnit = useCallback(
    ({ assetCurrencyId, symbol, code }: Options) => {
      if (assetCurrencyId) {
        if (symbol) {
          return getAssetCurrencySymbol(assetCurrencyId);
        } else if (code) {
          return getAssetCurrencyCode(assetCurrencyId);
        }
      }
    },
    [getAssetCurrencyCode, getAssetCurrencySymbol],
  );

  const getFiatUnit = useCallback(
    ({ fiatCurrencyId, symbol, code }: Options) => {
      if (fiatCurrencyId) {
        if (symbol) {
          return getFiatCurrencySymbol(fiatCurrencyId);
        } else if (code) {
          return getFiatCurrencyCode(fiatCurrencyId);
        }
      }
    },
    [getFiatCurrencyCode, getFiatCurrencySymbol],
  );

  const getUnit = useCallback(
    (options: Options) => {
      const assetUnit = getAssetUnit(options);
      const fiatUnit = getFiatUnit(options);

      return assetUnit && fiatUnit
        ? `${assetUnit}/${fiatUnit}`
        : assetUnit || fiatUnit;
    },
    [getAssetUnit, getFiatUnit],
  );

  const formatValue = useCallback(
    (value: number) => formatUtils.formatMoney(value),
    [],
  );

  const getUnitPosition = useCallback(
    (data: { assetCurrency?: Currency; fiatCurrency?: Currency }) =>
      data?.assetCurrency?.symbolPosition || data?.fiatCurrency?.symbolPosition,
    [],
  );

  const getFormattedMoney = useCallback(
    (options: Options) => {
      const assetCurrency = getAssetCurrency(options.assetCurrencyId!);
      const fiatCurrency = getFiatCurrency(options.fiatCurrencyId!);
      const unitPosition = getUnitPosition({ assetCurrency, fiatCurrency });
      const formattedValue = formatValue(options.value);
      const unit = getUnit(options);
      if (unitPosition === UnitPosition.Before) {
        return (unit ? `${unit} ` : '') + formattedValue;
      } else if (unitPosition === UnitPosition.After) {
        return formattedValue + (unit ? ` ${unit}` : '');
      }
      return formattedValue;
    },
    [getAssetCurrency, getFiatCurrency, getUnitPosition, formatValue, getUnit],
  );

  return {
    getFormattedMoney,
  };
}
