import { Route, Navigate } from 'react-router-dom';

import { OperatorRoute, PayRoutes } from 'components';
import { ROUTE_PATH as ROUTE_PATH_ALL } from 'constants/routes';
import { ManageOrderAutomationPage } from 'features/order-automation';
import { ManagePayoutOrderAutomationPage } from 'features/payout-order-automation';
import {
  OperatorSettingsPage,
  OperatorTradersPage,
  OperatorAssetsPage,
  OperatorArchivePage,
  OperatorRequisitesPage,
  OperatorPayinRequisitesPage,
  OperatorPayoutRequisitesPage,
  OperatorOrdersPage,
  OperatorPayinOrdersPage,
  OperatorPayoutOrdersPage,
  OperatorMainPage,
} from 'pages';

const ROUTE_PATH = ROUTE_PATH_ALL.OPERATOR;

export const OperatorRoutes = () => (
  <Route path={ROUTE_PATH.MAIN} element={<OperatorRoute />}>
    <Route path={ROUTE_PATH.SETTINGS} element={<OperatorSettingsPage />} />
    <Route path={ROUTE_PATH.TRADERS} element={<OperatorTradersPage />} />
    <Route
      path={`${ROUTE_PATH.ORDER_AUTOMATION}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.ORDER_AUTOMATION}
          payinElement={<ManageOrderAutomationPage />}
          payoutElement={<ManagePayoutOrderAutomationPage />}
        />
      }
    ></Route>
    <Route path={ROUTE_PATH.ASSETS} element={<OperatorAssetsPage />} />
    <Route path={ROUTE_PATH.ARCHIVE} element={<OperatorArchivePage />} />
    <Route path={ROUTE_PATH.REQUISITES} element={<OperatorRequisitesPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_REQUISITES}
        element={<OperatorPayinRequisitesPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_REQUISITES}
        element={<OperatorPayoutRequisitesPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_REQUISITES} />} />
    </Route>
    <Route
      path={ROUTE_PATH.ORDERS}
      element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />}
    />
    <Route path={ROUTE_PATH.ORDERS} element={<OperatorOrdersPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_ORDERS}
        element={<OperatorPayinOrdersPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_ORDERS}
        element={<OperatorPayoutOrdersPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />} />
    </Route>
    <Route path="" element={<OperatorMainPage />} />
  </Route>
);
