import { find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { paymentLimitsApi } from 'api';
import { requisitesGroupApi } from 'api/requisites-groups.api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DataGridColumnHeader,
  dataGridColumns,
  RequisitesInfo,
  StatisticalInfo,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  PayinRequisiteLimits,
  PayinRequisiteLimitsFilters,
  RequisitesFilters,
} from 'types';
import { formUtils, requisitesUtils } from 'utils';

export const RequisitesLimitsList: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.limits_list',
  });
  const { t: tRequisites } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation();

  const { role, isAdminOrTechOperator } = useUser();
  const canManage = useMemo(
    () => isAdminOrTechOperator,
    [isAdminOrTechOperator],
  );

  const { banks, paymentTypes } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResult = usePartialQuery(
    QueryKey.PayinRequisitesLimits,
    paymentLimitsApi.getAllPayinRequisitesLimitsPaginated,
  );

  const queryClient = useQueryClient();
  const { mutate: remove } = useMutation(
    paymentLimitsApi.removePayinRequisitesLimits,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.PayinRequisitesLimits);
      },
      notifierType: 'remove',
    },
  );

  const { data: groups = [] } = useQuery(
    QueryKey.RequisitesGroups,
    requisitesGroupApi.getAllAsRole(role),
  );

  const getGroup = useCallback((id?: string) => find(groups, { id }), [groups]);

  const columns = useMemo(
    (): DataGridColumnDefinition<PayinRequisiteLimits>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => <RequisitesInfo requisite={item.requisites} />,
        valueClassName: 'tw-min-w-[200px]',
      },
      {
        header: t('table.columns.group'),
        valueGetter: (item) => getGroup(item.requisites?.groupId)?.name,
        valueClassName: 'tw-w-[100px]',
      },
      {
        header: t('table.columns.customer_bank'),
        valueGetter: (item) => find(banks, { id: item.customerBankId })?.name,
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('table.columns.limit_count')}
            tooltip={t('table.columns.period')}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limitCountPerDay}
              total={item.defaultPaymentLimits?.payinLimitCountPerDay!}
            />
            <StatisticalInfo
              used={item.limitCountPerMonth}
              total={item.defaultPaymentLimits?.payinLimitCountPerMonth!}
            />
          </div>
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('table.columns.limit_sum')}
            tooltip={t(`table.columns.period`)}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limitSumPerDay}
              total={item.defaultPaymentLimits?.payinLimitSumPerDay!}
            />
            <StatisticalInfo
              used={item.limitSumPerMonth}
              total={item.defaultPaymentLimits?.payinLimitSumPerMonth!}
            />
          </div>
        ),
      },
    ],
    [t, getGroup, banks],
  );

  const filtersDefinitions: FilterDefinition<PayinRequisiteLimitsFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.requisites_id'),
          name: 'requisitesId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: tCommon('filters.bank'),
          name: 'bankId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(banks),
          getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
        },
        {
          label: tCommon('filters.group'),
          name: 'groupId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(groups),
          getDisplayName: (groupId) => getGroup(groupId)?.name,
        },
        {
          label: tRequisites('filters.payment_type'),
          name: 'paymentTypeId',
          type: FilterDefinitionType.Select,
          options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
          getDisplayName: (paymentType: string) =>
            requisitesUtils.getPaymentTypeLabel(
              find(paymentTypes, { id: paymentType })!,
            ),
        },
        {
          label: tRequisites('filters.fiat_currency'),
          name: 'fiatCurrencyId',
          type: FilterDefinitionType.Select,
          options: fiatCurrenciesOptions,
          getDisplayName: getFiatCurrencyCode,
        },
        ...requisitesUtils.getRequisitesFieldsFilters<RequisitesFilters>(),
      ],
      [
        t,
        banks,
        paymentTypes,
        getGroup,
        groups,
        tCommon,
        tRequisites,
        getFiatCurrencyCode,
        fiatCurrenciesOptions,
      ],
    );

  return (
    <CrudPage
      table={{
        queryResult,
        columns,
        paginated: true,
        hideActions: !canManage,
        actions: [
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ],
      }}
      filters={{ filtersDefinitions }}
    />
  );
};
