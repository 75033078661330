import { AxiosError } from 'axios';
import { findIndex } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { ordersApi } from 'api';
import {
  DataGrid,
  DataGridColumnDefinition,
  SidebarLayout,
  SidebarSize,
} from 'components';
import { QueryKey } from 'enums';
import { useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  PayinOrder,
  PayinOrderDispute,
  ResolvePayinOrderDispute,
  UpdateInternalStatusDetailsDto,
} from 'types';

import { OrderDetails } from '../OrderDetails';
import { P2PProviderStatus } from '../P2PProviderStatus';

type Props = {
  items: PayinOrder[];
  columns: DataGridColumnDefinition<PayinOrder>[];
  selectedOrder: PayinOrder | null;
  queryKey: string;
  handleOrderSelection: (orderId: string | null) => void;
};

export const OrderListWithAction: React.FC<Props> = ({
  items,
  columns,
  selectedOrder,
  queryKey,
  handleOrderSelection,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });
  const { role, isAdmin, isTechOperator, isOperator } = useUser();
  const isManager = useMemo(
    () => isAdmin || isTechOperator || isOperator,
    [isAdmin, isTechOperator, isOperator],
  );

  const queryClient = useQueryClient();

  const handleListUpdated = useCallback(
    (orderId: string | null) => {
      handleOrderSelection(orderId);
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(QueryKey.Notifications);
    },
    [handleOrderSelection, queryClient, queryKey],
  );

  const { mutate: confirmOrder, isLoading: confirmOrderLoading } = useMutation(
    ordersApi.confirm,
    {
      onSuccess: (data) => {
        handleListUpdated(data?.id);
      },
    },
  );

  const { mutate: openDispute } = useMutation(ordersApi.disputeAsRole(role), {
    onSuccess: (data) => {
      handleListUpdated(data?.id);
    },
  });

  const { mutate: cancelCompleted } = useMutation(
    ordersApi.cancelCompletedAsRole(role),
    {
      onSuccess: () => {
        handleListUpdated(null);
      },
    },
  );

  const { mutate: resolveDispute, error: resolveDisputeAsAdminError } =
    useMutation<
      PayinOrder,
      AxiosError,
      {
        id: string;
        data: ResolvePayinOrderDispute;
      }
    >(ordersApi.resolveDisputeAsRole(role), {
      onSuccess: () => handleListUpdated(null),
    });

  const { mutate: updateInternalStatusDetails } = useMutation<
    PayinOrder,
    AxiosError,
    {
      id: string;
      data: UpdateInternalStatusDetailsDto;
    }
  >(ordersApi.updateInternalStatusDetailsAsRole(role), {
    onSuccess: (data) => {
      handleListUpdated(data?.id);
    },
  });

  const handleOpenDispute = useCallback(
    (data: PayinOrderDispute) => {
      if (selectedOrder) {
        openDispute({ id: selectedOrder.id, data });
      }
    },
    [openDispute, selectedOrder],
  );

  const handleResolveDispute = useCallback(
    (data: ResolvePayinOrderDispute) => {
      if (selectedOrder) {
        resolveDispute({ id: selectedOrder.id, data });
      }
    },
    [resolveDispute, selectedOrder],
  );

  const handleUpdateInternalStatusDetails = useCallback(
    (data: UpdateInternalStatusDetailsDto) => {
      if (selectedOrder) {
        updateInternalStatusDetails({ id: selectedOrder.id, data });
      }
    },
    [updateInternalStatusDetails, selectedOrder],
  );

  const handleConfirm = useCallback(() => {
    if (selectedOrder) {
      confirmOrder(selectedOrder);
    }
  }, [confirmOrder, selectedOrder]);

  const handleCancelCompleted = useCallback(() => {
    if (selectedOrder) {
      cancelCompleted(selectedOrder.id);
    }
  }, [cancelCompleted, selectedOrder]);

  const handleClose = useCallback(() => {
    handleOrderSelection(null);
  }, [handleOrderSelection]);

  return (
    <SidebarLayout
      sidebarProps={{
        open: !!selectedOrder,
        title: (
          <Fragment>
            {t('details.title')}
            {isManager && selectedOrder && (
              <P2PProviderStatus order={selectedOrder} sx={{ ml: 1 }} />
            )}
          </Fragment>
        ),
        size: SidebarSize.md,
        onClose: handleClose,
        content: selectedOrder && (
          <OrderDetails
            order={selectedOrder}
            loading={confirmOrderLoading}
            resolveOrderDisputeAsAdminError={resolveDisputeAsAdminError}
            onCancelCompleted={handleCancelCompleted}
            onConfirm={handleConfirm}
            onOpenDispute={handleOpenDispute}
            onResolveDispute={handleResolveDispute}
            onUpdateInternalStatusDetails={handleUpdateInternalStatusDetails}
          />
        ),
      }}
    >
      <DataGrid
        columns={columns}
        data={items}
        onRowClick={(order) => handleOrderSelection(order?.id)}
        selectedRowIndex={findIndex(items, {
          id: selectedOrder?.id,
        })}
      />
    </SidebarLayout>
  );
};
