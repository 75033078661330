import React from 'react';

import { useUserContextUtils } from 'hooks';

type Props = {
  paymentTypeId?: string;
  paymentTypeCode?: string;
  className?: string;
};

export const PaymentTypeLabel: React.FC<Props> = ({
  paymentTypeId,
  paymentTypeCode,
  className,
}) => {
  const { getPaymentTypeLabel } = useUserContextUtils();

  return (
    <span className={className}>
      {getPaymentTypeLabel({ id: paymentTypeId, code: paymentTypeCode })}
    </span>
  );
};
