import { Divider, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { paymentLimitsApi } from 'api';
import {
  CloseDialogHandler,
  CloseFormikDialogResult,
  Dialog,
  FormControls,
  FormikNumericField,
  FormikSelect,
} from 'components';
import { QueryKey } from 'enums';
import { BanksHelperText } from 'features/common-limits/BanksHelperText';
import { useMutation, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PlatformPaymentLimits } from 'types';
import { formUtils, validationUtils } from 'utils';

type Props = {
  open: boolean;
  limits?: PlatformPaymentLimits;
  onClose: CloseDialogHandler;
};

type Values = Omit<PlatformPaymentLimits, 'id' | 'createdAt'>;

export const PlatformLimitsDialog: React.FC<Props> = ({
  open,
  limits,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const { banks } = useUserContext();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.platform_limits.create_dialog',
  });
  const { t: tUpdate } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.platform_limits.update_dialog',
  });
  const { t: tCommon } = useTranslation();

  const isNew = useMemo(() => !limits, [limits]);

  const initialValues = useMemo(
    () => ({
      requisitesBankId: limits?.requisitesBankId || '',
      customerBankId: limits?.customerBankId || '',
      payinLimitSumPerDay: limits?.payinLimitSumPerDay || 0,
      payinLimitSumPerMonth: limits?.payinLimitSumPerMonth || 0,
      payoutLimitSumPerDay: limits?.payoutLimitSumPerDay || 0,
      payoutLimitSumPerMonth: limits?.payoutLimitSumPerMonth || 0,
    }),
    [limits],
  );

  const validationSchema = Yup.object().shape({
    requisitesBankId: Yup.string().required(tCommon('errors.required')),
    customerBankId: Yup.string()
      .required(tCommon('errors.required'))
      .test(
        'notEqual',
        tCommon('features.common_limits.errors.same_banks'),
        function (customerBankId) {
          const ref = Yup.ref('requisitesBankId');
          return customerBankId !== this.resolve(ref);
        },
      ),
    payinLimitSumPerDay: Yup.number()
      .positive(tCommon('errors.natural_number'))
      .required(tCommon('errors.required')),
    payinLimitSumPerMonth: Yup.number()
      .positive(tCommon('errors.natural_number'))
      .required(tCommon('errors.required'))
      .test(
        'validLimits',
        tCommon('errors.more_than_or_equal', {
          field: t('fields.limit_sum_per_day'),
        }),
        function (payinLimitSumPerMonth) {
          const ref = Yup.ref('payinLimitSumPerDay');
          return payinLimitSumPerMonth >= (this.resolve(ref) as number);
        },
      ),
    payoutLimitSumPerDay: Yup.number()
      .positive(tCommon('errors.natural_number'))
      .required(tCommon('errors.required')),
    payoutLimitSumPerMonth: Yup.number()
      .positive(tCommon('errors.natural_number'))
      .required(tCommon('errors.required'))
      .test(
        'validLimits',
        tCommon('errors.more_than_or_equal', {
          field: t('fields.limit_sum_per_day'),
        }),
        function (payoutLimitSumPerMonth) {
          const ref = Yup.ref('payoutLimitSumPerDay');
          return payoutLimitSumPerMonth >= (this.resolve(ref) as number);
        },
      ),
  });

  const createMutation = useMutation<PlatformPaymentLimits, AxiosError, Values>(
    paymentLimitsApi.createDefaultPlatformLimits,
  );

  const updateMutation = useMutation<
    PlatformPaymentLimits,
    AxiosError,
    { id: string; data: Partial<PlatformPaymentLimits> }
  >(paymentLimitsApi.updateDefaultPlatformRequisitesLimits);

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.PlatformLimits);
          onClose({ ok: true });
          formikHelpers.resetForm();
        },
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      if (isNew) {
        createMutation.mutate(values, options);
      } else {
        updateMutation.mutate(
          {
            id: limits!.id,
            data: omit(values, 'requisitesBankId', 'customerBankId'),
          },
          options,
        );
      }
    },
    [queryClient, onClose, createMutation, updateMutation, isNew, limits],
  );

  const handleClose = useCallback(
    (result: CloseFormikDialogResult<Values>) => {
      if (!result.ok || !result.data) {
        result.data?.formikHelpers?.resetForm();
        onClose(result);
      } else {
        handleSubmit(result.data?.values, result.data?.formikHelpers);
      }
    },
    [handleSubmit, onClose],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={isNew ? t('title') : tUpdate('title')}
        data={{ values: formik.values, formikHelpers: formik }}
        mutation={createMutation}
        okDisabled={!formik.isValid}
        onClose={handleClose}
      >
        <FormControls>
          <Typography variant="subtitle1">{t('sections.banks')}</Typography>
          <FormikSelect
            label={t('fields.bank')}
            name="requisitesBankId"
            options={formUtils.getOptions(banks)}
            noneOption
            required
            disabled={!isNew}
          />
          <FormikSelect
            label={t('fields.bank')}
            name="customerBankId"
            options={formUtils.getOptions(banks)}
            noneOption
            required
            disabled={!isNew}
          />
        </FormControls>
        <Divider sx={{ margin: '24px 0 12px' }} />
        <FormControls>
          <Typography variant="subtitle1">{t('sections.payin')}</Typography>
          <BanksHelperText
            from={formik.values.customerBankId}
            to={formik.values.requisitesBankId}
          />
          <FormikNumericField
            label={t('fields.limit_sum_per_day')}
            name="payinLimitSumPerDay"
            allowNegative={false}
            required
          />
          <FormikNumericField
            label={t('fields.limit_sum_per_month')}
            name="payinLimitSumPerMonth"
            allowNegative={false}
            required
          />
        </FormControls>
        <Divider sx={{ margin: '24px 0 12px' }} />
        <FormControls>
          <Typography variant="subtitle1">{t('sections.payout')}</Typography>
          <BanksHelperText
            from={formik.values.requisitesBankId}
            to={formik.values.customerBankId}
          />
          <FormikNumericField
            label={t('fields.limit_sum_per_day')}
            name="payoutLimitSumPerDay"
            allowNegative={false}
            required
          />
          <FormikNumericField
            label={t('fields.limit_sum_per_month')}
            name="payoutLimitSumPerMonth"
            allowNegative={false}
            required
          />
        </FormControls>
      </Dialog>
    </FormikProvider>
  );
};
