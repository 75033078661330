import { ReactNode } from 'react';

import { QrCodeIcon, QrCodeIconProps } from 'components';

type WrapperProps = QrCodeIconProps & {
  children?: ReactNode;
};

export const RequisitesQrCodeWrapper: React.FC<WrapperProps> = ({
  children,
  sx = { mr: 2 },
  ...rest
}) => (
  <div className="tw-flex tw-items-center">
    <QrCodeIcon sx={sx} {...rest} />
    {children}
  </div>
);
