import {
  WarningAmber as WarningAmberIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { find, some } from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MutateOptions, useQuery, useQueryClient } from 'react-query';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { payoutRequisitesApi, tradersApi } from 'api';
import {
  DataGridColumnDefinition,
  dataGridColumns,
  CloseDialogResult,
  Dialog,
  RequisitesInfo,
  RequisitesStatusInfo,
  CrudPage,
} from 'components';
import { ACTIVE_REQUISITE_EXISTS_MESSAGE } from 'constants/requisites.constants';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey, RequisitesStatus } from 'enums';
import { UnblockRequisitesDialog } from 'features/requisites';
import {
  useCurrencies,
  useMutation,
  usePartialQuery,
  useUser,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, PayoutRequisites } from 'types';
import { formatUtils, formUtils, requisitesUtils } from 'utils';

type RequisitesFilters = Partial<{
  traderId: string;
  status: RequisitesStatus;
  bankId: string;
  paymentTypeId: string;
  fiatCurrencyId: string;
}>;

type Props = {
  title?: string;
  archived?: boolean;
};

export const PayoutRequisitesList: FC<Props> = ({ title = '', archived }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const queryClient = useQueryClient();

  const { role, isTrader, isManager, isAdminOrTechOperator } = useUser();
  const { tradeMethods, banks, paymentTypes } = useUserContext();

  const navigate = useNavigate();

  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const [confirmRemoveDialogProps, setConfirmRemoveDialogProps] = useState<{
    open: boolean;
    data?: PayoutRequisites;
  }>({ open: false });

  const [unblockDialogProps, setUnblockDialogProps] = useState<{
    open: boolean;
    requisites?: PayoutRequisites;
  }>({ open: false });

  const detailsPath = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.PAYOUT_REQUISITES_DETAILS;
    }
    return '';
  }, [isTrader]);

  const queryResult = usePartialQuery(
    QueryKey.PayoutRequisites,
    archived
      ? payoutRequisitesApi.getAllArchivePaginatedAsRole(role)
      : payoutRequisitesApi.getAllPaginatedAsRole(role),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: isManager,
    },
  );

  const { mutate: archive, isLoading } = useMutation(
    payoutRequisitesApi.archive,
    {
      onSuccess: () => {
        setConfirmRemoveDialogProps({ open: false });
        queryClient.invalidateQueries(QueryKey.PayoutRequisites);
      },
      notifierType: 'remove',
    },
  );

  const isActiveRequisitesExistsError = useCallback(
    (error: AxiosError<any>) =>
      error.response?.data?.message === ACTIVE_REQUISITE_EXISTS_MESSAGE,
    [],
  );

  const { mutate: updateStatus } = useMutation<
    PayoutRequisites,
    AxiosError,
    { id: string; data: Pick<PayoutRequisites, 'status'> }
  >(payoutRequisitesApi.updateStatus, {
    onError: (error: AxiosError) => {
      if (!isActiveRequisitesExistsError(error)) {
        queryClient.invalidateQueries(QueryKey.PayoutRequisites);
      }
    },
    notifierMessages: {
      error: (error: AxiosError) => {
        if (isActiveRequisitesExistsError(error)) {
          return t('messages.active_exists');
        }
      },
    },
  });

  const handleStatusChange = useCallback(
    (
      id: string,
      status: RequisitesStatus,
      options?: MutateOptions<any, any, any, any>,
    ) =>
      updateStatus(
        {
          id,
          data: { status },
        },
        options,
      ),
    [updateStatus],
  );

  const handleOpenDetails = useCallback(
    (requisitesId: string) => {
      const url = generatePath(detailsPath, {
        id: requisitesId,
      });
      navigate(url);
    },
    [detailsPath, navigate],
  );

  const handleRemove = useCallback((item: PayoutRequisites) => {
    setConfirmRemoveDialogProps({ open: true, data: item });
  }, []);

  const handleCloseRemoveDialog = useCallback(
    (result: CloseDialogResult<PayoutRequisites>) => {
      if (result.ok && result.data) {
        archive(result.data.id);
      } else {
        setConfirmRemoveDialogProps({ open: false });
      }
    },
    [archive],
  );

  const isTradeMethodSupported = useCallback(
    (requisites: PayoutRequisites) =>
      some(tradeMethods, {
        fiatCurrencyId: requisites.fiatCurrencyId,
        paymentTypeId: requisites.paymentTypeId,
      }),
    [tradeMethods],
  );

  const renderUnblockRequisitesAction = useCallback(
    (requisites: PayoutRequisites) =>
      isAdminOrTechOperator &&
      requisites.status === RequisitesStatus.Blocked && (
        <IconButton
          color="success"
          onClick={() => setUnblockDialogProps({ open: true, requisites })}
        >
          <CreditCardIcon />
        </IconButton>
      ),
    [isAdminOrTechOperator],
  );

  const handleCloseUnblockDialog = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok) {
        queryClient.invalidateQueries(QueryKey.PayoutRequisites);
      }
      setUnblockDialogProps({ open: false });
    },
    [queryClient],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutRequisites>[] => [
      {
        header: t('table.columns.status'),
        valueGetter: (item) => (
          <div>
            <RequisitesStatusInfo
              canChangeStatus={isTrader && !archived}
              requisites={item}
              isPayout
              updateStatus={handleStatusChange}
            />
            {!isTradeMethodSupported(item) && (
              <Tooltip
                title={tCommon(
                  'features.requisites.error.trade_method_unavailable',
                )}
              >
                <WarningAmberIcon color="warning" />
              </Tooltip>
            )}
          </div>
        ),
      },
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.trader_name'),
        valueGetter: (item) => item.user?.name,
        hidden: !isManager,
      },
      {
        header: t('table.columns.requisites'),
        valueGetter: (item) => (
          <RequisitesInfo
            paymentTypeId={item.paymentTypeId}
            bankId={item.bankId}
            fiatCurrencyId={item.fiatCurrencyId}
            canCopy
          />
        ),
      },
      {
        header: t('table.columns.transaction_sum'),
        valueGetter: (item) => (
          <Fragment>
            {!!item.minTransactionSum && (
              <span>{`${tCommon('common.from')} ${formatUtils.formatMoney(
                item.minTransactionSum,
              )} `}</span>
            )}
            {!!item.maxTransactionSum && (
              <span>{`${tCommon('common.to')} ${formatUtils.formatMoney(
                item.maxTransactionSum,
              )}`}</span>
            )}
          </Fragment>
        ),
      },
      {
        header: t('table.columns.sum'),
        valueFormatter: formatUtils.formatMoney,
        valueGetter: (item) => formatUtils.formatMoney(item.limits.limitSum),
      },
      {
        header: t('table.columns.count'),
        valueGetter: (item) => formatUtils.formatMoney(item.limits.limitCount),
      },
      {
        header: t('table.columns.any_bank'),
        valueGetter: (item) => formatUtils.formatBoolean(item.anyBank),
      },
      dataGridColumns.getActionsColumn({
        hidden: archived,
        children: (item) =>
          isAdminOrTechOperator ? (
            <Fragment>{renderUnblockRequisitesAction(item)}</Fragment>
          ) : null,
        handleRemove: isTrader ? handleRemove : undefined,
        handleEdit: isTrader
          ? (item: PayoutRequisites) => handleOpenDetails(item.id)
          : undefined,
      }),
    ],
    [
      t,
      isManager,
      archived,
      handleRemove,
      isTrader,
      handleStatusChange,
      isTradeMethodSupported,
      tCommon,
      isAdminOrTechOperator,
      renderUnblockRequisitesAction,
      handleOpenDetails,
    ],
  );

  const filtersDefinitions: FilterDefinition<RequisitesFilters>[] = useMemo(
    () => [
      {
        label: t('filters.trader'),
        name: 'traderId',
        type: FilterDefinitionType.Trader,
        traders: queryResultTraders.data,
        getDisplayName: (traderId: string) =>
          find(queryResultTraders.data, { id: traderId })?.user?.name,
        hidden: !isManager,
      },
      {
        label: t('filters.status'),
        name: 'status',
        type: FilterDefinitionType.Enum,
        enum: RequisitesStatus,
        getDisplayName: requisitesUtils.getStatusLabel,
      },
      {
        label: t('filters.bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (bankId: string) => find(banks, { id: bankId })?.name,
      },
      {
        label: t('filters.payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (paymentType: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: paymentType })!,
          ),
      },
      {
        label: t('filters.fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      isManager,
      paymentTypes,
      queryResultTraders.data,
      t,
    ],
  );

  return (
    <Fragment>
      <CrudPage
        filters={{ filtersDefinitions }}
        table={{ queryResult, columns, paginated: true }}
      />
      {!archived && (
        <Dialog
          title={t('remove_dialog.title')}
          disabled={isLoading}
          onClose={handleCloseRemoveDialog}
          {...confirmRemoveDialogProps}
        />
      )}

      {!archived && isAdminOrTechOperator && (
        <Fragment>
          <UnblockRequisitesDialog
            {...unblockDialogProps}
            isPayout
            onClose={handleCloseUnblockDialog}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
