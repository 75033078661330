import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShopList } from 'features/shop';
import { TranslationNamespace } from 'i18n';

export const AdminShopsPage: React.FC = () => {
  // TODO: move tkeys to common
  // TODO: remove title prop passed to child
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.shops',
  });
  return <ShopList title={t('title')} />;
};
