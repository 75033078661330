import { Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseDialogResult,
  CloseDialogHandler,
  Dialog,
  OperationTypeLabel,
  StylizedMoney,
  TronscanLink,
  FormikYesNoRadioGroup,
} from 'components';
import { FundsRequestCompletionType, OperationType } from 'enums';
import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FundsRequest } from 'types';

import { FundRequestCompletionData } from './FundRequestCompletionData';

type Props = {
  open: boolean;
  data?: FundRequestCompletionData;
  onClose: CloseDialogHandler<FundRequestCompletionData>;
};

type Values = {
  hash: string;
  sendTransaction: boolean;
};

export const FundsRequestCompletionDepositDialog: React.FC<Props> = (
  props: Props,
) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {});

  const { defaultAssetCurrency } = useCurrencies();

  const title = useMemo(
    () =>
      props.data?.completionType === FundsRequestCompletionType.Approve
        ? t('completion_dialog.approve.title')
        : t('completion_dialog.reject.title'),
    [t, props.data?.completionType],
  );

  //todo: check initial state rewrite
  const initialValues: Values = useMemo(
    () => ({
      hash: '',
      sendTransaction: false,
    }),
    [],
  );

  const canSendTronTransaction = useMemo(
    () => props.data?.item.asset?.assetCurrencyId === defaultAssetCurrency?.id,
    [defaultAssetCurrency?.id, props.data?.item.asset?.assetCurrencyId],
  );

  const renderFundRequestInfo = useCallback(
    (fundRequest: FundsRequest) => {
      if (!fundRequest) return null;

      return (
        <Fragment>
          <div>
            {t('funds_requests.fields.operation_type')}
            {': '}
            <OperationTypeLabel fundsRequest={fundRequest} />
          </div>
          <div>
            {t('funds_requests.fields.amount')}
            {': '}
            <StylizedMoney
              variant="overline"
              value={fundRequest.amount}
              symbol
              assetCurrencyId={fundRequest.asset?.assetCurrencyId}
            />
          </div>
          {fundRequest.hash && (
            <div>
              {t('funds_requests.fields.hash')}
              {': '}
              <TronscanLink hash={fundRequest.hash} />
            </div>
          )}
        </Fragment>
      );
    },
    [t],
  );

  const handleSubmit = useCallback(
    (values: Values) => {
      props.onClose({
        ok: true,
        data: {
          ...(props.data as FundRequestCompletionData),
          hash: values.hash,
          sendTransaction: values.sendTransaction,
        },
      });
    },
    [props],
  );

  const handleClose = useCallback(
    ({ ok }: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (
        ok &&
        props.data?.completionType === FundsRequestCompletionType.Approve &&
        props.data?.item.operationType === OperationType.Withdrawal
      ) {
        handleSubmit(formik.values);
        return;
      }
      formik.resetForm();
      props.onClose({ ok, data: props.data });
    },
    [props, handleSubmit],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        sendTransaction: Yup.boolean().required(tCommon('errors.required')),
        hash: Yup.string().when('sendTransaction', {
          is: (sendTransaction: boolean) => !sendTransaction,
          then: (schema) => schema.required(tCommon('errors.required')),
        }),
      }),
    [tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          {...props}
          title={title}
          onClose={(data) => handleClose(data, formik)}
        >
          <Fragment>
            {props.data?.completionType ===
              FundsRequestCompletionType.Approve && (
              <Fragment>
                <div className="tw-mb-4">
                  {t('completion_dialog.approve.content')}
                </div>
                {renderFundRequestInfo(props.data?.item)}
                {canSendTronTransaction &&
                  props.data?.item.operationType ===
                    OperationType.Withdrawal && (
                    <Form className="tw-flex tw-flex-col tw-mt-4">
                      <FormikYesNoRadioGroup
                        name="sendTransaction"
                        label={t('completion_dialog.approve.send_transaction')}
                      />
                      {formik.values.sendTransaction ? (
                        <Typography
                          variant="caption"
                          sx={{ fontStyle: 'italic' }}
                        >
                          {t(
                            'completion_dialog.approve.send_transaction_platform_wallet',
                          )}
                        </Typography>
                      ) : (
                        <Field
                          required
                          component={TextField}
                          name="hash"
                          label={t('funds_requests.fields.hash')}
                          variant="standard"
                          sx={{ mt: 0 }}
                        />
                      )}
                    </Form>
                  )}
              </Fragment>
            )}
            {props.data?.completionType ===
              FundsRequestCompletionType.Reject && (
              <Fragment>
                <div className="tw-mb-4">
                  {t('completion_dialog.reject.content')}
                </div>
                {renderFundRequestInfo(props.data?.item)}
              </Fragment>
            )}
          </Fragment>
        </Dialog>
      )}
    </Formik>
  );
};
