import { Stack } from '@mui/material';

import { assetsApi, shopsApi } from 'api';
import { AssetsTable, TotalBalance } from 'components';
import { AssetsTabs, QueryKey } from 'enums';
import {
  useCalculateAssetBalances,
  usePartialQuery,
  useShopsQuery,
  useUser,
  useUserContext,
} from 'hooks';

export const P2PProvidersBalance: React.FC = () => {
  const { role } = useUser();
  const { p2pProviders } = useUserContext();
  const queryResult = usePartialQuery(
    QueryKey.P2PProvidersBalances,
    assetsApi.getAllP2PProviders,
    { paginated: false },
  );

  const { balances } = useCalculateAssetBalances(queryResult.data);

  const shopsQueryResult = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />
      <AssetsTable
        tab={AssetsTabs.P2PProvidersBalance}
        shops={shopsQueryResult.data}
        p2pProviders={p2pProviders}
        queryResult={queryResult}
      />
    </Stack>
  );
};
