import { P2PProviderType } from 'enums';
import { P2PProviderDto } from 'types';

const P2P_PROVIDER_BASE_CONFIG_VALUE = {
  url: '',
  credentials: {
    apiKey: '',
    apiToken: '',
    signatureKey: '',
    password: '',
    apiSecret: '',
    apiPublic: '',
    apiPrivate: '',
    host2host: '',
  },
  enabled: false,
  pollingEnabled: false,
  responseTimeout: 0,
  expectedFee: 0,
  tradeMethods: [],
  assetCurrencyExchangeId: null,
};

const PAYIN_CONFIG_VALUE = {
  ...P2P_PROVIDER_BASE_CONFIG_VALUE,
};

const PAYOUT_CONFIG_VALUE = {
  ...P2P_PROVIDER_BASE_CONFIG_VALUE,
  orderTimeout: 0,
};

const PAYOUT_LIMITS_VALUE = {
  limitSum: 0,
  limitCount: 0,
};

export const P2P_PROVIDER_INITIAL_VALUE: P2PProviderDto = {
  name: '',
  type: P2PProviderType.InternalLike,
  withdrawalFee: 0,
  assetCurrencyId: '',
  fiatCurrencyId: '',
  currencyExchangeId: '',
  payinConfig: PAYIN_CONFIG_VALUE,
  payoutConfig: PAYOUT_CONFIG_VALUE,
  payoutLimits: PAYOUT_LIMITS_VALUE,
};
