import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import React, { FC } from 'react';

type Props = {
  title: string;
  tooltip?: string;
  className?: string;
};

export const DataGridColumnHeader: FC<Props> = ({
  title,
  tooltip,
  className,
}) => (
  <div className={cx('tw-flex tw-items-center', className)}>
    <div>{title}</div>
    {tooltip && (
      <Tooltip title={tooltip} classes={{ popper: 'tw-whitespace-pre-line' }}>
        <HelpIcon sx={{ ml: 1 }} fontSize="small" color="secondary" />
      </Tooltip>
    )}
  </div>
);
