import { AxiosError } from 'axios';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { banksApi } from 'api';
import {
  CloseFormikDialogData,
  CloseFormikDialogResult,
  CopyTextId,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  PageHeader,
} from 'components';
import { ERROR_MESSAGE } from 'constants/common.constants';
import { QueryKey, StatusCode } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Bank, BankDto } from 'types';
import { validationUtils } from 'utils';

import { BankDetailsDialog } from './BankDetailsDialog';

export const BanksPage: React.FC = () => {
  const queryClient = useQueryClient();
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.banks',
  });
  const { t: tCommon } = useTranslation();

  const [createBankDialogOpen, setCreateBankDialogOpen] = useState(false);

  const columns: DataGridColumnDefinition<Bank>[] = useMemo(
    () => [
      {
        header: t('fields.id'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.code'),
        valueKey: 'code',
      },
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
    ],
    [t],
  );
  const queryResult = useQuery(QueryKey.Banks, banksApi.getAll, {});

  const { mutate: remove } = useMutation(banksApi.remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.Banks);
    },
    notifierType: 'remove',
    notifierMessages: {
      error: (error: AxiosError<{ message: string | undefined }>) => {
        const status = error?.response?.status;
        if (
          status === StatusCode.Conflict &&
          error.response?.data?.message === ERROR_MESSAGE.ENTITY_IN_USE
        ) {
          return tCommon('errors.in_use');
        }
      },
    },
  });

  const { mutate: update } = useMutation<
    Bank,
    AxiosError,
    { id: string; data: Partial<Bank> }
  >(banksApi.update);
  const { mutate: create } = useMutation<Bank, AxiosError, BankDto>(
    banksApi.create,
  );

  const handleUpdate = useCallback(
    (
      item: Bank,
      data: CloseFormikDialogData<Partial<Bank>>,
      closeDialog: () => void,
    ) => {
      update(
        { id: item.id, data: data?.values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.Banks);
            closeDialog();
          },
          onError: (error: AxiosError) => {
            data?.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        },
      );
    },
    [queryClient, update],
  );

  const handleCreateClick = useCallback(() => {
    setCreateBankDialogOpen(true);
  }, []);

  const handleCreateBankDialogClose = useCallback(
    ({ data, ok }: CloseFormikDialogResult<BankDto>) => {
      if (ok && data) {
        return create(data.values, {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.Banks);
            data.formikHelpers.resetForm();
            setCreateBankDialogOpen(false);
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        });
      }
      setCreateBankDialogOpen(false);
    },
    [queryClient, create],
  );

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        rightContentButton={{
          onClick: handleCreateClick,
        }}
      />
      <CrudTable
        queryResult={queryResult}
        columns={columns}
        actions={[
          {
            type: CrudTableActionType.Details,
            renderDialog: BankDetailsDialog,
            onUpdate: handleUpdate,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, { onSuccess: close }),
          },
        ]}
      />
      <BankDetailsDialog
        open={createBankDialogOpen}
        onClose={handleCreateBankDialogClose}
      />
    </Fragment>
  );
};
