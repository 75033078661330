import { Chip } from '@mui/material';
import { isEmpty, isNumber, map, transform } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterDefinitionType } from 'enums';
import { FilterDefinition } from 'types';

type FilterChip = {
  label: string;
  value: any;
  name: string;
  removable?: boolean;
};

type Props = {
  definitions: FilterDefinition[];
  filters: any;
  onDelete: (name: string) => void;
};

export const FiltersChips: React.FC<Props> = ({
  definitions,
  filters,
  onDelete,
}) => {
  const { t } = useTranslation();

  const chips = useMemo(
    () =>
      transform(
        definitions,
        (acc, definition) => {
          if (definition.type === FilterDefinitionType.Range) {
            const minValue = filters?.[definition.minName] as number;
            const maxValue = filters?.[definition.maxName] as number;

            if (!isEmpty(minValue) || isNumber(minValue)) {
              acc.push({
                label: `${definition.label} ${t(
                  'filters.from',
                ).toLowerCase()}: ${minValue}`,
                value: minValue,
                name: definition.minName,
                removable: definition.removable !== false,
              });
            }
            if (!isEmpty(maxValue) || isNumber(maxValue)) {
              acc.push({
                label: `${definition.label} ${t(
                  'filters.to',
                ).toLowerCase()}: ${maxValue}`,
                value: maxValue,
                name: definition.maxName,
                removable: definition.removable !== false,
              });
            }
          } else {
            const value = filters?.[definition.name];

            if (!isEmpty(value) || isNumber(value)) {
              const displayName = definition.getDisplayName?.(value) || value;
              acc.push({
                label: `${definition.label}: ${displayName}`,
                value,
                name: definition.name,
                removable: definition.removable !== false,
              });
            }
          }
        },
        [] as FilterChip[],
      ),
    [definitions, filters, t],
  );
  return (
    <Fragment>
      {map(chips, (chip) => (
        <Chip
          sx={{
            marginRight: 2,
            marginBottom: 2,
            // added support for multiline chip: https://mui.com/material-ui/react-chip/#multiline-chip
            height: 'auto',
            minHeight: '32px',
            padding: '4px 0',
            wordBreak: 'break-all',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
          key={chip.label}
          label={chip.label}
          variant="outlined"
          onDelete={chip.removable ? () => onDelete(chip.name) : undefined}
        />
      ))}
    </Fragment>
  );
};
