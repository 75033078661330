import { Form, Formik, FormikHelpers } from 'formik';
import { assign, keys, map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikSelect,
  FormikTextField,
} from 'components';
import { SciTheme, SciView } from 'enums';
import { TranslationNamespace } from 'i18n';
import { SciDomainDto } from 'types';

type Values = SciDomainDto;

type Props = DialogProps<CloseFormikDialogResult<Values>>;

export const SciDomainsDialog: React.FC<Props> = ({
  open,
  data,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.sci_domains',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const title = useMemo(
    () =>
      data ? t('details_dialog.edit_title') : t('details_dialog.create_title'),
    [t, data],
  );

  const themesOptions = useMemo(
    () =>
      map(SciTheme, (theme) => ({
        value: theme,
        label: theme,
      })),
    [],
  );

  const viewsOptions = useMemo(
    () =>
      map(SciView, (view) => ({
        value: view,
        label: t(`views.${view}`, { defaultValue: view }),
      })),
    [t],
  );

  const getInitialValues = useCallback(
    (): Values => ({
      url: '',
      logo: '',
      loader: '',
      title: '',
      copyright: '',
      theme: '',
      view: '',
      telegramSupportBotUrl: '',
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        url: Yup.string().required(tCommon('errors.required')),
        logo: Yup.string(),
        loader: Yup.string(),
        title: Yup.string(),
        copyright: Yup.string(),
        theme: Yup.string().oneOf(Object.values(SciTheme)),
        view: Yup.string().oneOf(Object.values(SciView)),
        telegramSupportBotUrl: Yup.string(),
      }),
    [tCommon],
  );

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<Values>) => {
      if (!data.ok) {
        data.data?.formikHelpers?.resetForm();
      }
      onClose(data);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { formikHelpers, values } });
    },
    [handleClose],
  );

  useEffect(() => {
    const _initialValues = getInitialValues();
    setInitialValues(assign(_initialValues, pick(data, keys(_initialValues))));
  }, [data, getInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
          {...rest}
        >
          <Form>
            <FormControls>
              <FormikTextField label={t('fields.url')} name="url" required />
              <FormikTextField label={t('fields.logo')} name="logo" />
              <FormikTextField label={t('fields.loader')} name="loader" />
              <FormikTextField label={t('fields.title')} name="title" />
              <FormikTextField label={t('fields.copyright')} name="copyright" />
              <FormikSelect
                label={t('fields.theme')}
                name="theme"
                options={themesOptions}
                noneOption
              />
              <FormikSelect
                label={t('fields.view')}
                name="view"
                options={viewsOptions}
                noneOption
              />
              <FormikTextField
                label={t('fields.telegramSupportBotUrl')}
                name="telegramSupportBotUrl"
              />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
