import { Tab } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { PayoutOrderAutomationTab } from 'enums';
import { TranslationNamespace } from 'i18n';

import { PayoutOrderAutomationList } from '.';

export const ManagePayoutOrderAutomationPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.payout_order_automation',
  });

  return (
    <Fragment>
      <PageHeader title={t('title')} divider={false} />
      <QueryTabs tabsEnum={PayoutOrderAutomationTab}>
        <Tab value={PayoutOrderAutomationTab.Active} label={t('tabs.active')} />
        <Tab
          value={PayoutOrderAutomationTab.Completed}
          label={t('tabs.completed')}
        />
        <Tab
          value={PayoutOrderAutomationTab.Rejected}
          label={t('tabs.rejected')}
        />
        <Tab value={PayoutOrderAutomationTab.Error} label={t('tabs.error')} />
        <Tab
          value={PayoutOrderAutomationTab.Cancelled}
          label={t('tabs.cancelled')}
        />
        <Tab value={PayoutOrderAutomationTab.All} label={t('tabs.all')} />
      </QueryTabs>
      <QueryTabPanel value={PayoutOrderAutomationTab.Active}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.Active} />
      </QueryTabPanel>
      <QueryTabPanel value={PayoutOrderAutomationTab.Completed}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.Completed} />
      </QueryTabPanel>
      <QueryTabPanel value={PayoutOrderAutomationTab.Rejected}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.Rejected} />
      </QueryTabPanel>
      <QueryTabPanel value={PayoutOrderAutomationTab.Error}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.Error} />
      </QueryTabPanel>
      <QueryTabPanel value={PayoutOrderAutomationTab.Cancelled}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.Cancelled} />
      </QueryTabPanel>
      <QueryTabPanel value={PayoutOrderAutomationTab.All}>
        <PayoutOrderAutomationList tab={PayoutOrderAutomationTab.All} />
      </QueryTabPanel>
    </Fragment>
  );
};
