import { Link } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { payoutOrderAutomationRequestsApi } from 'api';
import { DescriptionRow } from 'components';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';

import { AutomationRequestsDialog } from './AutomationRequestsDialog';

type Props = {
  orderId: string;
};

export const AutomationRequests: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.automation_requests',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [dialogOpen, setDialogOpen] = useState(false);

  const queryResult = useQuery(
    QueryKey.PayoutOrderAutomationRequests,
    payoutOrderAutomationRequestsApi.getForOrder(orderId),
    {
      enabled: dialogOpen,
    },
  );

  return (
    <Fragment>
      <DescriptionRow
        title={t('title')}
        value={
          <div>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => setDialogOpen(true)}
            >
              {tCommon('buttons.open')}
            </Link>
          </div>
        }
      />
      <AutomationRequestsDialog
        queryResult={queryResult}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Fragment>
  );
};
