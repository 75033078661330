import { find } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { shopsApi } from 'api';
import { MERCHANT_ORDER_STATUSES } from 'constants/order.constants';
import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  FilterDefinitionType,
  OrderStatus,
  OrdersTab,
  QueryKey,
} from 'enums';
import { Orders } from 'features/orders';
import {
  useCurrencies,
  useQueryFilters,
  useShopsQuery,
  useUserContext,
} from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { formUtils, orderUtils, requisitesUtils } from 'utils';

export const MerchantAllOrders: React.FC = () => {
  const { t: tFilters } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.filters',
  });

  const { paymentTypes, banks } = useUserContext();
  const { getFiatCurrencyCode, fiatCurrenciesOptions } = useCurrencies();

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsMerchant,
  );

  useQueryFilters<OrderFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
    statuses: [OrderStatus.Completed, OrderStatus.Cancelled],
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      orderUtils.getStatusFilterDefinition(MERCHANT_ORDER_STATUSES),
      {
        label: tFilters('customer'),
        name: 'customerId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('shop'),
        name: 'shopId',
        type: FilterDefinitionType.Shop,
        shops: queryResultShops.data,
        getDisplayName: (shopId: string) =>
          find(queryResultShops.data, { id: shopId })?.name,
      },
      {
        label: tFilters('external_order_id'),
        name: 'externalOrderId',
        type: FilterDefinitionType.Text,
      },
      {
        label: tFilters('callback_url_status'),
        name: 'callbackUrlStatus',
        type: FilterDefinitionType.Enum,
        enum: CallbackUrlStatus,
        getDisplayName: orderUtils.getCallbackUrlStatusLabel,
      },
      {
        label: tFilters('payment_type'),
        name: 'paymentTypeId',
        type: FilterDefinitionType.Select,
        options: requisitesUtils.getPaymentTypesOptions(paymentTypes),
        getDisplayName: (value: string) =>
          requisitesUtils.getPaymentTypeLabel(
            find(paymentTypes, { id: value })!,
          ),
      },
      {
        label: tFilters('bank'),
        name: 'bankId',
        type: FilterDefinitionType.Select,
        options: formUtils.getOptions(banks),
        getDisplayName: (value: string) => find(banks, { id: value })?.name,
      },
      {
        label: tFilters('fiat_currency'),
        name: 'fiatCurrencyId',
        type: FilterDefinitionType.Select,
        options: fiatCurrenciesOptions,
        getDisplayName: getFiatCurrencyCode,
      },
      ...requisitesUtils.getRequisitesFieldsFilters<OrderFilters>(),
    ],
    [
      banks,
      fiatCurrenciesOptions,
      getFiatCurrencyCode,
      paymentTypes,
      queryResultShops.data,

      tFilters,
    ],
  );

  return (
    <Orders
      tab={OrdersTab.All}
      additionalFilters={additionalFilters}
      additionalColumns={[
        AdditionalOrderColumn.CustomerContacts,
        AdditionalOrderColumn.Shop,
        AdditionalOrderColumn.ExternalOrderId,
        AdditionalOrderColumn.CustomerPayment,
        AdditionalOrderColumn.StatusActive,
      ]}
    />
  );
};
