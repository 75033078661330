import axios from 'axios';

import { env } from 'config';
import { PayoutOrderAutomationTab } from 'enums';
import { PayoutOrderAutomation, PaginatedData, QueryParams } from 'types';
import { apiUtils } from 'utils';

import { createCrudApi } from './crud.api';

const path = '/payout-order-automation';
const url = `${env.apiUrl}${path}`;

const getPaginatedByStatus =
  (status: PayoutOrderAutomationTab) =>
  async (params?: QueryParams): Promise<PaginatedData<PayoutOrderAutomation>> =>
    (await apiUtils.getWithQueryParams(`${url}/${status}/paginated`, params))
      .data;

const cancelActiveAutomation = async (
  payoutOrderAutomationId: string,
): Promise<PayoutOrderAutomation> =>
  (await axios.post(`${url}/${payoutOrderAutomationId}/cancel`)).data;

export const payoutOrderAutomationApi = {
  ...createCrudApi<PayoutOrderAutomation>(path),
  getPaginatedByStatus,
  cancelActiveAutomation,
};
