import { NotificationEvent, NotificationEventGroup, UserRole } from 'enums';

export const NOTIFICATION_EVENTS_BY_ROLE: Partial<
  Record<UserRole, NotificationEvent[]>
> = {
  [UserRole.Admin]: [
    NotificationEvent.FundsRequestCreate,
    NotificationEvent.FundsRequestComplete,
    NotificationEvent.PayinOrderDispute,
    NotificationEvent.PayinOrderResolveDisputeComplete,
    NotificationEvent.PayinOrderResolveDisputeCancel,
    NotificationEvent.PayinOrderCreatedDispute,
    NotificationEvent.PayinOrderCancelCompleted,
    NotificationEvent.PayinOrderCancel,
    NotificationEvent.PayinOrderComplete,
    NotificationEvent.PayinDisableUserConsecutiveCancellations,
    NotificationEvent.PayinBlockTraderRequisites,
    NotificationEvent.PayinUnblockTraderRequisites,
    NotificationEvent.PayoutDisableUserConsecutiveDisputes,
    NotificationEvent.PayoutOrderCreatedDispute,
    NotificationEvent.PayoutOrderDispute,
    NotificationEvent.PayoutOrderCancel,
    NotificationEvent.PayoutOrderComplete,
    NotificationEvent.PayoutBlockTraderRequisites,
    NotificationEvent.PayoutUnblockTraderRequisites,
    NotificationEvent.AutomationGroupDisconnected,
    NotificationEvent.RequisitesTelegramAutomationUnauthorized,
  ],
  [UserRole.TechOperator]: [
    NotificationEvent.FundsRequestCreate,
    NotificationEvent.FundsRequestComplete,
    NotificationEvent.PayinOrderDispute,
    NotificationEvent.PayinOrderResolveDisputeComplete,
    NotificationEvent.PayinOrderResolveDisputeCancel,
    NotificationEvent.PayinOrderCreatedDispute,
    NotificationEvent.PayinOrderCancelCompleted,
    NotificationEvent.PayinOrderCancel,
    NotificationEvent.PayinOrderComplete,
    NotificationEvent.PayinDisableUserConsecutiveCancellations,
    NotificationEvent.PayinBlockTraderRequisites,
    NotificationEvent.PayinUnblockTraderRequisites,
    NotificationEvent.PayoutOrderCreatedDispute,
    NotificationEvent.PayoutOrderDispute,
    NotificationEvent.PayoutOrderCancel,
    NotificationEvent.PayoutOrderComplete,
    NotificationEvent.PayoutBlockTraderRequisites,
    NotificationEvent.PayoutUnblockTraderRequisites,
    NotificationEvent.PayoutDisableUserConsecutiveDisputes,
    NotificationEvent.AutomationGroupDisconnected,
    NotificationEvent.RequisitesTelegramAutomationUnauthorized,
  ],
  [UserRole.Merchant]: [
    NotificationEvent.FundsRequestCreate,
    NotificationEvent.FundsRequestComplete,
  ],
  [UserRole.Trader]: [
    NotificationEvent.FundsRequestCreate,
    NotificationEvent.FundsRequestComplete,
    NotificationEvent.PayinOrderCustomerConfirm,
    NotificationEvent.PayinOrderTraderConfirm,
    NotificationEvent.PayinOrderDispute,
    NotificationEvent.PayinOrderDisputeTraderConfirmTimeout,
    NotificationEvent.PayinOrderCancelledTraderConfirmTimeout,
    NotificationEvent.PayinOrderCompletedByAutomation,
    NotificationEvent.PayinOrderResolveDisputeComplete,
    NotificationEvent.PayinOrderResolveDisputeCancel,
    NotificationEvent.PayinOrderCancelCompleted,
    NotificationEvent.PayinOrderCreatedDispute,
    NotificationEvent.PayinOrderCancel,
    NotificationEvent.PayinOrderComplete,
    NotificationEvent.PayinBlockTraderRequisites,
    NotificationEvent.PayinUnblockTraderRequisites,
    NotificationEvent.PayoutOrderCreatedDispute,
    NotificationEvent.PayoutOrderTraderAccept,
    NotificationEvent.PayoutOrderDispute,
    NotificationEvent.PayoutOrderTraderAcceptTimeout,
    NotificationEvent.PayoutOrderDisputeTraderPaymentTimeout,
    NotificationEvent.PayoutOrderCancel,
    NotificationEvent.PayoutOrderComplete,
    NotificationEvent.PayoutBlockTraderRequisites,
    NotificationEvent.PayoutUnblockTraderRequisites,
    NotificationEvent.AutomationGroupDisconnected,
    NotificationEvent.PayinRequisitesVerificationExpired,
    NotificationEvent.RequisitesTelegramAutomationUnauthorized,
  ],
};

export const NOTIFICATION_EVENTS_GROUPS = [
  {
    name: NotificationEventGroup.FundsRequests,
    events: [
      NotificationEvent.FundsRequestCreate,
      NotificationEvent.FundsRequestComplete,
    ],
  },
  {
    name: NotificationEventGroup.PayinOrders,
    events: [
      NotificationEvent.PayinOrderCustomerConfirm,
      NotificationEvent.PayinOrderCancel,
      NotificationEvent.PayinOrderComplete,
      NotificationEvent.PayinOrderTraderConfirm,
      NotificationEvent.PayinOrderDispute,
      NotificationEvent.PayinOrderCreatedDispute,
      NotificationEvent.PayinOrderCancelCompleted,
      NotificationEvent.PayinOrderDisputeTraderConfirmTimeout,
      NotificationEvent.PayinOrderCancelledTraderConfirmTimeout,
      NotificationEvent.PayinOrderCompletedByAutomation,
      NotificationEvent.PayinOrderResolveDisputeComplete,
      NotificationEvent.PayinOrderResolveDisputeCancel,
      NotificationEvent.PayinDisableUserConsecutiveCancellations,
      NotificationEvent.PayinBlockTraderRequisites,
      NotificationEvent.PayinUnblockTraderRequisites,
    ],
  },
  {
    name: NotificationEventGroup.PayoutOrders,
    events: [
      NotificationEvent.PayoutOrderTraderAccept,
      NotificationEvent.PayoutOrderTraderAcceptTimeout,
      NotificationEvent.PayoutOrderDisputeTraderPaymentTimeout,
      NotificationEvent.PayoutOrderDispute,
      NotificationEvent.PayoutOrderCreatedDispute,
      NotificationEvent.PayoutOrderCancel,
      NotificationEvent.PayoutOrderComplete,
      NotificationEvent.PayoutDisableUserConsecutiveDisputes,
      NotificationEvent.PayoutBlockTraderRequisites,
      NotificationEvent.PayoutUnblockTraderRequisites,
    ],
  },
  {
    name: NotificationEventGroup.Automation,
    events: [
      NotificationEvent.AutomationGroupDisconnected,
      NotificationEvent.RequisitesTelegramAutomationUnauthorized,
      NotificationEvent.PayinRequisitesVerificationExpired,
    ],
  },
];
