import {
  filter,
  find,
  includes,
  isEmpty,
  map,
  omit,
  transform,
  values,
} from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { payoutRequisitesAutomationApi, payoutRequisitesApi } from 'api';
import {
  EntityDetailsPage,
  EntityDetailsPageChildrenProps,
  FormikNumericField,
  FormikPasswordInput,
  FormikRadioGroup,
  FormikSelect,
  FormikTextField,
  PayoutRequisitesSelect,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import {
  PayoutRequisitesAutomationStatus,
  PayoutRequisitesAutomationType,
  PayoutRequisitesTabs,
  QueryKey,
} from 'enums';
import { useCurrencies, useUser, useUserContextUtils } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationDto,
} from 'types';
import { payoutRequisitesAutomationUtils } from 'utils';

import { RequisitesAutomationStatusLabel } from '../RequisitesAutomationStatusLabel';

type Values = PayoutRequisitesAutomationDto;

export const PayoutRequisitesAutomationDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { role } = useUser();
  const { getBankId } = useUserContextUtils();
  const { fiatCurrenciesOptions } = useCurrencies();

  const { t: tCommon } = useTranslation();

  const isNew = useMemo(() => id === NEW_ID, [id]);

  const initialValues = useMemo(
    (): Values => ({
      name: '',
      status: PayoutRequisitesAutomationStatus.Active,
      type: PayoutRequisitesAutomationType.AMobile,
      requisitesId: '',
      wallet: '',
      secret: '',
      limits: {
        limitSum: 0,
        limitCount: 0,
      },
      balanceFiatCurrencyId: '',
    }),
    [],
  );

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(tCommon('errors.required')),
        requisitesId: Yup.string().required(tCommon('errors.required')),
        wallet: Yup.string().required(tCommon('errors.required')),
        secret: isNew
          ? Yup.string().required(tCommon('errors.required'))
          : Yup.string(),
        status: Yup.string()
          .oneOf(values(PayoutRequisitesAutomationStatus))
          .required(tCommon('errors.required')),
        type: Yup.string()
          .oneOf(values(PayoutRequisitesAutomationType))
          .required(tCommon('errors.required')),
        limits: Yup.object().shape({
          limitSum: Yup.number()
            .moreThan(0, tCommon('errors.natural_number'))
            .required(tCommon('errors.required')),
          limitCount: Yup.number()
            .moreThan(0, tCommon('errors.natural_number'))
            .required(tCommon('errors.required')),
        }),
        balanceFiatCurrencyId: Yup.string().optional().nullable(),
      }),
    [tCommon, isNew],
  );

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation.details',
  });

  const queryResultRequisites = useQuery(
    QueryKey.PayoutRequisites,
    payoutRequisitesApi.getAllAsRole(role),
    { enabled: isNew },
  );

  const queryResultConfig = useQuery(
    QueryKey.PayoutRequisitesAutomationConfig,
    payoutRequisitesAutomationApi.getConfig,
    {
      enabled: isNew,
      select: (data) =>
        map(data, (item) => ({
          type: item.type,
          banks: transform(
            item.banks,
            (acc: string[], bankCode: string) => {
              const id = getBankId({ code: bankCode });
              if (id) {
                acc.push(id);
              }
            },
            [],
          ),
        })),
    },
  );

  const typeOptions = useMemo(
    () =>
      map(values(PayoutRequisitesAutomationType), (type) => ({
        label: payoutRequisitesAutomationUtils.getTypeLabel(type),
        value: type,
      })),
    [],
  );

  const statusOptions = useMemo(
    () =>
      map(
        values(PayoutRequisitesAutomationStatus),
        (status: PayoutRequisitesAutomationStatus) => ({
          value: status,
          label: <RequisitesAutomationStatusLabel status={status} />,
        }),
      ),
    [],
  );

  const onBeforeUpdateMutation = useCallback(
    ({
      id,
      data,
    }: {
      id: string;
      data: Values;
    }): { id: string; data: Values } => ({
      id,
      data: omit(data, ['type', 'requisitesId', 'limits.id']),
    }),
    [],
  );

  const getAvailableRequisites = useCallback(
    (type?: PayoutRequisitesAutomationType) => {
      const typeConfig = find(queryResultConfig.data, { type });
      if (isEmpty(typeConfig?.banks)) {
        return [];
      }
      return filter(queryResultRequisites.data, (requisite) =>
        includes(typeConfig?.banks, requisite.bankId),
      );
    },
    [queryResultConfig.data, queryResultRequisites.data],
  );

  const initialValuesSelect = useCallback(
    (data: PayoutRequisitesAutomation) => ({
      ...data,
      balanceFiatCurrencyId: data.balanceFiatCurrencyId || '',
    }),
    [],
  );

  return (
    <EntityDetailsPage
      title={id ? t('edit_title') : t('create_title')}
      id={id!}
      api={payoutRequisitesAutomationApi}
      listUrl={ROUTE_PATH.TRADER.PAYOUT_REQUISITES}
      tab={PayoutRequisitesTabs.Automation}
      initialValues={initialValues}
      validationSchema={validationSchema}
      queryKey={QueryKey.PayoutRequisitesAutomation}
      formClassName="tw-max-w-md"
      initialValuesSelect={initialValuesSelect}
      onBeforeUpdateMutation={onBeforeUpdateMutation}
    >
      {({
        formik,
        queryResult,
      }: EntityDetailsPageChildrenProps<
        Values,
        PayoutRequisitesAutomation
      >) => (
        <Fragment>
          <FormikRadioGroup
            label={t('fields.status')}
            name="status"
            options={statusOptions}
            disabled={queryResult.data?.requisites?.archived}
          />
          <FormikTextField label={t('fields.name')} name="name" required />
          <FormikSelect
            label={t('fields.type')}
            options={typeOptions}
            name="type"
            disabled={!isNew}
            required
          />
          <PayoutRequisitesSelect
            labelClassName="tw-max-w-[400px] tw-truncate"
            name="requisitesId"
            requisites={
              isNew
                ? getAvailableRequisites(formik.values.type)
                : [queryResult.data?.requisites!]
            }
            required
            disabled={!isNew}
          />
          <FormikTextField name="wallet" label={t('fields.wallet')} required />
          <FormikPasswordInput
            name="secret"
            label={t('fields.secret')}
            required={isNew}
            helperText={
              queryResult.data?.maskedSecret
                ? `${t('fields.masked_secret')}: ****${
                    queryResult.data?.maskedSecret
                  }`
                : undefined
            }
          />
          <FormikSelect
            label={t('fields.balance_fiat_currency')}
            name="balanceFiatCurrencyId"
            options={fiatCurrenciesOptions}
            noneOption
          />
          <FormikNumericField
            name="limits.limitSum"
            label={t('fields.limit_sum')}
            allowNegative={false}
            required
            fullWidth
          />
          <FormikNumericField
            name="limits.limitCount"
            label={t('fields.limit_count')}
            decimalScale={0}
            allowNegative={false}
            required
            fullWidth
          />
        </Fragment>
      )}
    </EntityDetailsPage>
  );
};
