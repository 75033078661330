import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { isNumber } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrencies } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { P2PProvider } from 'types';

import { StylizedNumber } from '../StylizedNumber';

type Props = {
  provider: P2PProvider;
};

export const P2PProviderNameInfo: React.FC<Props> = ({ provider }) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'common',
  });
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.p2p_providers',
  });
  const { getFiatCurrencyCode, getAssetCurrencyCode } = useCurrencies();

  const { name, payinConfig, payoutConfig, fiatCurrencyId, assetCurrencyId } =
    provider;

  const currencyInfo = useMemo(() => {
    const fiatCode = getFiatCurrencyCode(fiatCurrencyId);
    const assetCode = getAssetCurrencyCode(assetCurrencyId);
    return [assetCode, fiatCode].filter(Boolean).join(' / ');
  }, [
    fiatCurrencyId,
    assetCurrencyId,
    getFiatCurrencyCode,
    getAssetCurrencyCode,
  ]);

  const tooltipFee = useMemo(
    () =>
      `${t('fields.expected_fee')} ${tCommon('payin')} / ${tCommon('payout')}`,
    [t, tCommon],
  );

  const payinFee = useMemo(
    () =>
      isNumber(payinConfig?.expectedFee) ? (
        <StylizedNumber value={payinConfig.expectedFee} unit="%" />
      ) : (
        '-'
      ),
    [payinConfig?.expectedFee],
  );

  const payoutFee = useMemo(
    () =>
      isNumber(payoutConfig?.expectedFee) ? (
        <StylizedNumber value={payoutConfig.expectedFee} unit="%" />
      ) : (
        '-'
      ),
    [payoutConfig?.expectedFee],
  );

  return (
    <div className="tw-leading-normal">
      <span className="tw-font-bold">{name}</span>
      <div className="tw-text-gray-400">{currencyInfo}</div>
      <div className="tw-flex">
        {payinFee}
        <div className="tw-mx-2">{'/'}</div>
        {payoutFee}
        <Tooltip
          title={tooltipFee}
          classes={{ popper: 'tw-whitespace-pre-line' }}
        >
          <HelpIcon sx={{ ml: 2 }} fontSize="small" color="secondary" />
        </Tooltip>
      </div>
    </div>
  );
};
