import { Tab } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import {
  CloseDialogResult,
  PageHeader,
  QueryTabPanel,
  QueryTabs,
} from 'components';
import { QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { ActiveFundsRequests } from './ActiveFundsRequests';
import { ArchiveFundsRequests } from './ArchiveFundsRequest';
import { CreateFundRequestDialog } from './CreateFundRequestDialog';

enum FundsRequestsTab {
  Active = 'active',
  Archive = 'archive',
}

export const ManageFundsRequestsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });
  const queryClient = useQueryClient();
  const { isAdmin } = useUser();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreate = useCallback(() => {
    setCreateDialogOpen(true);
  }, []);

  const handleClose = useCallback(
    (result: CloseDialogResult) => {
      if (result.ok) {
        queryClient.invalidateQueries(QueryKey.FundsRequests);
      }
      setCreateDialogOpen(false);
    },
    [queryClient],
  );

  return (
    <div>
      <PageHeader
        title={t('title')}
        divider={false}
        {...(isAdmin && {
          rightContentButton: { onClick: handleCreate },
        })}
      />
      <QueryTabs tabsEnum={FundsRequestsTab}>
        <Tab value={FundsRequestsTab.Active} label={t('tabs.active')} />
        <Tab value={FundsRequestsTab.Archive} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={FundsRequestsTab.Active}>
        <ActiveFundsRequests />
      </QueryTabPanel>
      <QueryTabPanel value={FundsRequestsTab.Archive}>
        <ArchiveFundsRequests />
      </QueryTabPanel>
      {isAdmin && (
        <CreateFundRequestDialog
          open={createDialogOpen}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
