import { Chip, styled } from '@mui/material';
import { concat } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CopyText,
  CopyTextId,
  CrudTable,
  DataGridColumnDefinition,
  DateLabel,
  MailLink,
  OperationTypeLabel,
  QueryFilters,
  StylizedMoney,
  TronscanLink,
} from 'components';
import { OperationType } from 'enums';
import { FundsRequestStatusLabel } from 'features/funds-request';
import { CreateUserWalletWrapper } from 'features/wallets';
import { useQueryFilters, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  FundsRequestFilters,
  FundsRequest,
  PaginatedData,
  User,
  Shop,
} from 'types';
import { filtersUtils, formatUtils, fundsRequestUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<PaginatedData<FundsRequest>>;
  hideTitle?: boolean;
  users?: User[];
  shops?: Shop[];
  additionalColumns?: DataGridColumnDefinition<FundsRequest>[];
  additionalFilters?: FilterDefinition<Partial<FundsRequestFilters>>[];
};

export const AddressChip = styled(Chip)`
  background: none;
`;

export const FundsRequests: React.FC<Props> = ({
  queryResult,
  additionalColumns,
  additionalFilters,
  users,
  shops,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request.funds_requests',
  });

  const { isAdmin, isManager, isMerchant } = useUser();
  const { p2pProviders } = useUserContext();

  const columns = useMemo(
    (): DataGridColumnDefinition<FundsRequest>[] => [
      {
        header: t('fields.date'),
        valueGetter: (item) => (
          <div>
            <CopyTextId id={item.id} />
            <DateLabel>{formatUtils.formatDate(item.createdAt)}</DateLabel>
          </div>
        ),
      },
      {
        header: t('fields.user'),
        hidden: !isManager,
        valueGetter: (item: FundsRequest) =>
          item.user ? (
            <Fragment>
              <div>{item.user.name}</div>
              <div>
                <MailLink email={item.user.email} />
              </div>
              {item.asset?.shop?.name && (
                <div>{formatUtils.formatName(item.asset.shop)}</div>
              )}
              {item.asset?.p2pProvider?.name && (
                <div>{item.asset.p2pProvider.name}</div>
              )}
            </Fragment>
          ) : null,
      },
      {
        header: t('fields.shop'),
        hidden: !isMerchant,
        valueGetter: (item: FundsRequest) =>
          item.asset?.shop?.name && (
            <div>{formatUtils.formatName(item.asset.shop)}</div>
          ),
      },
      {
        header: t('fields.transaction'),
        valueGetter: (item) => (
          <Fragment>
            <div>
              <TronscanLink hash={item.hash} />
            </div>
            {item.recipient &&
              item.operationType === OperationType.Withdrawal && (
                <CreateUserWalletWrapper
                  enabled={isAdmin}
                  data={{
                    userId: item.user?.id!,
                    address: item.recipient,
                    network: item.network,
                  }}
                >
                  <CopyText truncateLength={24} text={item.recipient} />
                </CreateUserWalletWrapper>
              )}
            {item.sender && item.operationType === OperationType.Deposit && (
              <CopyText text={item.sender} truncateLength={24} />
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.amount'),
        valueGetter: (item) => (
          <div>
            <StylizedMoney
              variant="body1"
              value={item.amount}
              symbol
              assetCurrencyId={item.asset?.assetCurrencyId}
            />
            <OperationTypeLabel fundsRequest={item} />
          </div>
        ),
      },
      {
        header: t('fields.status'),
        valueGetter: (item: FundsRequest) => (
          <FundsRequestStatusLabel status={item.status} />
        ),
      },
      ...(additionalColumns ? additionalColumns : []),
    ],
    [t, isManager, isMerchant, isAdmin, additionalColumns],
  );

  const { filters } = useQueryFilters<FundsRequestFilters>();

  const filtersDefinitions = useMemo(() => {
    const common = concat(
      filtersUtils.getCommonFilters(filters),
      fundsRequestUtils.getCommonFilters(users, shops, p2pProviders),
    );
    return additionalFilters ? concat(common, additionalFilters) : common;
  }, [filters, users, shops, p2pProviders, additionalFilters]);

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filtersDefinitions} />
      <CrudTable columns={columns} queryResult={queryResult} paginated />
    </Fragment>
  );
};
