import axios, { AxiosError } from 'axios';
import { t as tCommon, TFunction } from 'i18next';
import { isFunction, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  MutationFunction,
  useMutation as useMutationReactQuery,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { toast, Id, ToastOptions } from 'react-toastify';

import {
  applyGlobalRequestInterceptor,
  applyGlobalResponseInterceptor,
} from 'config/interceptors';
import { SecureConfirmContextType } from 'context';
import { SecureConfirmType } from 'enums';
import { TranslationNamespace } from 'i18n';
import { SecureConfirmData } from 'types';
import { validationUtils } from 'utils';

import { useSecureConfirmContext } from './use-secure-confirm-context.hook';

const notifierTypeKey = 'notifierType';
const notifierMessagesKey = 'notifierMessages';
const onBeforeMutationKey = 'onBeforeMutation';
const requireSecureConfirmKey = 'requireSecureConfirm';
const secureConfirmTypesKey = 'secureConfirmTypes';
type NotifierType = 'save' | 'remove' | 'execute' | 'none';
const defaultNotifierType: NotifierType = 'save';
type NotifierMessages = {
  loading?: string | null;
  success?: string | null;
  error?: ((error: any) => string | undefined) | string | null;
};
type ExtendOptions = {
  [notifierTypeKey]?: NotifierType;
  [notifierMessagesKey]?: NotifierMessages;
  [onBeforeMutationKey]?: (data: any) => any;
  [requireSecureConfirmKey]?: boolean;
  [secureConfirmTypesKey]?: SecureConfirmType[];
};
type InternalOptions = {
  onRequestStart: () => void;
};

function getNotifierMessages(
  t: TFunction,
  notifierType: NotifierType = defaultNotifierType,
): NotifierMessages {
  if (notifierType === 'none') {
    return null as any as NotifierMessages;
  }
  return {
    loading: t(`${notifierType}.loading`),
    success: t(`${notifierType}.success`),
    error: t(`${notifierType}.error`),
  };
}

function extendOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  t: TFunction,
  internalOptions: InternalOptions,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > &
    ExtendOptions,
): Partial<
  Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >
> {
  let toastId: Id;
  const messages =
    options?.[notifierMessagesKey] ||
    getNotifierMessages(t, options?.[notifierTypeKey]);
  return {
    ...(options &&
      omit(
        options,
        notifierTypeKey,
        notifierMessagesKey,
        requireSecureConfirmKey,
        secureConfirmTypesKey,
      )),
    onMutate: (
      variables: TVariables,
    ): Promise<TContext | undefined> | TContext | undefined => {
      if (options?.[requireSecureConfirmKey]) {
        internalOptions.onRequestStart = () => {
          if (messages?.loading) {
            toastId = toast.loading(messages.loading);
          }
        };
      } else {
        if (messages?.loading) {
          toastId = toast.loading(messages.loading);
        }
      }
      return options?.onMutate?.(variables);
    },
    onSuccess: (
      data: TData,
      variables: TVariables,
      context: TContext | undefined,
    ): Promise<unknown> | void => {
      if (messages?.success) {
        const options: ToastOptions = {
          type: 'success',
          isLoading: false,
        };
        if (toastId) {
          toast.update(toastId, {
            ...options,
            closeButton: true,
            autoClose: 3000,
            render: messages.success,
          });
        } else {
          toast.success(messages.success, options);
        }
      }
      return options?.onSuccess?.(data, variables, context);
    },
    onError: (
      error: TError,
      variables: TVariables,
      context: TContext | undefined,
    ): Promise<unknown> | void => {
      if (axios.isCancel(error)) {
        if (toastId) {
          toast.dismiss(toastId);
        }
        return;
      }

      if (messages?.error) {
        const toastOptions: ToastOptions = {
          type: 'error',
          isLoading: false,
        };
        let message;

        if (!options?.[notifierMessagesKey]) {
          if (validationUtils.isTwoFAError(error as any)) {
            message = tCommon('errors.two_fa');
          } else if (
            validationUtils.isSecurePasswordConfirmError(error as any)
          ) {
            message = tCommon('errors.password');
          }
        }

        if (!message) {
          message =
            (isFunction(messages.error)
              ? messages.error(error)
              : messages.error) ||
            getNotifierMessages(t, options?.[notifierTypeKey]).error;
        }

        if (toastId) {
          toast.update(toastId, {
            ...toastOptions,
            closeButton: true,
            autoClose: 3000,
            render: message,
          });
        } else {
          toast.error(message, toastOptions);
        }
      }
      return options?.onError?.(error, variables as any as TVariables, context);
    },
  };
}

function getMutationFn<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options:
    | (Omit<
        UseMutationOptions<TData, TError, TVariables, TContext>,
        'mutationKey' | 'mutationFn'
      > &
        ExtendOptions)
    | undefined,
  internalOptions: InternalOptions,
  secureConfirmContext?: SecureConfirmContextType,
): MutationFunction<TData, TVariables> {
  return async (variables: TVariables) => {
    const formattedVariables = options?.onBeforeMutation
      ? options.onBeforeMutation(variables)
      : variables;

    let axiosInstance = axios.create();
    let secureConfirmData: SecureConfirmData | undefined;

    if (options?.requireSecureConfirm) {
      if (!secureConfirmContext?.requestSecureConfirm) {
        throw new Error('Secure confirm context is missing!');
      }

      try {
        secureConfirmData = await secureConfirmContext.requestSecureConfirm(
          options?.secureConfirmTypes,
        );
      } catch (error) {
        return Promise.reject(new axios.Cancel('Secure confirm canceled'));
      }

      axiosInstance.interceptors.request.use((config) => {
        if (secureConfirmData?.password) {
          config.headers.set('X-Auth-Password', secureConfirmData.password);
        }
        if (secureConfirmData?.code) {
          config.headers.set('X-Auth-Two-FA', secureConfirmData.code);
        }
        return config;
      });

      applyGlobalRequestInterceptor(axiosInstance);
      applyGlobalResponseInterceptor(axiosInstance);
      internalOptions?.onRequestStart?.();
      return mutationFn({ ...formattedVariables, axiosInstance });
    }

    return mutationFn(formattedVariables);
  };
}

export function useMutation<
  TData = unknown,
  TError = AxiosError,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > &
    ExtendOptions,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.notifier.http',
  });

  const secureConfirmContext = useSecureConfirmContext(
    !options?.[requireSecureConfirmKey],
  );

  const internalOptions = {} as InternalOptions;

  return useMutationReactQuery(
    getMutationFn(mutationFn, options, internalOptions, secureConfirmContext),
    extendOptions(t, internalOptions, options),
  );
}
