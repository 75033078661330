import { Alert } from '@mui/material';
import { find } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { paymentLimitsApi } from 'api';
import {
  CrudPage,
  CrudTableActionType,
  DataGridColumnDefinition,
  DataGridColumnHeader,
  dataGridColumns,
} from 'components';
import { FilterDefinitionType, OrderType, QueryKey } from 'enums';
import { useUserContext, useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, RequisiteLimits } from 'types';
import { formUtils } from 'utils';

import { LimitsInfo } from './LimitsInfo';

type RequisiteLimitsFilters = {
  bankId?: string;
};

type Props = {
  onEdit?: (item: RequisiteLimits) => void;
};

export const CommonRequisitesLimits: React.FC<Props> = ({ onEdit }) => {
  const queryClient = useQueryClient();
  const queryResult = usePartialQuery(
    QueryKey.RequisiteLimits,
    paymentLimitsApi.getDefaultRequisitesLimits,
  );
  const { banks } = useUserContext();
  const { isAdminOrTechOperator } = useUser();

  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits.requisite_limits',
  });

  const columns: DataGridColumnDefinition<RequisiteLimits>[] = useMemo(
    () => [
      dataGridColumns.getIdColumn(),
      {
        header: t('columns.banks'),
        valueGetter: (item) =>
          `${find(banks, { id: item.requisitesBankId })?.name} / ${
            find(banks, { id: item.customerBankId })?.name
          }`,
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('columns.payin_limits')}
            tooltip={t('columns.payin_limits_tooltip')}
          />
        ),
        valueGetter: (item) => (
          <LimitsInfo limits={item} orderType={OrderType.Payin} />
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('columns.payout_limits')}
            tooltip={t('columns.payout_limits_tooltip')}
          />
        ),
        valueGetter: (item) => (
          <LimitsInfo limits={item} orderType={OrderType.Payout} />
        ),
      },
    ],
    [banks, t],
  );

  const filtersDefinitions: FilterDefinition<RequisiteLimitsFilters>[] =
    useMemo(
      () => [
        {
          label: tCommon('filters.bank'),
          name: 'bankId',
          type: FilterDefinitionType.Select,
          options: formUtils.getOptions(banks),
          getDisplayName: (value: string) => find(banks, { id: value })?.name,
        },
      ],
      [banks, tCommon],
    );

  const { mutate: remove } = useMutation(
    paymentLimitsApi.removeDefaultRequisitesLimits,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.RequisiteLimits);
      },
      notifierType: 'remove',
    },
  );

  return (
    <Fragment>
      <CrudPage
        table={{
          queryResult,
          paginated: false,
          columns,
          hideActions: !isAdminOrTechOperator,
          actions: [
            {
              type: CrudTableActionType.Details,
              onClick: onEdit,
            },
            {
              type: CrudTableActionType.Remove,
              renderDialogContent: (item) => (
                <Alert severity="warning">{t('remove_dialog.warning')}</Alert>
              ),
              onRemove: (item, { close }) =>
                remove(item.id, { onSuccess: close }),
            },
          ],
        }}
        filters={{ filtersDefinitions }}
      />
    </Fragment>
  );
};
