import axios from 'axios';

import { env } from 'config';
import {
  PayoutRequisitesAutomation,
  PayoutRequisitesAutomationConfig,
  PayoutRequisitesAutomationWarning,
  QueryParams,
} from 'types';
import { apiUtils } from 'utils';

import { createCrudApi } from './crud.api';

const path = '/payout-requisites-automation';
const url = `${env.apiUrl}${path}`;

const getConfig = async (
  params?: Partial<QueryParams>,
): Promise<PayoutRequisitesAutomationConfig[]> =>
  (await apiUtils.getWithQueryParams(`${url}/config`, params)).data;

const getAccountInfo = async (
  id: string,
): Promise<PayoutRequisitesAutomation> =>
  (await axios.get(`${url}/${id}/account-info`)).data;

const refreshAccountInfo = async (): Promise<PayoutRequisitesAutomation> =>
  (await axios.post(`${url}/account-info`)).data;

const getOrderAutomations = async (
  orderId: string,
): Promise<PayoutRequisitesAutomation[]> =>
  (await axios.get(`${url}/order-automations/${orderId}`)).data;

const getWarnings =
  (id: string) =>
  async (
    params?: Partial<QueryParams>,
  ): Promise<PayoutRequisitesAutomationWarning[]> =>
    (await apiUtils.getWithQueryParams(`${url}/${id}/warnings`, params)).data;

const reviewWarning = async ({
  id,
  warningId,
}: {
  id: string;
  warningId: string;
}) => (await axios.post(`${url}/${id}/warnings/${warningId}/review`)).data;

export const payoutRequisitesAutomationApi = {
  ...createCrudApi<PayoutRequisitesAutomation>(path),
  getConfig,
  getAccountInfo,
  refreshAccountInfo,
  getOrderAutomations,
  getWarnings,
  reviewWarning,
};
