import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { isNil, isNumber } from 'lodash';
import React, { useCallback } from 'react';
import {
  NumericFormat,
  NumberFormatValues,
  NumericFormatProps,
} from 'react-number-format';

import { ROUND_DECIMALS } from 'constants/common.constants';
import { useFormikError } from 'hooks';

type Props = {
  name: string;
  label?: string;
  percentageSuffix?: boolean;
  defaultValue?: number | null;
  thousandSeparator?: string;
  error?: boolean;
  helperText?: string;
  onChangeHandler?: (value: number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
} & Partial<NumericFormatProps> &
  Partial<TextFieldProps>;

export const FormikNumericField: React.FC<Props> = ({
  name,
  percentageSuffix,
  suffix,
  defaultValue,
  error,
  helperText,
  thousandSeparator = ' ',
  onChangeHandler,
  onBlur,
  ...rest
}) => {
  const [field, , helpers] = useField(name);

  const { errorVisible, errorMessage } = useFormikError(name);

  const handleChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      const isValidValue = isNumber(floatValue) && !isNaN(floatValue);
      if (isValidValue) {
        onChangeHandler?.(floatValue);
      }
      helpers.setValue(
        isValidValue
          ? floatValue
          : rest.required && isNil(floatValue)
          ? ''
          : defaultValue,
      );
    },
    [helpers, defaultValue, rest.required, onChangeHandler],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      helpers.setTouched(true);
      onBlur?.(e);
    },
    [helpers, onBlur],
  );

  return (
    <NumericFormat
      decimalScale={ROUND_DECIMALS}
      {...rest}
      name={name}
      value={field.value}
      onValueChange={handleChange}
      customInput={TextField}
      decimalSeparator=","
      thousandSeparator={thousandSeparator}
      error={errorVisible || error}
      helperText={errorMessage || helperText}
      size="small"
      suffix={percentageSuffix ? ' %' : suffix ? ` ${suffix}` : ''}
      onBlur={handleBlur}
    />
  );
};
