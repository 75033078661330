import i18next from 'i18next';
import * as Yup from 'yup';

import { P2PProviderType } from 'enums';
import { P2PProviderDto } from 'types';

const getPayinConfigValidationSchema = (
  schema: Yup.ObjectSchema<P2PProviderDto>,
  options?: { assetCurrencyExchangeIdRequired: boolean },
) => {
  const { t } = i18next;
  return schema.shape({
    url: Yup.string().when('enabled', {
      is: (enabled: boolean) => !!enabled,
      then: (schema) => schema.required(t('errors.required')),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    credentials: Yup.object(),
    enabled: Yup.boolean(),
    pollingEnabled: Yup.boolean(),
    responseTimeout: Yup.number().nullable(),
    expectedFee: Yup.number().when('enabled', {
      is: (enabled: boolean) => !!enabled,
      then: (schema) => schema.required(t('errors.required')),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    tradeMethods: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(t('errors.required')),
        }),
      )
      .required(),
    ...(options?.assetCurrencyExchangeIdRequired
      ? {
          assetCurrencyExchangeId: Yup.string().required(t('errors.required')),
        }
      : { assetCurrencyExchangeId: Yup.string().nullable().optional() }),
  });
};

const getPayoutConfigValidationSchema = (
  schema: Yup.ObjectSchema<P2PProviderDto>,
  options?: { assetCurrencyExchangeIdRequired: boolean },
) => {
  const { t } = i18next;
  return schema.shape({
    url: Yup.string().when('enabled', {
      is: (enabled: boolean) => !!enabled,
      then: (schema) => schema.required(t('errors.required')),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    credentials: Yup.object(),
    enabled: Yup.boolean(),
    pollingEnabled: Yup.boolean(),
    responseTimeout: Yup.number().nullable(),
    orderTimeout: Yup.number()
      .min(0, t('errors.natural_or_zero_number'))
      .integer(t('errors.natural_number'))
      .when('enabled', {
        is: (enabled: boolean) => !!enabled,
        then: (schema) => schema.required(t('errors.required')),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    expectedFee: Yup.number().when('enabled', {
      is: (enabled: boolean) => !!enabled,
      then: (schema) => schema.required(t('errors.required')),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    tradeMethods: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(t('errors.required')),
        }),
      )
      .required(),
    ...(options?.assetCurrencyExchangeIdRequired
      ? {
          assetCurrencyExchangeId: Yup.string().required(t('errors.required')),
        }
      : { assetCurrencyExchangeId: Yup.string().nullable().optional() }),
  });
};

export const getP2PProviderValidationSchema = (
  defaultAssetCurrencyId?: string,
): Yup.ObjectSchema<P2PProviderDto> => {
  const { t } = i18next;
  return Yup.object().shape({
    name: Yup.string().required(t('errors.required')),
    type: Yup.string()
      .required(t('errors.required'))
      .oneOf(Object.values(P2PProviderType)),
    withdrawalFee: Yup.number().required(t('errors.required')),
    assetCurrencyId: Yup.string().required(t('errors.required')),
    fiatCurrencyId: Yup.string().required(t('errors.required')),
    currencyExchangeId: Yup.string().when('assetCurrencyId', {
      is: (assetCurrencyId: string) =>
        !!assetCurrencyId && assetCurrencyId === defaultAssetCurrencyId,
      then: (schema) => schema.nullable().optional(),
      otherwise: (schema) => schema.required(t('errors.required')),
    }),
    payinConfig: Yup.object().when('assetCurrencyId', {
      is: (assetCurrencyId: string) =>
        !!assetCurrencyId && assetCurrencyId === defaultAssetCurrencyId,
      then: (schema) => getPayinConfigValidationSchema(schema),
      otherwise: (schema) =>
        getPayinConfigValidationSchema(schema, {
          assetCurrencyExchangeIdRequired: true,
        }),
    }),
    payoutConfig: Yup.object().when('assetCurrencyId', {
      is: (assetCurrencyId: string) =>
        !!assetCurrencyId && assetCurrencyId === defaultAssetCurrencyId,
      then: (schema) => getPayoutConfigValidationSchema(schema),
      otherwise: (schema) =>
        getPayoutConfigValidationSchema(schema, {
          assetCurrencyExchangeIdRequired: true,
        }),
    }),
    payoutLimits: Yup.object().when('payoutConfig.enabled', {
      is: (enabled: boolean) => !!enabled,
      then: (schema) =>
        schema.shape({
          limitCount: Yup.number()
            .min(0)
            .integer()
            .required(t('errors.required')),
          limitSum: Yup.number().min(0).required(t('errors.required')),
        }),
      otherwise: (schema) =>
        schema.shape({
          limitCount: Yup.number().min(0).integer(),
          limitSum: Yup.number().min(0),
        }),
    }),
  });
};
