import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { executionMetricsApi } from 'api';
import {
  CopyTextId,
  CrudPage,
  DataGridColumnDefinition,
  dataGridColumns,
  ExpandedJson,
} from 'components';
import {
  ExecutionMetricOperation,
  FilterDefinitionType,
  QueryKey,
} from 'enums';
import { usePartialQuery } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { ExecutionMetric, FilterDefinition } from 'types';
import { formatUtils } from 'utils';

type ExecutionMetricsFilters = {
  orderId: string;
  payoutOrderId: string;
  operation: string;
  error: string;
};

export const ExecutionMetricsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.execution_metrics',
  });

  const queryResult = usePartialQuery(
    QueryKey.ExecutionMetrics,
    executionMetricsApi.getAllPaginated,
  );

  const getOperationLabel = useCallback(
    (operation: ExecutionMetricOperation) =>
      t(`operations.${operation}`, {
        defaultValue: operation,
      }),
    [t],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<ExecutionMetric>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.operation'),
        valueGetter: (item) => (
          <Fragment>
            <div>{getOperationLabel(item.operation)}</div>
            {(item.orderId || item.payoutOrderId) && (
              <CopyTextId id={item.orderId || item.payoutOrderId} />
            )}
          </Fragment>
        ),
      },
      {
        header: t('fields.duration'),
        valueGetter: (item) =>
          formatUtils.formatNumber(item.duration, { suffix: 'ms' }),
      },
      {
        header: t('fields.error'),
        valueClassName: 'tw-w-[200px] tw-break-all',
        valueGetter: (item) => {
          if (item.error) {
            return <ExpandedJson text={item.error} />;
          }
          return null;
        },
      },
    ],
    [t, getOperationLabel],
  );

  const filtersDefinitions: FilterDefinition<ExecutionMetricsFilters>[] =
    useMemo(
      () => [
        {
          label: t('fields.operation'),
          name: 'operation',
          type: FilterDefinitionType.Enum,
          enum: ExecutionMetricOperation,
          getDisplayName: getOperationLabel,
        },
        {
          label: t('fields.payin_id'),
          name: 'orderId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: t('fields.payin_id'),
          name: 'payoutOrderId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: t('fields.error'),
          name: 'error',
          type: FilterDefinitionType.Text,
        },
      ],
      [t, getOperationLabel],
    );

  return (
    <CrudPage
      header={{ title: t('title') }}
      filters={{ filtersDefinitions, withCommon: true }}
      table={{ queryResult, columns, paginated: true }}
    />
  );
};
