import { useQuery } from 'react-query';

import { assetsApi, shopsApi, usersApi } from 'api';
import { QueryKey } from 'enums';
import { InternalTransfersPage } from 'features/internal-transfers';
import { useShopsQuery, useUser } from 'hooks';

export const AdminInternalTransfersPage: React.FC = () => {
  const { role } = useUser();
  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultUsers = useQuery(
    QueryKey.InternalTransfersUsers,
    usersApi.getAssetHoldersWithProvider,
  );
  const queryResultAssets = useQuery(QueryKey.Assets, assetsApi.getAll);

  return (
    <InternalTransfersPage
      queryResultUsers={queryResultUsers}
      queryResultShops={queryResultShops}
      queryResultAssets={queryResultAssets}
    />
  );
};
