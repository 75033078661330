import { SxProps, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatusLabel } from 'components';
import { TranslationNamespace } from 'i18n';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
  sx?: SxProps;
};

export const VerificationStatus: React.FC<Props> = ({ order, sx }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders',
  });

  if (!order.requisitesVerification) {
    return null;
  }

  return (
    <Tooltip title={t('table.columns.requisites_verification')} sx={sx}>
      <StatusLabel
        label={t('details.requisites_verification_order')}
        status="inactive"
      />
    </Tooltip>
  );
};
