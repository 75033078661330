import { Link } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
  p2pProvidersPayinRequestsApi,
  p2pProvidersPayoutRequestsApi,
} from 'api';
import { DescriptionRow } from 'components';
import { OrderType, QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';

import { P2PProvidersRequestsDialog } from './P2PProvidersRequestsDialog';

type Props = {
  orderId: string;
  orderType: OrderType;
};

export const P2PProvidersRequests: React.FC<Props> = ({
  orderId,
  orderType,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.p2p_providers_requests',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [dialogOpen, setDialogOpen] = useState(false);
  const queryKey = useMemo(
    () =>
      orderType === OrderType.Payin
        ? QueryKey.P2PProviderPayinRequests
        : QueryKey.P2PProviderPayoutRequests,
    [orderType],
  );
  const queryFn = useMemo(
    () =>
      orderType === OrderType.Payin
        ? p2pProvidersPayinRequestsApi.getForOrder
        : p2pProvidersPayoutRequestsApi.getForOrder,
    [orderType],
  );

  const queryResult = useQuery(queryKey, queryFn(orderId), {
    enabled: dialogOpen,
  });

  return (
    <Fragment>
      <DescriptionRow
        title={t('title')}
        value={
          <div>
            <Link
              component="button"
              variant="body2"
              underline="none"
              onClick={() => setDialogOpen(true)}
            >
              {tCommon('buttons.open')}
            </Link>
          </div>
        }
      />
      <P2PProvidersRequestsDialog
        queryResult={queryResult}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Fragment>
  );
};
