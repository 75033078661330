import { Alert, Typography } from '@mui/material';
import { Form } from 'formik';
import { Fragment, useCallback, useMemo } from 'react';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  DialogProps,
  FormControls,
  FormikDialog,
  FormikPasswordInput,
} from 'components';
import { TWO_FA_CODE_LENGTH } from 'constants/auth.constants';
import { SecureConfirmType } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { SecureConfirmData } from 'types';

type Props = {
  secureConfirms?: SecureConfirmType[];
} & DialogProps<SecureConfirmData>;

export const SecureConfirmDialog: React.FC<Props> = ({
  open,
  secureConfirms = [SecureConfirmType.TwoFA, SecureConfirmType.Password],
  onClose,
}) => {
  const { user } = useUser();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.secure_confirm_dialog',
  });

  const { confirmTwoFa, confirmPassword } = useMemo(() => {
    const confirmTwoFa = secureConfirms?.includes(SecureConfirmType.TwoFA);
    const confirmPassword = secureConfirms?.includes(
      SecureConfirmType.Password,
    );
    return { confirmTwoFa, confirmPassword };
  }, [secureConfirms]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: confirmPassword
          ? Yup.string().required()
          : Yup.string().nullable(),
        code: confirmTwoFa
          ? Yup.string().required().length(TWO_FA_CODE_LENGTH)
          : Yup.string().nullable(),
      }),
    [confirmPassword, confirmTwoFa],
  );

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<SecureConfirmData>) => {
      data.data?.formikHelpers.resetForm();
      onClose(data);
    },
    [onClose],
  );

  return (
    <FormikDialog
      dialogProps={{
        open,
        title: t('title'),
        hideOk: confirmTwoFa && !user.twoFAEnabled,
        onClose: handleClose,
      }}
      formikProps={{
        validationSchema,
        validateOnMount: true,
        initialValues: {
          code: '',
          password: '',
        },
      }}
    >
      {({ formik }) => (
        <Form>
          <Typography sx={{ mb: 4 }}>{t('description')}</Typography>
          <FormControls>
            {confirmTwoFa && !user.twoFAEnabled && (
              <Alert severity="error">
                <div>{t('two_fa_disabled')}</div>
                <div>{t('two_fa_disabled_profile')}</div>
              </Alert>
            )}
            {confirmTwoFa && user.twoFAEnabled && (
              <Fragment>
                <div className="tw-text-center">{t('code_2fa')}</div>
                <AuthCode
                  containerClassName="tw-px-4 tw-text-center"
                  inputClassName="tw-w-10 tw-h-10 tw-m-1 tw-border tw-border-2 tw-text-center"
                  allowedCharacters="numeric"
                  autoFocus
                  onChange={(code) => formik.setFieldValue('code', code)}
                />
              </Fragment>
            )}
            {(!confirmTwoFa || user.twoFAEnabled) && confirmPassword && (
              <div className="tw-text-center">
                <FormikPasswordInput
                  name="password"
                  sx={{ width: 280 }}
                  autoComplete="new-password"
                  label={t('password')}
                />
              </div>
            )}
          </FormControls>
        </Form>
      )}
    </FormikDialog>
  );
};
