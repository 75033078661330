import { createContext } from 'react';

import { SecureConfirmType } from 'enums';
import { SecureConfirmData } from 'types';

export type SecureConfirmContextType = {
  requestSecureConfirm: (
    confirms?: SecureConfirmType[],
  ) => Promise<SecureConfirmData>;
};

export const SecureConfirmContext = createContext<
  SecureConfirmContextType | undefined
>(undefined);
