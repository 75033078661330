import React, { useCallback, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PayOutlet } from 'components';

type Props = {
  path: string;
  payinElement: React.ReactNode;
  payoutElement: React.ReactNode;
  commonElement?: React.ReactNode;
};

export const PayRoutes: React.FC<Props> = ({
  path,
  payinElement,
  payoutElement,
  commonElement,
}) => {
  const { payin, payout, common } = useMemo(
    () => ({
      payin: 'payin',
      payout: 'payout',
      common: 'common',
    }),
    [],
  );

  const renderOutlet = useCallback(
    () => (
      <PayOutlet
        payinTo={`${path}/${payin}`}
        payoutTo={`${path}/${payout}`}
        {...(commonElement && { commonTo: `${path}/${common}` })}
      />
    ),
    [path, payin, payout, common, commonElement],
  );

  return (
    <Routes>
      <Route path={'*'} element={renderOutlet()}>
        <Route index path={payin} element={payinElement} />
        <Route path={payout} element={payoutElement} />
        {commonElement && <Route path={common} element={commonElement} />}
        <Route path="*" element={<Navigate to={payin} />} />
      </Route>
    </Routes>
  );
};
