import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyText, ExpandedJson, FormikJsonField } from 'components';
import { AppConfigValueType } from 'enums';
import { usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { AppConfig, AppConfigSchema } from 'types';
import { appConfigUtils, formatUtils } from 'utils';

type Props = {
  schema: AppConfigSchema;
  data: Partial<AppConfig>;
};

export const ConfigValueField: React.FC<Props> = ({ schema, data }) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'features.configs',
  });

  const formik = useFormikContext();
  const prevDefaultValue = usePrevious(schema.defaultValue);

  const initializedRef = useRef(false);

  const { isJsonObject, isJsonArray } = useMemo(
    () => ({
      isJsonObject: schema.valueType === AppConfigValueType.JsonObject,
      isJsonArray: schema.valueType === AppConfigValueType.JsonArray,
    }),
    [schema],
  );

  useEffect(() => {
    if (!initializedRef.current || prevDefaultValue !== schema.defaultValue) {
      initializedRef.current = true;
      let field = appConfigUtils.getValueField(schema.valueType);
      if (field) {
        formik.setFieldTouched(field, false);
        if (!data?.id && schema.defaultValue) {
          if (isJsonArray || isJsonObject) {
            formik.setFieldValue(
              field,
              formatUtils.jsonStringify(schema.defaultValue),
            );
          }
        }
      }
    }
  }, [
    formik,
    isJsonArray,
    isJsonObject,
    prevDefaultValue,
    schema.defaultValue,
    data,
    schema.valueType,
  ]);

  return (
    <Fragment>
      {isJsonObject && (
        <FormikJsonField
          label={t('fields.json_object_value')}
          name="jsonObjectValue"
          required
        />
      )}
      {isJsonArray && (
        <FormikJsonField
          label={t('fields.json_array_value')}
          name="jsonArrayValue"
          required
        />
      )}
      {schema.defaultValue && (
        <Fragment>
          <Typography variant="subtitle2">
            <div className="tw-flex tw-items-center">
              {t('fields.default_value')}
              {(isJsonObject || isJsonArray) && (
                <CopyText
                  iconOnly
                  className="tw-ml-1"
                  text={formatUtils.jsonStringify(schema.defaultValue)}
                />
              )}
            </div>
          </Typography>
          {(isJsonObject || isJsonArray) && (
            <ExpandedJson json={schema.defaultValue} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
