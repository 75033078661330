import { FormControlLabel } from '@mui/material';
import { Field, FieldAttributes } from 'formik';
import { Checkbox } from 'formik-mui';
import React from 'react';

type Props = FieldAttributes<any>;

export const FormikCheckbox: React.FC<Props> = ({
  name,
  label,
  sx,
  ...rest
}) => (
  <div>
    <FormControlLabel
      control={
        <Field
          component={Checkbox}
          type="checkbox"
          name={name}
          sx={{ paddingTop: 1, paddingBottom: 1 }}
          {...rest}
        />
      }
      label={label}
      sx={sx}
    />
  </div>
);
