import * as QRCode from 'qrcode';
import { useCallback, useEffect, useState } from 'react';

import { DataWrapper } from 'components';

type Props = {
  data: any;
};

export const QrCode: React.FC<Props> = ({ data }) => {
  const [qrCode, setQrCode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const generateQrCode = useCallback(
    async (data: any) => {
      if (!loading && !error && !qrCode && data) {
        try {
          setLoading(true);
          const qrCodeUrl = await QRCode.toDataURL(JSON.stringify(data));
          setQrCode(qrCodeUrl);
        } catch (e) {
          setError(!!e);
        } finally {
          setLoading(false);
        }
      }
    },
    [loading, error, qrCode],
  );

  useEffect(() => {
    generateQrCode(data);
  }, [data, generateQrCode]);

  return (
    <DataWrapper isLoading={loading} isError={error}>
      <div className="tw-flex tw-justify-center">
        <img src={qrCode} alt="qr" className="tw-border" />
      </div>
    </DataWrapper>
  );
};
