import { QrCodeScannerOutlined as QrCodeScannerOutlinedIcon } from '@mui/icons-material';
import { SxProps, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';

export type QrCodeIconProps = {
  onClick: MouseEventHandler<SVGSVGElement>;
  sx?: SxProps;
  title: string;
};

export const QrCodeIcon: React.FC<QrCodeIconProps> = ({
  onClick,
  sx,
  title,
}) => (
  <Tooltip title={title}>
    <QrCodeScannerOutlinedIcon
      fontSize="small"
      color="primary"
      className="tw-cursor-pointer"
      sx={sx}
      onClick={onClick}
    />
  </Tooltip>
);
