import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect, FormikSelectProps } from 'components';
import { CurrencyExchangeDirection } from 'enums';
import { TranslationNamespace } from 'i18n';
import { currencyExchangeUtils } from 'utils';

type Props = {
  name: string;
  label?: string;
} & Partial<FormikSelectProps>;

export const CurrencyExchangeDirectionSelect: React.FC<Props> = ({
  name,
  label,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.exchange_direction',
  });

  const options = useMemo(
    () => [
      {
        label: currencyExchangeUtils.getCurrencyExchangeDirectionLabel(
          CurrencyExchangeDirection.Buy,
        ),
        value: CurrencyExchangeDirection.Buy,
      },
      {
        label: currencyExchangeUtils.getCurrencyExchangeDirectionLabel(
          CurrencyExchangeDirection.Sell,
        ),
        value: CurrencyExchangeDirection.Sell,
      },
    ],
    [],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
