import { TypographyProps } from '@mui/material';
import i18next from 'i18next';
import moment from 'moment';

import {
  CurrencyExchangeDirection,
  CurrencyExchangeSource,
  FiatCurrencyExchangeSource,
} from 'enums';

const ALERT_DATE_AFTER_MINUTES = 5;

const getCurrencyExchangeSourceLabel = (source: CurrencyExchangeSource) =>
  i18next.t(`currency_exchange_source.${source}`, { defaultValue: source });

const getFiatCurrencyExchangeSourceLabel = (
  source: FiatCurrencyExchangeSource,
) =>
  i18next.t(`fiat_currency_exchange_source.${source}`, {
    defaultValue: source,
  });

const getDateColor = (
  date: string,
  item: { refresh: boolean },
  dataUpdatedAt: string | number,
): TypographyProps['color'] => {
  if (
    item.refresh &&
    (!date ||
      moment(date)
        .add(ALERT_DATE_AFTER_MINUTES, 'minutes')
        .isBefore(dataUpdatedAt))
  ) {
    return 'red';
  }
  return 'textSecondary';
};

const getCurrencyExchangeDirectionLabel = (
  direction: CurrencyExchangeDirection,
) => i18next.t(`common.${direction}`, { defaultValue: direction });

export const currencyExchangeUtils = {
  getCurrencyExchangeSourceLabel,
  getFiatCurrencyExchangeSourceLabel,
  getCurrencyExchangeDirectionLabel,
  getDateColor,
};
