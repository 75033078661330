import { Switch } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

type Props = {
  status: any;
  activeStatus: any;
  inactiveStatus: any;
  disabled?: boolean;
  updateStatus: (status: any) => void;
};

export const StatusToggle: React.FC<Props> = ({
  status,
  activeStatus,
  inactiveStatus,
  disabled,
  updateStatus,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.status_toggle',
  });

  const isActive = useMemo(
    () => status === activeStatus,
    [status, activeStatus],
  );

  const changeStatusTitle = useMemo(() => {
    if (status === activeStatus) {
      return t('deactivate');
    } else if (status === inactiveStatus) {
      return t('activate');
    }
  }, [status, activeStatus, inactiveStatus, t]);

  const oppositeStatus = useMemo(() => {
    if (status === activeStatus) {
      return inactiveStatus;
    } else if (status === inactiveStatus) {
      return activeStatus;
    }
  }, [activeStatus, inactiveStatus, status]);

  const handleStatusChange = useCallback(() => {
    if (disabled) {
      return;
    }
    if (oppositeStatus) {
      updateStatus(oppositeStatus);
    }
  }, [disabled, oppositeStatus, updateStatus]);

  return (
    <Switch
      title={changeStatusTitle}
      checked={isActive}
      disabled={disabled}
      onChange={handleStatusChange}
    />
  );
};
