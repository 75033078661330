import React from 'react';

import { useUserContextUtils } from 'hooks';

type Props = {
  bankId?: string;
  bankCode?: string;
  className?: string;
};

export const BankLabel: React.FC<Props> = ({ bankId, bankCode, className }) => {
  const { getBankName } = useUserContextUtils();
  return (
    <span className={className}>
      {getBankName({ id: bankId, code: bankCode })}
    </span>
  );
};
