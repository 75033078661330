import styled from '@emotion/styled';
import {
  SmartToyOutlined as SmartToyOutlinedIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components';
import { PayoutRequisitesAutomationStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type Props = {
  status: PayoutRequisitesAutomationStatus;
};

const getBackgroundColor = (props: any) => {
  switch (props.status) {
    case PayoutRequisitesAutomationStatus.Active:
      return props.theme.palette.primary.main;
    case PayoutRequisitesAutomationStatus.Automation:
      return props.theme.palette.success.main;
    case PayoutRequisitesAutomationStatus.Inactive:
      return props.theme.palette.grey[500];
  }
};

const StyledLabel = styled(StyledChip)<
  ChipProps & { status: PayoutRequisitesAutomationStatus }
>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

export const RequisitesAutomationStatusLabel: React.FC<Props> = ({
  status,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'payout_requisites_status',
  });

  switch (status) {
    case PayoutRequisitesAutomationStatus.Active:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="success"
          icon={<PersonIcon />}
        ></StyledLabel>
      );
    case PayoutRequisitesAutomationStatus.Automation:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          icon={<SmartToyOutlinedIcon />}
        ></StyledLabel>
      );
    case PayoutRequisitesAutomationStatus.Inactive:
      return (
        <StyledLabel
          label={t(status)}
          status={status}
          color="default"
        ></StyledLabel>
      );
    default:
      return null;
  }
};
