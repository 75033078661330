import { find } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { requisitesDistributionApi, shopsApi, tradersApi } from 'api';
import {
  DataGridColumnDefinition,
  RequisitesInfo,
  StylizedMoney,
  StatisticalInfo,
  CrudPage,
  DataGridColumnHeader,
} from 'components';
import { FilterDefinitionType, QueryKey } from 'enums';
import { usePartialQuery, useShopsQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { RequisitesDistribution, FilterDefinition } from 'types';
import { formatUtils } from 'utils';

export type RequisitesDistributionFilters = {
  requisitesId: string;
  shopId: string;
  traderId: string;
};

export const RequisitesDistributionPage: React.FC = () => {
  // TODO: move tkeys
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.distribution',
  });
  const { role } = useUser();

  const queryResultShops = useShopsQuery(
    QueryKey.Shops,
    shopsApi.getAllAsRole(role),
  );
  const queryResultTraders = useQuery(QueryKey.Traders, () =>
    tradersApi.getAllAsRole(role)(),
  );

  const queryResult = usePartialQuery(
    QueryKey.RequisitesDistribution,
    requisitesDistributionApi.getCachePaginated,
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<RequisitesDistribution>[] => [
      {
        header: t('fields.shop'),
        valueGetter: (item) => formatUtils.formatName(item.shop),
      },
      {
        header: t('fields.trader'),
        valueGetter: (item) => (
          <Fragment>
            <div>{item.trader?.user?.name}</div>
            <StylizedMoney
              variant="body1"
              value={item.traderAsset?.balance}
              assetCurrencyId={item.traderAsset?.assetCurrencyId}
              symbol
            />
          </Fragment>
        ),
      },
      {
        header: t('fields.requisites'),
        valueGetter: (item) => <RequisitesInfo requisite={item.requisites} />,
      },
      {
        header: t('fields.trade_method_id'),
        valueKey: 'tradeMethodId',
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('fields.count')}
            tooltip={t('fields.period')}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limits?.limitCountPerHour}
              total={item.requisites?.limitCountPerHour}
            />
            <StatisticalInfo
              used={item.limits?.limitCountPerDay}
              total={item.requisites?.limitCountPerDay}
            />
            <StatisticalInfo
              used={item.limits?.limitCountPerMonth}
              total={item.requisites?.limitCountPerMonth}
            />
          </div>
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('fields.sum')}
            tooltip={t('fields.period')}
          />
        ),
        valueGetter: (item) => (
          <div>
            <StatisticalInfo
              used={item.limits?.limitSumPerHour}
              total={item.requisites?.limitSumPerHour}
              formatter={formatUtils.formatMoney}
            />
            <StatisticalInfo
              used={item.limits?.limitSumPerDay}
              total={item.requisites?.limitSumPerDay}
              formatter={formatUtils.formatMoney}
            />
            <StatisticalInfo
              used={item.limits?.limitSumPerMonth}
              total={item.requisites?.limitSumPerMonth}
              formatter={formatUtils.formatMoney}
            />
          </div>
        ),
      },
      {
        header: (
          <DataGridColumnHeader
            title={t('fields.priority')}
            tooltip={t('fields.priority_description')}
          />
        ),
        valueGetter: (item) => item.requisitesPriority,
      },
      {
        header: t('fields.last_update'),
        valueGetter: (item) => formatUtils.formatDate(item.limits?.updatedAt),
      },
    ],
    [t],
  );

  const filtersDefinitions: FilterDefinition<RequisitesDistributionFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.shop'),
          name: 'shopId',
          type: FilterDefinitionType.Shop,
          users: queryResultShops.data,
          getDisplayName: (shopId: string) =>
            find(queryResultShops.data, { id: shopId })?.name,
        },
        {
          label: t('filters.trader'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          traders: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.user?.name,
        },
        {
          label: t('filters.requisites_id'),
          name: 'requisitesId',
          type: FilterDefinitionType.Text,
        },
      ],
      [queryResultShops.data, queryResultTraders.data, t],
    );

  return (
    <CrudPage
      header={{ title: t('title') }}
      filters={{ filtersDefinitions }}
      table={{ queryResult, columns, paginated: true }}
    />
  );
};
