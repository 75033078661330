import { AdditionalOrderColumn, OrdersTab } from 'enums';

import { ManageOrdersList } from '../ManageOrderList';

export const HoldCompletedOrders: React.FC = () => (
  <ManageOrdersList
    tab={OrdersTab.HoldCompleted}
    additionalColumns={[AdditionalOrderColumn.HoldCompleted]}
  />
);
