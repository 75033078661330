import { Tab } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, DataWrapper, QueryTabs, QueryTabPanel } from 'components';
import { useQueryTab, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PlatformPaymentLimits, RequisiteLimits } from 'types';

import {
  CommonPlatformLimits,
  PlatformLimitsDialog,
} from './CommonPlatformLimits';
import {
  CommonRequisitesLimits,
  RequisiteLimitsDialog,
} from './CommonRequisitesLimits';

enum Tabs {
  RequisiteLimits = 'requisite_limits',
  PlatformLimits = 'platform_limits',
}

export const CommonLimitsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.common_limits',
  });

  const { isAdminOrTechOperator } = useUser();

  const { tab } = useQueryTab<Tabs>();

  const [requisiteLimitsDialogProps, setRequisiteLimitsDialogProps] = useState<{
    open: boolean;
    limits?: RequisiteLimits;
  }>({ open: false });

  const handleCreateRequisiteLimits = useCallback(() => {
    setRequisiteLimitsDialogProps({ open: true });
  }, []);

  const [platformLimitsDialogProps, setPlatformLimitsDialogProps] = useState<{
    open: boolean;
    limits?: PlatformPaymentLimits;
  }>({ open: false });

  const handleCreatePlatformLimits = useCallback(() => {
    setPlatformLimitsDialogProps({ open: true });
  }, []);

  const handleCreateClick = useCallback(() => {
    if (tab === Tabs.RequisiteLimits) {
      handleCreateRequisiteLimits();
    } else if (tab === Tabs.PlatformLimits) {
      handleCreatePlatformLimits();
    }
  }, [tab, handleCreateRequisiteLimits, handleCreatePlatformLimits]);

  const onEdit = useCallback(
    (limits: RequisiteLimits | PlatformPaymentLimits) => {
      if (tab === Tabs.RequisiteLimits) {
        setRequisiteLimitsDialogProps({
          open: true,
          limits: limits as RequisiteLimits,
        });
      } else if (tab === Tabs.PlatformLimits) {
        setPlatformLimitsDialogProps({
          open: true,
          limits: limits as PlatformPaymentLimits,
        });
      }
    },
    [tab, setRequisiteLimitsDialogProps, setPlatformLimitsDialogProps],
  );

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        {...(isAdminOrTechOperator && {
          rightContentButton: { onClick: handleCreateClick },
        })}
      />
      <DataWrapper>
        <Fragment>
          <QueryTabs tabsEnum={Tabs}>
            <Tab
              value={Tabs.RequisiteLimits}
              label={t('tabs.requisite_limits')}
            ></Tab>
            {isAdminOrTechOperator && (
              <Tab
                value={Tabs.PlatformLimits}
                label={t('tabs.platform_limits')}
              ></Tab>
            )}
          </QueryTabs>
          <QueryTabPanel value={Tabs.RequisiteLimits}>
            <CommonRequisitesLimits onEdit={onEdit} />
          </QueryTabPanel>
          {isAdminOrTechOperator && (
            <QueryTabPanel value={Tabs.PlatformLimits}>
              <CommonPlatformLimits onEdit={onEdit} />
            </QueryTabPanel>
          )}
        </Fragment>
      </DataWrapper>
      {isAdminOrTechOperator && (
        <Fragment>
          <RequisiteLimitsDialog
            {...requisiteLimitsDialogProps}
            onClose={() => setRequisiteLimitsDialogProps({ open: false })}
          />
          <PlatformLimitsDialog
            {...platformLimitsDialogProps}
            onClose={() => setPlatformLimitsDialogProps({ open: false })}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
