import { FieldAttributes } from 'formik';
import React from 'react';

import { FormikTextField } from 'components';

type Props = FieldAttributes<any>;

export const FormikJsonField: React.FC<Props> = (props) => (
  <FormikTextField multiline rows={10} {...props} />
);
