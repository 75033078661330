import { Route, Navigate } from 'react-router-dom';

import { MerchantRoute, PayRoutes } from 'components';
import { ROUTE_PATH as ROUTE_PATH_ALL } from 'constants/routes';
import { CurrencyExchangeRequestsPage } from 'features/currency-exchange-requests';
import {
  MerchantMainPage,
  MerchantFundsRequestsPage,
  MerchantInternalTransfersPage,
  MerchantBalanceHistoryPage,
  MerchantSettingsPage,
  MerchantShopsPage,
  MerchantShopDetailsPage,
  MerchantWebhooksPage,
  MerchantOrdersPage,
  MerchantPayinOrdersPage,
  MerchantPayoutOrdersPage,
  MerchantPayinStatisticsPage,
  MerchantPayoutStatisticsPage,
} from 'pages';

const ROUTE_PATH = ROUTE_PATH_ALL.MERCHANT;

export const MerchantRoutes = () => (
  <Route path={ROUTE_PATH.MAIN} element={<MerchantRoute />}>
    <Route path="" element={<MerchantMainPage />} />
    <Route
      path={ROUTE_PATH.FUNDS_REQUESTS}
      element={<MerchantFundsRequestsPage />}
    />
    <Route
      path={ROUTE_PATH.INTERNAL_TRANSFERS}
      element={<MerchantInternalTransfersPage />}
    />
    <Route
      path={ROUTE_PATH.BALANCE_HISTORY}
      element={<MerchantBalanceHistoryPage />}
    />

    <Route path={ROUTE_PATH.SETTINGS} element={<MerchantSettingsPage />} />
    <Route path={ROUTE_PATH.SHOPS} element={<MerchantShopsPage />} />

    <Route
      path={ROUTE_PATH.SHOP_DETAILS}
      element={<MerchantShopDetailsPage />}
    />

    <Route path={ROUTE_PATH.WEBHOOKS} element={<MerchantWebhooksPage />} />
    <Route
      path={ROUTE_PATH.ORDERS}
      element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />}
    />
    <Route path={ROUTE_PATH.ORDERS} element={<MerchantOrdersPage />}>
      <Route
        index
        path={ROUTE_PATH.PAYIN_ORDERS}
        element={<MerchantPayinOrdersPage />}
      />
      <Route
        path={ROUTE_PATH.PAYOUT_ORDERS}
        element={<MerchantPayoutOrdersPage />}
      />
      <Route path="*" element={<Navigate to={ROUTE_PATH.PAYIN_ORDERS} />} />
    </Route>

    <Route
      path={`${ROUTE_PATH.STATISTICS}/*`}
      element={
        <PayRoutes
          path={ROUTE_PATH.STATISTICS}
          payinElement={<MerchantPayinStatisticsPage />}
          payoutElement={<MerchantPayoutStatisticsPage />}
        />
      }
    />
    <Route
      path={ROUTE_PATH.CURRENCY_EXCHANGE}
      element={<CurrencyExchangeRequestsPage />}
    />
  </Route>
);
