import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  SxProps,
} from '@mui/material';
import { Field, useField } from 'formik';
import { RadioGroup } from 'formik-mui';
import { map, noop } from 'lodash';
import React, { useCallback } from 'react';

export type FormikRadioGroupOption = {
  label: React.ReactNode | string;
  value: unknown;
  disabled?: boolean;
};

type Props = {
  label?: string;
  name: string;
  disabled?: boolean;
  options: FormikRadioGroupOption[];
  sx?: SxProps;
  formControlLabelSx?: SxProps;
  onChange?: (value: any) => void;
};

export const FormikRadioGroup: React.FC<Props> = ({
  name,
  label,
  options,
  sx,
  formControlLabelSx,
  disabled,
  onChange: onChangeProp,
}) => {
  const [field, , helpers] = useField(name);

  const onChange = useCallback(
    (value: any) => {
      helpers.setValue(value);
      onChangeProp?.(value);
    },
    [helpers, onChangeProp],
  );

  return (
    <FormControl sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      <Field component={RadioGroup} name={name} row onChange={noop}>
        {map(options, (option, index) => (
          <FormControlLabel
            sx={formControlLabelSx}
            key={index}
            value={option.value}
            control={
              <Radio
                checked={option.value === field.value}
                disabled={option.disabled || disabled}
                onChange={(e, checked) => {
                  if (checked) {
                    onChange(option.value);
                  }
                }}
              />
            }
            label={option.label}
          />
        ))}
      </Field>
    </FormControl>
  );
};
